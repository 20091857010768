import React, { useEffect, useRef, useState } from "react";
import "./PurchaseTransaction.css";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import PurchaseInvoiceDetails from "./components/PurchaseInvoiceDetails";
import PurchaseTable from "./components/PurchaseTable";
import PurchaseDetailFooter from "./components/PurchaseDetailFooter";
import PurchasePrintingDetails from "./components/PurchasePrintingDetails";
import PurchaseDeliveryDetails from "./components/PurchaseDeliveryDetails";
import PurchaseTableItemList from "./components/PurchaseTableItemList";
import PurchaseEditList from "./components/PurchaseEditList";
import { PurchaseItemBatchAdd } from "./components/PurchaseItemSerielAdd";
import Swal from "sweetalert2";
import usePurchaseServices from "../../../services/transactions/purchcaseServices";
import useItemServices from "../../../services/master/itemServices";
import { StockPop } from "../components/StockPop";
import useCustomerServices from "../../../services/master/customerServices";
import useAccountServices from "../../../services/master/accountServices";
import {
  initialPurchaseAdd,
  initialPurchaseSalesTableStatePosition,
  initialTableItem,
} from "./InitialData/data";
import usePurchaseReturnServices from "../../../services/transactions/purchaseReturnService";
import { StockJournalEdit } from "../stockjurnal/components/StockJournalEdit";
import { usePurchaseOrderServices } from "../../../services/transactions/purchaseOrderServices";
import { useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import useUnsavedServices from "../../../services/master/unsavedServices";
import { ClipLoader,MoonLoader,PuffLoader,ClimbingBoxLoader,ClockLoader,PropagateLoader,RingLoader,HashLoader } from 'react-spinners';
import { useUserServices } from "../../../services/controller/userServices";

export const initialPurchaseTableStatePositionLocal = JSON.parse(
  localStorage.getItem("initialPurchaseTableStatePositionLocal")
);

const PurchaseTransaction = ({ returnPage, orderPage }) => {
  const permissions = useSelector((state) => state.auth.activityPermissions);
  const [purchaseItemModal, setPurchaseItemModal] = useState(false);
  const [showPurchaseReturn, setShowPurchaseReturn] = useState(false);
  const [supplierList, setSupplierList] = useState(null);
  const [purchaseEditModal, setPurchaseEditModal] = useState(false);
  const [purchaseItemSerielModal, setPurchaseItemSerielModal] = useState(false);
  const [pageHeadItem, setPageHeadItem] = useState(1);
  const [edit, setEdit] = useState(null);
  const [showStock, setShowStock] = useState(false);
  const [showBatch, setShowBatch] = useState(false);
  const [itemNameList, setItemNameList] = useState([]);
  const [tableEdit, setTableEdit] = useState(false);
  const [batchKeys, setBatchKeys] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [orderDocList, setOrderDocList] = useState([]);
  const [bankSelect, setBankSelect] = useState(false);
  const [batchType,setBatchType]=useState('');
  console.log("is_batch",batchType)
  const initialData= initialPurchaseTableStatePositionLocal || initialPurchaseSalesTableStatePosition
  const getUpdatedData=(data) => data.map(item => {
    if (item.state === "batch_no") {
      return { ...item, visible: false, purchaseShow:false};
    }
    if (item.state === "expiry_date") {
      return { ...item, visible: false, purchaseShow:false};
    }
    return item;
  });
  const initialTableHeadList = batchType === "batchless" ? getUpdatedData(initialData) : initialData;
  const [tableHeadList, setTableHeadList] = useState(initialTableHeadList);
  useEffect(() => {
    if (batchType === "batchless") {
      setTableHeadList(getUpdatedData(initialData));
    } else {
      setTableHeadList(initialData);
    }
  }, [batchType, initialData]);

  const [purchaseOnlyList, setPurchaseOnlyList] = useState([]);
  const [purchaseOrReturnList, setPurchaseOrReturnList] = useState([]);
  const [tableItemList, setTableItemList] = useState([]);
  const [tableItemBatchList, setTableItemBatchList] = useState([]);
  const [purchaseAdd, setPurchaseAdd] = useState(initialPurchaseAdd);

  const [tableItem, setTableItem] = useState(initialTableItem);

  //ref of input / select / button fileds
  const [tableItemRef, setTableItemRef] = useState(null);
  const [purchaseInvoiceRef, setPurchaseInvoiceRef] = useState(null);
  const location = useLocation();
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const { getCode } = useItemServices();

  const { getAccountList } = useAccountServices();
  const {
    getPurchase,
    postPurchase,
    putPurchase,
    getPurchaseList,
    getPurchaseWithId,
  } = usePurchaseServices();
  const {
    postPurchaseReturn,
    getPurchaseReturnList,
    getPurchaseReturnWithId,
    putPurchaseReturn,
  } = usePurchaseReturnServices();

  const {
    postPurchaseOrder,
    getPurchaseOrderWithId,
    getPurchaseOrderList,
    putPurchaseOrder,
  } = usePurchaseOrderServices();

  const { getSupplier } = useCustomerServices();

  const {postUnsavedData,getUnsavedData} = useUnsavedServices() 

  const {
    getUserProfile,
  }= useUserServices();

  const handleGetBatchStatus = async () =>{
      try {
        let res = await getUserProfile();
        if (res?.success) {
          setBatchType(res?.data?.user_data?.group_profile_details?.is_batch)
        }
      } catch (err) {
        console.log(err);
      }
  }

  useEffect(() => {
    getData();
    if (!returnPage && !orderPage) {
      handleReloadData();
      setPurchaseOrReturnList([]);
      setPurchaseOnlyList([]);
    } else if (returnPage || orderPage) {
      handleReloadData();
      setPurchaseOrReturnList([]);
      setPurchaseOnlyList([]);
      handlePurchaseAllReset();
      handleGetCode(true);
    }
    handleGetBatchStatus()
    let supplier = location.state;
    if (supplier?.id) {
      setPurchaseAdd((data) => ({
        ...data,
        fk_supplier: supplier.id,
        supplier_name: supplier.name,
        supplier_code: supplier.code,
      }));
      navigate(null, { replace: true, state: null });
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "E" && e.shiftKey && returnPage) {
        setShowPurchaseReturn(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if ((edit || tableItemList.length > 0)){ //(edit || tableItemList.length > 0) added  // && !returnPage && !orderPage
      myBeforeUnloadFunction();
    } 
  }, [purchaseAdd ,tableItemList, edit]); //purchaseAdd 

  const myBeforeUnloadFunction = () => {
    let checkLocal= (edit!== null? true : false)
    var allPurchState = {
      ...purchaseAdd,
      edit: edit,
      local:checkLocal,
    };
    if (tableItemList?.length > 0)
      allPurchState = {
        ...allPurchState,
        items: [...tableItemList],
      };

    if (!returnPage && !orderPage){
      // localStorage.setItem("purchaseData", JSON.stringify(allPurchState));
      handlePostUnsavedData("purchase",allPurchState,false)
    }else if (returnPage){
      handlePostUnsavedData("purchase_return",allPurchState,false)
    }else if (orderPage){
      handlePostUnsavedData("purchase_order",allPurchState,false)
    }
  };

  const handleBlur =(e)=>{
    var allPurchState ={
      ...purchaseAdd,
      edit:edit,
    }
    allPurchState[e.target.name]=e.target.value

    if (tableItemList?.length > 0)
      allPurchState = {
        ...allPurchState,
        items: [...tableItemList],
      };
    if (!returnPage && !orderPage){
      // console.log("------------------------",allPurchState)
      handlePostUnsavedData("purchase",allPurchState,false)
    }else if (returnPage){
      handlePostUnsavedData("purchase_return",allPurchState,false)
    }else if (orderPage){
      handlePostUnsavedData("purchase_order",allPurchState,false)
    }
  }

  const handlePostUnsavedData = async(module,data,is_saved)=>{
    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",data)
    let jsonData=JSON.stringify(data)
    const response = await postUnsavedData({module:module,data:jsonData,is_saved:is_saved})
    if (response?.data) console.log("send")
  }

  // const handleGetUnsavedData = async()=>{
  //   const response = await getUnsavedData({module:'purchase'})
  //   console.log(response.data,"Checking")
  // }

  const handleReloadData = async() => {
    // let data = localStorage.getItem("purchaseData");
    let response;
    if (!returnPage && !orderPage){
      response = await getUnsavedData({module:'purchase'})
    }else if (returnPage){
      response = await getUnsavedData({module:'purchase_return'})
    }else if (orderPage){
      response = await getUnsavedData({module:'purchase_order'})
    }
      // console.log(response?.data[0]?.data,"======================PurchaseSuperb=======================")
    let data = response?.data[0]?.data
  
    if (data){
      data = JSON.parse(data);
      // console.log('CheckkkkkkkkkkkkkkkkkkPurchasekkkkkkkkkkkkkkkkkkkkkkkk',data )
    } 
    if (data?.edit && data?.local === false) {
      setEdit({ ...data.edit });
      handleSetEdit(data?.edit);
    } else if (data?.edit && data?.local === true){
      handlePurchaseEdit(data,data?.edit);
    }
    // else if(!data.documents_no) {
    //    handleGetCode(false, true);
    // }
    else if (data?.date && data?.documents_no) {
      let {
        items,
        fk_supplier,
        supplier_name,
        supplier_code,
        updated_at,
        edit,
        tablekeys,
        ...others
      } = data;
      let tempData = {
        ...others,
        change_due: others.change_due || "0.00",
      };
      if (!location?.state?.id && location?.state?.id !== 0) {
        tempData = { ...tempData, fk_supplier: fk_supplier };
      }
      setPurchaseAdd((data) => ({
        ...data,
        ...tempData,
        supplier_name: supplier_name,
        supplier_code: supplier_code,
      }));
      if (items) {
        // console.log("########## items1 ##########",items)
        setTableItemList([...items]);
        handlePurchAllCalc(items, true, tempData);
      }
    }
    else handleGetCode(false, true);
  };

  const handlePurchaseEdit = async (data,edit) =>{
    try {
      let purchaseData;
      if (!returnPage && !orderPage){
        purchaseData = await getPurchaseWithId(edit.id);
        // console.log("looooooooooooooooooooooooooook",purchaseData)
        // console.log("purchaseData.data", purchaseData.data)  
      }if (purchaseData?.data) {
       
      
        // let tempLocalData ={...purchaseData.data, ...data}
        // console.log("purchaseData.data...data", tempLocalData)
        let { fk_supplier, items, updated_at, ...others } = data;
        let tempData = {
          ...others,
          change_due: others.change_due || "0.00",
        };
        if (location?.state?.id) {
          tempData = {
            ...tempData,
            supplier_code: location?.state?.code,
            supplier_name: location?.state?.name,
            fk_supplier: location?.state?.id,
          };
        } else if (fk_supplier >= 0) {
          let supplierData = supplierList?.filter(
            (x) => x.value === fk_supplier
          )[0]
          tempData = {
            ...tempData,
            supplier_code: supplierData?.text,
            supplier_name: supplierData?.name,
            fk_supplier: fk_supplier,
          };
        }
        // console.log("padachoneeeeeeeeeeeeeeeeeeeeeeeeeeeee",tempData)
        setPurchaseAdd((data) => ({ ...data, ...tempData }));
        if (items) {
          console.log("########## items2 ##########")
          items = filterOutNullValues(data.items);
          setTableItemList([...items]);
          handlePurchAllCalc(items, true, tempData);
        }
        setEdit(data.edit)
      } else {
        handlePurchaseAllReset();
      }
    }catch(e){
      console.log('Error',e)
    }
  }

  useEffect(() => {
    if (
      (!purchaseAdd.bank_amount && !purchaseAdd.fk_bank) ||
      purchaseAdd.fk_bank
    )
      setBankSelect(true);
    else setBankSelect(false);
  }, [purchaseAdd.bank_amount, purchaseAdd.fk_bank]);

  useEffect(() => {
    let paymentType = "CASH";
    if (purchaseAdd.change_due > 0) paymentType = "CREDIT";
    setPurchaseAdd((data) => ({ ...data, payment_type: paymentType }));
  }, [purchaseAdd.change_due]);

  const filterOutNullValues = (items) => {
    items.forEach((item, i) => {
      for (const [key, value] of Object.entries(item)) {
        if (value === 0 || value === null || value === undefined) {
          delete items[i][key];
        }
      }
    });
    return items;
  };

  const handleSetEdit = async (state, orderGet) => {
    //orderGet is true when the order is selected from order list else false
    try {
      let purchaseData;
      if (!returnPage && !orderPage && !orderGet)
        purchaseData = await getPurchaseWithId(state.id);
      if (returnPage) purchaseData = await getPurchaseReturnWithId(state.id);
      if (orderPage || orderGet)
        purchaseData = await getPurchaseOrderWithId(state.id);

      if (purchaseData?.data) {
        let { fk_supplier, items, updated_at, ...others } = purchaseData.data;
        let tempData = {
          ...others,
          change_due: others.change_due || "0.00",
        };
        if (orderGet) {
          tempData = { ...tempData, order_no: others.id };
          delete tempData.documents_no;
        }

        if (location?.state?.id) {
          tempData = {
            ...tempData,
            supplier_code: location?.state?.code,
            supplier_name: location?.state?.name,
            fk_supplier: location?.state?.id,
          };
        } else if (fk_supplier >= 0) {
          let supplierData = supplierList?.filter(
            (x) => x.value === fk_supplier
          )[0]
          tempData = {
            ...tempData,
            supplier_code: supplierData?.text,
            supplier_name: supplierData?.name,
            fk_supplier: fk_supplier,
          };
        }
        // console.log("nokikeeeeeeeeeeeeeeeeeeeeeeeee",tempData)
        setPurchaseAdd((data) => ({ ...data, ...tempData }));
        if (items) {
          console.log("########## items3 ##########")
          items = filterOutNullValues(items);
          setTableItemList([...items]);
          handlePurchAllCalc(items, true, tempData);
        }
      } else {
        handlePurchaseAllReset();
      }
      if (orderGet) {
        setEdit(false);
        handleGetCode();
      }
    } catch (err) {
      // console.log(purchaseOrReturnList);
    }
  };

  const handlePurchAllCalc = (dataList, fromEdit, purchaseData) => {
    // dataList is the list of tableItemList
    if (dataList?.length > 0) {
      let tempPurchaseAdd = purchaseData || { ...purchaseAdd };
      let netAmount = dataList?.reduce((a, b) => {
        return b.total ? parseFloat(a) + parseFloat(b.total) : 0;
      }, 0);
      let netMargin = dataList?.reduce((a, b) => {
        return b.margin ? parseFloat(a) + parseFloat(b.margin) : 0;
      }, 0);
      let totalItem = dataList?.reduce((a, b) => {
        return a + 1;
      }, 0);
      let totalCTC = dataList?.reduce((a, b) => {
        return b.cost ? parseFloat(a) + parseFloat(b.cost) : 0;
      }, 0);
      let totalQty = dataList?.reduce((a, b) => {
        return b.quantity ? parseFloat(a) + parseFloat(b.quantity) : 0;
      }, 0);
      let total_value = dataList?.reduce((a, b) => {
        return b.value ? parseFloat(a) + parseFloat(b.value) : 0;
      }, 0);
      let total_scGst = dataList?.reduce((a, b) => {
        return b.value && !isNaN(parseFloat(b.cgst_or_igst)) ? parseFloat(a) + parseFloat(b.cgst_or_igst) : parseFloat(a);
      }, 0);
      let total_total = dataList?.reduce((a, b) => {
        return b.value ? parseFloat(a) + parseFloat(b.total) : 0;
      }, 0);
      let total_disc = dataList?.reduce((a, b) => {
        return b.discount_1_amount
          ? parseFloat(a) + parseFloat(b.discount_1_amount)
          : 0;
      }, 0);

      let isVat = tableHeadList?.findIndex((x) => x.state == "vat")[0];

      let roundOff = (
        Math.round(parseFloat(netAmount)).toFixed(2) - parseFloat(netAmount)
      ).toFixed(2);
      if (roundOff == 0 || !roundOff) roundOff = null;
      else if (roundOff < 0) roundOff = Math.abs(roundOff);

      if (!fromEdit)
        tempPurchaseAdd = {
          ...tempPurchaseAdd,
          paid_cash: netAmount?.toFixed(0),
          change_due: 0,
          bank_amount: 0,
        };

      tempPurchaseAdd = {
        ...tempPurchaseAdd,
        total_margin: netMargin?.toFixed(0),
        total_amount: Number(netAmount?.toFixed(0) - purchaseAdd.discount),
        total_amount2: Number(netAmount?.toFixed(2) - purchaseAdd.discount),
        total_CTC: totalCTC?.toFixed(2),
        total_qty: totalQty?.toFixed(0),
        total_value: total_value?.toFixed(2),
        total_total: total_total?.toFixed(2),
        [!isVat ? "total_scGst" : "total_vatPerc"]: total_scGst?.toFixed(2),
        total_items: totalItem,
        total_disc: total_disc,
        roundoff: roundOff,
      };

      setPurchaseAdd((data) => {
        return { ...data, ...tempPurchaseAdd };
      });
    } else {
      setPurchaseAdd((data) => ({
        ...data,
        otal_margin: 0,
        total_amount: 0,
        paid_cash: 0,
        total_CTC: 0,
        total_qty: 0,
        total_value: 0,
        total_total: 0,
        total_scGst: 0,
        total_items: 0,
        discount: 0,
        roundoff: 0,
        total_disc: 0,
      }));
    }
  };

  const handleGetCode = async (nextCode, firstReload) => {
    //  nextCode arg is sent form handleNext fucnt
    //  in purchaseTable and handleReset in purchaseDetailFooter
    //  it is for reseting doc_no instead of checking edit
    try {
      let code;
      let response = await getCode();
      if (
        response.success &&
        ((!edit && nextCode) || nextCode || (!nextCode && firstReload))
      ) {
        for (let i of response.data) {
          let type = "PURCHASE_CODE";
          if (returnPage) {
            type = "PURCHASE_RETURN";
          } else if (orderPage) {
            type = "PURCHASE_ORDER";
          }
          if (i.types == type) {
            code = i.next_code;
          }
          setPurchaseAdd((data) => ({ ...data, documents_no: code }));
        }
      }
    } catch (err) { }
  };

  const getData = async () => {
    try {
      let response,
        response1,
        response3,
        response4,
        response5,
        suppList,
        bankList;

      const handleSuppNameAdd = (data) => {
        let list = [];
        data.map((purData, key) => {
          if (purData.fk_supplier > -1) {
            let supplierData = suppList?.filter(
              (supData) => supData.value == purData.fk_supplier
            )[0];
            purData = {
              ...purData,
              supplier_name: supplierData?.name,
              supplier_code: supplierData?.text,
            };
          }
          list.splice(key, 1, purData);
        });
        return list;
      };
      if (!returnPage && !orderPage) {
        response5 = await getPurchaseOrderList();
        if (response5?.success) {
          setOrderDocList((data) => {
            return response5.data.map((doc) => {
              return { text: doc.documents_no, value: doc.id };
            });
          });
        }
      }

      response = await getSupplier();
      if (!response?.success) return 0;
      suppList = [];
      response.data.map((item) => {
        let a = {
          value: item.id,
          text: item.code,
          name: item.name,
          description: item.name,
        };
        suppList.push(a);
      });
      setSupplierList(suppList);

      if (returnPage) {
        response4 = await getPurchase();
        response1 = await getPurchaseReturnList();
      } else if (orderPage) {
        response1 = await getPurchaseOrderList();
      } else response1 = await getPurchaseList();
      if (response1?.success && response4?.success && returnPage) {
        setPurchaseOrReturnList(handleSuppNameAdd(response1.data));
        setPurchaseOnlyList(handleSuppNameAdd(response4.data));
      } else if (response1.success) {
        setPurchaseOrReturnList(handleSuppNameAdd(response1.data));
      }

      response3 = await getAccountList();
      if (response3.success) {
        bankList = [];
        response3.data.map((item) => {
          if (item.bank_account) {
            bankList.push({
              key: item.code,
              value: item.id,
              text: item.name,
              description: item.code,
            });
          }
        });

        setBankList([...bankList]);
      }
      // return { suppList, bankList };
    } catch (err) {
      console.log(err);
    }
  };

  const handlePurchOrderSelect = async (id) => {
    if (returnPage || orderPage) return 0;
    handleSetEdit({ id: id }, true);
  };

  const handleTableItemReset = () => {
    setTableEdit(false);
    setTableItem(initialTableItem);
  };

  const handleEdit = () => {
    setPurchaseEditModal(true);
  };

  const handlePurchaseAllReset = (resetLocal) => {
    setPurchaseAdd(initialPurchaseAdd);
    setTableItemList([]);
    setTableItemBatchList([]);
    setTableItem(initialTableItem);
    setEdit(false);
    if (!returnPage && !orderPage && resetLocal) {
      localStorage.setItem("purchaseData", false);
    }
  };

  const handleChange = (e, data) => {
    let name = e.target.name;
    let value = e.target.value;
    if (data && data.name == "fk_bank") {
      let bank_data = data.options.filter((x) => x.value === data.value)[0];
      setPurchaseAdd((data) => ({
        ...data,
        fk_bank: bank_data?.value,
      }));
    } else if (data && data.name === "fk_supplier") {
      let supplier_data = data.options.filter((x) => x.value === data.value)[0];
      setPurchaseAdd((pur) => ({
        ...pur,
        supplier_code: supplier_data?.text,
        supplier_name: supplier_data?.name,
        fk_supplier: supplier_data?.value,
      }));
    } else if (data && data.name === "order_no") {
      setPurchaseAdd((pur) => ({
        ...pur,
        order_no: data.value,
      }));
      handlePurchOrderSelect(data.value);
    } else if (e.target.type === "checkbox") {
      setPurchaseAdd((data) => ({
        ...data,
        [name]: !data[name],
      }));
    } else if (name == "discount") {
      let discPrice,
        total_value = value !== "" ? +value : null;

      let totalAmount =
        (+purchaseAdd.paid_cash || 0) +
        (+purchaseAdd?.bank_amount || 0) +
        (+purchaseAdd?.change_due || 0);
      discPrice = totalAmount + +purchaseAdd.discount - total_value;
      setPurchaseAdd((data) => ({
        ...data,
        [name]: total_value,
        total_amount: discPrice?.toFixed(0),
        paid_cash: discPrice?.toFixed(0),
        change_due: "0.00",
        bank_amount: 0,
      }));
    } else if (name == "bank_amount") {
      let total_value = value == "" ? null : +value;
      let totalAmount = purchaseAdd.total_amount;
      setPurchaseAdd((data) => ({
        ...data,
        paid_cash: +totalAmount - total_value,
        change_due: "0.00",
        bank_amount: total_value,
      }));
    } else if (name == "paid_cash") {
      if (
        +value >
        +(
          +purchaseAdd.paid_cash +
          +purchaseAdd.bank_amount +
          +purchaseAdd.change_due
        )
      ) {
        Swal.fire({
          title: "Warning",
          text: "The amount exceeds the value in net amount",
          icon: "warning",
          timer: 1560,
        });
      } else {
        let total_value = value == "" ? null : +value;
        setPurchaseAdd((data) => ({
          ...data,
          change_due:
            +purchaseAdd.change_due +
            +purchaseAdd.total_amount +
            +purchaseAdd.paid_cash -
            total_value -
            +purchaseAdd.total_amount || "0.00",
          paid_cash: total_value,
        }));
      }
    } else if (value == "")
      setPurchaseAdd((data) => ({ ...data, [name]: null }));
    else {
      if (name === "date") value = new Date(value).toISOString();
      setPurchaseAdd((data) => ({ ...data, [name]: value }));
    }
  };

  const handleCloseItemBatch = () => {
    if (!tableEdit) {
      let tempList = [...tableItemList];
      let listAfterItemRem = [];

      let index = tempList.findIndex((x) => {
        return x.cstm_id == purchaseItemSerielModal;
      });

      if (index > -1) {
        tempList.splice(index, 1);
        listAfterItemRem = [...tempList];
      }
      handlePurchaseAllReset();
      setTableItemList([...listAfterItemRem]);
    }
    setPurchaseItemSerielModal(false);
    setShowBatch(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (purchaseAdd.change_due > 0 && !purchaseAdd.fk_supplier) {
        Swal.fire({
          title: "Supplier not selected",
          icon: "warning",
          text: "Please select a supplier if balance is due",
          showConfirmButton: false,
          timer: 1500,
        });
        return 0;
      }
      if (tableItemList.length <= 0) {
        Swal.fire({
          title: "Item not added",
          icon: "warning",
          text: "Please add an item before submitting purchase",
          showConfirmButton: false,
          timer: 1500,
        });
        return 0;
      }
      if (orderPage && !purchaseAdd.fk_supplier && purchaseAdd.paid_cash > 0) {
        Swal.fire({
          title: "Please Select Supplier",
          icon: "warning",
          text: "Please select supplier or empty paid cash",
          showConfirmButton: false,
          timer: 1500,
        });
        return 0;
      }

      let submitData = {
        ...purchaseAdd,
        items: filterOutNullValues(tableItemList),
        advance_amt: purchaseAdd.paid_cash,
      };
      // console.log("submit_data",submitData)
      let response;
      setLoading(true)
      if (!edit) {
        // console.log("submit_data",submitData)
        if (returnPage) response = await postPurchaseReturn(submitData);
        else if (orderPage) response = await postPurchaseOrder(submitData);
        else response = await postPurchase(submitData);
      } else {
        // console.log("submit_data",submitData)
        // console.log("edit_id",edit?.id)
        if (returnPage)
          response = await putPurchaseReturn(edit?.id, submitData);
        else if (orderPage)
          response = await putPurchaseOrder(edit?.id, submitData);
        else response = await putPurchase(edit?.id, submitData);
      }
      if (response?.success) {
        setLoading(false)
        if(!returnPage&&!orderPage) handlePostUnsavedData('purchase',submitData,true)
        if(returnPage) handlePostUnsavedData('purchase_return',submitData,true)
        if(orderPage) handlePostUnsavedData('purchase_order',submitData,true)
        handlePurchaseAllReset(true);
        handleGetCode(true);
        getData();
        Swal.fire(
          `Purchase  ${returnPage ? "return" : orderPage ? "order" : ""} ${edit?.id ? "edited" : "added"
          } successfully`,
          "",
          "success"
        );
      } else {
        setLoading(false)
        if (response?.data?.length > 0) {
          if (response?.data) {
            Swal.fire({
              title: "Error",
              text:
                response?.data[0] ||
                "Something went wrong. Pls try again later",
              icon: "error",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } else Swal.fire("Failed to create purchase", "", "error");
      }
    } catch (err) {
      if (err?.response?.data?.data) {
        Swal.fire({
          title: "Error",
          text:
            err?.response?.data || "Something went wrong. Pls try again later",
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire("Failed to create purchase", "", "error");
      }
    }
  };

  const handleBatchSubmit = (tempItems) => { };

  return (
    <div style={{backgroundColor:"#E1F0DA"}} className="item_add zoom-responsive-view-pur">
      <div  className={`itemList_header row mx-0 mb-3`}>
        <div
          className={`page_head ps-4 d-flex pe-0 ${returnPage
              ? " purchase-return active-header2"
              : orderPage && " purchase-order active-header2"
            }`}
        >
          <div className="col-6 col-7">
            <div className="fw-600 fs-5 mt-3">
              Purchase {returnPage ? " Return" : orderPage && " Order"}
            </div>
            <div className="page_head_items mb-1">
              <div
                onClick={() => {
                  navigate("/purchase-transaction");
                }}
                className={`page_head_item active`}
              >
                Purchase {returnPage ? " Return" : orderPage && " Order"}{" "}
                Details
              </div>
            </div>
          </div>
          <div className="col-5 col-6 pe-4 d-flex align-items-center justify-content-end">
            <div className="col-3 d-flex px-0 align-items-center justify-content-end">
              <div
                onClick={() => setPageHeadItem(2)}
                className={`btn purchase-nav-btn px-2 
                ${pageHeadItem === 2 && "select"}`}
              >
                Printing details
              </div>
            </div>
            <div className="col-3 d-flex px-0 align-items-center justify-content-end">
              <div
                onClick={() => setPageHeadItem(3)}
                className={`btn purchase-nav-btn px-2 
                ${pageHeadItem === 3 && "select"}`}
              >
                Delivery details
              </div>
            </div>
            <div className="col-3 d-flex px-0 align-items-center justify-content-end">
              <div
                onClick={() => setPageHeadItem(1)}
                className={`btn purchase-nav-btn px-2 
                ${pageHeadItem === 1 && "select"}`}
              >
                Invoice details
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
      <div className="spinner-container">
        <HashLoader  color="#400091" loading={loading} size={100} />
      </div>
      )}
        <form
        onSubmit={handleSubmit}
        className="item_add_cont px-3 pb-1 pt-0 position-relative "
        id="myForm"
        style={{ opacity: loading ? 0.5 : 1 , backgroundColor:'#e1f0da'}}
      >
        {/* {purchaseHeader} ---------------------------------------------------------*/}
        <div className="position-relative">
          {pageHeadItem == 1 ? (
            <PurchaseInvoiceDetails
              {...{
                orderDocList,
                orderPage,
                returnPage,
                purchaseInvoiceRef,
                setPurchaseInvoiceRef,
                tableItemRef,
                handleEdit,
                purchaseAdd,
                handleChange,
                supplierList,
                setSupplierList,
                handleBlur,
              }}
            />
          ) : pageHeadItem == 2 ? (
            <PurchasePrintingDetails
              {...{
                returnPage,
                orderPage,
                handleEdit,
                purchaseAdd,
                handleChange,
                handleBlur,
              }}
            />
          ) : (
            pageHeadItem == 3 && (
              <PurchaseDeliveryDetails
                {...{
                  returnPage,
                  orderPage,
                  handleEdit,
                  purchaseAdd,
                  handleChange,
                  handleBlur,
                }}
              />
            )
          )}
          <div
            className="col-3  pe-0 d-flex align-items-end justify-content-start ps-1 position-absolute "
            style={{ bottom: "-2px" }}
          >
            <div className="ps-1 ">
              <div className="btn btn-sm purch-table-btn1 px-3">
                Purchase{returnPage ? " Return" : orderPage && " Order"}
              </div>
            </div>
            <div className="">
              {/* <div className="btn btn-sm purch-table-btn1 px-3">P.Return</div> */}
            </div>
            <div className="ps-1">
              <div className="btn btn-sm purch-table-btn1 px-3">Other</div>
            </div>
            <div className="ps-1 col-2 col-3 ">
              {((returnPage && !permissions.includes(1200)) ||
                (orderPage && !permissions.includes(1238)) ||
                !permissions.includes(1062)) && (
                  <div
                    onClick={handleEdit}
                    className="btn btn-sm btn-dark px-1 justify-content-center d-flex align-items-center gap-1"
                  >
                    <FiEdit size={"1rem"} />
                    Edit
                  </div>
                )}
            </div>
          </div>
          <div className="col-1"></div>
        </div>
        {/* {purchaseHeader} ---------------------------------------------------------*/}
        <PurchaseTable
          from={
            orderPage ? "purch Order" : returnPage ? "purch Return" : "purch"
          }
          {...{
            permissions,
            purchaseInvoiceRef,
            tableItemRef,
            setTableItemRef,
            handleGetCode,
            handleSetEdit,
            tableHeadList,
            setPurchaseItemModal,
            tableItem,
            setTableItem,
            edit,
            handlePurchAllCalc,
            purchaseAdd,
            tableItemList,
            setTableItemList,
            tableEdit,
            setEdit,
            itemNameList,
            setItemNameList,
            purchaseOrReturnList,
            handleTableItemReset,
            handlePurchaseAllReset,
            setShowBatch,
            batchType,
          }}
        />
        <PurchaseDetailFooter
          from={
            orderPage ? "purch Order" : returnPage ? "purch Return" : "purch"
          }
          {...{
            permissions,
            setPurchaseAdd,
            handleGetCode,
            bankSelect,
            bankList,
            tableItemList,
            purchaseAdd,
            handleChange,
            handlePurchaseAllReset,
            edit,
            handleBlur,
            loading,
            returnPage,
            orderPage,
            handlePostUnsavedData
          }}
        />
        </form>
      <Modal
        show={purchaseItemModal}
        size="lg"
        centered
        onHide={() => setPurchaseItemModal(false)}
        className="column-resp-zoom"
      >
        <PurchaseTableItemList
          from="pur"
          {...{
            tableHeadList,
            setTableHeadList,
          }}
        />
      </Modal>
      <Modal
        show={purchaseEditModal}
        size="lg"
        centered
        onHide={() => setPurchaseEditModal(false)}
      >
        <PurchaseEditList
          from={
            orderPage ? "purch Order" : returnPage ? "purch Return" : "purch"
          }
          setShow={setPurchaseEditModal}
          title={"Purchase Edit Table"}
          list={purchaseOrReturnList}
          {...{
            handleSetEdit,
            setEdit,
            edit,
            getData,
          }}
        />
      </Modal>
      <Modal
        show={showBatch}
        size="lg"
        centered
        contentClassName="purchase-batch-modal"
        onHide={() => handleCloseItemBatch()}
      >

        <PurchaseItemBatchAdd
          {...{
            edit,
            setPurchaseItemSerielModal,
            purchaseItemSerielModal,
            handleTableItemReset,
            tableItemBatchList,
            setTableItemBatchList,
            handleCloseItemBatch,
            setTableEdit,
            getData,
            handleBatchSubmit,
            batchKeys,
            setBatchKeys,
            showBatch,
            setShowBatch,
          }}
        />
      </Modal>

      <Modal
        show={showStock}
        centered
        size="lg"
        onHide={() => setShowStock(false)}
      >
        <StockPop
          {...{ itemNameList, setTableItem, tableItem, setShowStock }}
        />
      </Modal>
      <Modal
        show={showPurchaseReturn && returnPage}
        centered
        size="xl"
        onHide={() => setShowPurchaseReturn(false)}
      >
        <StockJournalEdit
          list={purchaseOnlyList}
          title="Purchase Return Item List"
          setShow={setShowPurchaseReturn}
          handleCalc={handlePurchAllCalc}
          show={showPurchaseReturn}
          setItemList={setTableItemList}
          handleClearAll={handlePurchaseAllReset}
          supplierCustomer={purchaseAdd.supplier_name}
          from="purchRtn"
          {...{ getData, setEdit }}
        />
      </Modal>
    </div>    
  );
};

export default PurchaseTransaction;
