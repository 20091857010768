import useAxiosPrivate from "../../hooks/axios/useAxiosPrivate"

const useUnsavedServices = () =>{
    const axios =useAxiosPrivate()

    //get unsaved data
    const getUnsavedData = async (params) =>{
        const response = await axios.get('/master/unsaved/',{params:params})
        return response.data
    }
    //post unsaved data
    const postUnsavedData = async (data) =>{
        const response = await axios.post('/master/unsaved/',data)
        return response.data
    }
    return{
        getUnsavedData,
        postUnsavedData,
    }
   
}

export default useUnsavedServices