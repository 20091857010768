// import React from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { GenerateDynamicHtml } from "../../../../components/print/Print";
// import ReactToPrint from "react-to-print";

// export const PrintFIle = (props) => {
//     console.log('step 1');
//     const {
//         handleSalesAllReset,
//         SalesTable,
//         c_address,
//         c_name,
//         c_number,
//         delivery_add,
//         c_gstin,
//         vehicle_no,
//         driver,
//         total_qty,
//         total_disc,
//         total_value,
//         total_cgst,
//         total_sgst,
//         total,
//         taxPerc,
//         hsn,
//         roundOff,
//         hsnCalc,
//         salesAdd
//     } = props;
//     console.log('step 2');

//     const printStyle = localStorage.getItem("printType");
//     console.log('step 3');

//     const handleConvertToPdf = async (status) => {
//         // const content = document.getElementsByClassName("print-page");
//         // const pages = document.querySelectorAll(".print-page");
//         const pdf = new jsPDF('p', 'mm', 'a4');
//         const pages = document.querySelectorAll('.print-page');
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = pdf.internal.pageSize.getHeight();
//         // console.log(content);
//         // tempContainer.innerHTML = dynamicHtml;
//         const pdfOptions = {
//             // margin: 9,
//             margin: 0,
//             filename: "output.pdf",
//             image: { type: "jpeg", quality: 0.98 },
//             html2canvas: { scale: 2 },
//             jsPDF: {
//                 unit: "mm",
//                 format: "a4",
//                 orientation: "portrait",
//                 autoPrint: { variant: "non-conform" },
//             },
//         };
        
//             // const pdf = new jsPDF(pdfOptions.jsPDF);
//             for (let i = 0; i < pages.length; i++) {
//                 const page = pages[i];
//                 await html2canvas(page,{ scale: 2 }).then(canvas => {
//                     const imgData = canvas.toDataURL('image/png');
//                     const imgWidth = pdfWidth;
//                     const imgHeight = canvas.height * pdfWidth / canvas.width;
                    
//                     if (i > 0) {
//                         pdf.addPage();
//                     }

//                     pdf.addImage(
//                         imgData,
//                         'PNG', 
//                         0, 
//                         0, 
//                         // imgWidth, 
//                         // imgHeight);
//                         210 - 1 * pdfOptions.margin,
//                         297 - 1 * pdfOptions.margin
//                     )
//                 });
//             }

            // pdf.save('document.pdf');
            // if (status == "print") {
            //     console.log('in print status')
            //     // pdf.autoPrint();
            //     // window.open(pdf.output('datauristring'));
            //     pdf.autoPrint();
            //     //This is a key for printing
            //     pdf.output('dataurlnewwindow');
            // }


        // if (content.length > 0) {
        //     const pdf = new jsPDF(pdfOptions.jsPDF);
        //     let idx = 0;
            // for (var c of content) {
            //     console.log(c);
            //     console.log(`alias${idx}`);
            //     html2canvas(c, { scale: 2 }).then(async (canvas) => {
            //         // const imgData = canvas.toDataURL("image/jpeg");

            //         // pdf.addImage(
            //         //     imgData,
            //         //     "JPEG",
            //         //     pdfOptions.margin,
            //         //     pdfOptions.margin,
            //         //     210 - 1 * pdfOptions.margin,
            //         //     297 - 1 * pdfOptions.margin,
            //         //     `alias${idx}`
            //         // );
                    
            //         pdf.html(document.body);
            //         // if (printStyle == "thermal") {
            //         //     // Use Courier font
            //         //     pdf.setFont("courier");

            //         //     // Set font size
            //         //     pdf.setFontSize(12);

            //         //     // Add black and white image
            //         //     pdf.addImage(
            //         //         imgData,
            //         //         "JPEG",
            //         //         pdfOptions.margin,
            //         //         pdfOptions.margin,
            //         //         210 - 1 * pdfOptions.margin,
            //         //         297 - 1 * pdfOptions.margin
            //         //     );
            //         // }

            //     });
            //     idx=idx+1;
            // }
            
            // if (status == "print") {
            //     // // Generate a Blob from the PDF content
            //     // const pdfBlob = pdf.output("blob");

            //     // // Create an object URL from the Blob
            //     // const pdfObjectURL = URL.createObjectURL(pdfBlob);

            //     // // Create an invisible iframe
            //     // const iframe = document.createElement("iframe");
            //     // iframe.style.display = "none";
            //     // document.body.appendChild(iframe);

            //     // // Set the iframe content to the PDF object URL
            //     // iframe.src = pdfObjectURL;

            //     // // Wait for the PDF to load in the iframe, then trigger print
            //     // iframe.onload = () => {
            //     //     iframe.contentWindow.print();
            //     //     // Release the object URL when done printing
            //     //     URL.revokeObjectURL(pdfObjectURL);
            //     // };
            //     pdf.autoPrint();
            // } else if (status == "pdf") {
            //     pdf.save(pdfOptions.filename);
            // }

        // }
//     };
//     console.log('step 4');
//     const handlePrintInvoice = () => {
//         window.print();
//       };
//     return (
//         <div
//             className="d-flex justify-content-center flex-column"
//             style={{ width: "fit-content" }}
//         >
//             <div
//                 className="d-flex justify-content-center flex-column p-2"
//                 style={{ width: "fit-content" }}
//             >
//                 <GenerateDynamicHtml
//                     {...{
//                         c_address,
//                         c_name,
//                         c_number,
//                         delivery_add,
//                         c_gstin,
//                         vehicle_no,
//                         driver,
//                         total_qty,
//                         total_disc,
//                         total_value,
//                         total_cgst,
//                         total_sgst,
//                         total,
//                         taxPerc,
//                         hsn,
//                         roundOff,
//                         hsnCalc,
//                         salesAdd
//                     }}
//                 />
//             </div>
//             <div className="w-100 d-flex justify-content-center gap-4 pb-3">
//                 <button onClick={handleSalesAllReset} className="btn btn-sm btn-dark">
//                     Close
//                 </button>
//                 <button
//                     className="btn btn-sm btn-dark"
//                     onClick={() => handleConvertToPdf("print")}
//                 >
//                     Print
//                 </button>
//                 <button
//                     className="btn btn-sm btn-dark"
//                     onClick={() => handleConvertToPdf("pdf")}
//                 >
//                     Pdf
//                 </button>
//                 <button 
//                     className="btn btn-sm btn-dark"
//                     onClick={handlePrintInvoice}>
//                     Invoice
//                 </button>
//             </div>
//         </div>
//     );
// };
//########################################################################



import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GenerateDynamicHtml } from "../../../../components/print/Print";

export const PrintFIle = (props) => {
    const {
        handleSalesAllReset,
        SalesTable,
        c_address,
        c_name,
        c_number,
        delivery_add,
        c_gstin,
        vehicle_no,
        driver,
        total_qty,
        total_disc,
        total_value,
        total_cgst,
        total_sgst,
        total,
        taxPerc,
        hsn,
        roundOff,
        hsnCalc,
        salesAdd
    } = props;
    const [pType, setPType] = useState(localStorage.getItem('printType') || 'A4_normal');
    const [format, setFormat] = useState('a4');
    useEffect(() => {
        if (pType === 'A4_format_1') {
            setFormat('a4');
        } else if (pType === 'A5_format_1') {
            setFormat('a5');
        } else if (pType === 'Dot_matrix_1') {
            setFormat('a4');
        }
    }, [pType]);

    const [orientation, setOrientation] = useState('portrait');

    const handleConvertToPdf = async (status) => {
    // let pdf = new jsPDF(orientation, 'mm', format);
    const pdfOptions = {
        margin: 0,
        filename: "output.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
            unit: "mm",
            format: format,
            orientation: orientation,
            autoPrint: { variant: "non-conform" },
        },
    };
    
    const pdf = new jsPDF(pdfOptions.jsPDF);
    const pages = document.querySelectorAll('.print-page');
    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = pdf.internal.pageSize.getHeight();

    // Adjust dimensions for A5 format in landscape mode
    if (format === 'a5' && orientation === 'landscape') {
        [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
        // pdf.internal.pageSize.width = pdfWidth;
        // pdf.internal.pageSize.height = pdfHeight;
    }

    console.log(":::::::::::::::::::::::::::::::::::::", pdfWidth, pdfHeight);

    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        await html2canvas(page, { scale: 2 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            let imgWidth, imgHeight;

            if (orientation === 'landscape') {
                imgWidth = pdfHeight;
                imgHeight = canvas.width * pdfHeight / canvas.height;
            } else {
                imgWidth = pdfWidth;
                imgHeight = canvas.height * pdfWidth / canvas.width;
            }

            console.log("Image dimensions:", imgWidth, imgHeight);

            if (i > 0) {
                pdf.addPage(format, orientation);
            }

            pdf.addImage(
                imgData,
                'PNG',
                0,
                0,
                imgWidth,
                imgHeight
            );
        });
    }

    if (status === "print") {
        console.log('in print status');
        pdf.autoPrint();
        const blob = pdf.output('blob');
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url);
        printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(url);
        };
    } else {
        pdf.save('document.pdf');
    }
};
    return (
        <div
            className="d-flex justify-content-center flex-column"
            style={{ width: "fit-content" }}
        >
            <div
                className="d-flex justify-content-center flex-column p-2"
                style={{ width: "fit-content" }}
            >
                <GenerateDynamicHtml
                    {...{
                        c_address,
                        c_name,
                        c_number,
                        delivery_add,
                        c_gstin,
                        vehicle_no,
                        driver,
                        total_qty,
                        total_disc,
                        total_value,
                        total_cgst,
                        total_sgst,
                        total,
                        taxPerc,
                        hsn,
                        roundOff,
                        hsnCalc,
                        salesAdd
                    }}
                />
            </div>
            <div className="w-100 d-flex justify-content-center gap-4 pb-3">
                <button onClick={handleSalesAllReset} className="btn btn-sm btn-dark">
                    Close
                </button>
                <button
                    className="btn btn-sm btn-dark"
                    onClick={() => handleConvertToPdf("print")}
                >
                    Print
                </button>
                <button
                    className="btn btn-sm btn-dark"
                    onClick={() => handleConvertToPdf("pdf")}
                >
                    Pdf
                </button>
            </div>
            <div className="w-100 d-flex justify-content-center gap-4 pb-3 ">
                <select 
                onChange={(e) => setOrientation(e.target.value)}
                value={orientation}
                className="btn btn-sm btn-dark"
                    >
                    <option value="portrait">Portrait</option>
                    <option value="landscape">Landscape</option>
                </select>
            </div>
        </div>
    );
};
