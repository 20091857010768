import { createSlice } from "@reduxjs/toolkit";

const initAuth = {
    token:sessionStorage.getItem('token')||localStorage.getItem('token')||null,
    userDetails:sessionStorage.getItem('userDetails')?JSON?.parse(sessionStorage.getItem('userDetails')):localStorage.getItem('userDetails')?JSON?.parse(localStorage.getItem('userDetails')):null,
    permissions:sessionStorage.getItem('permissions')?JSON?.parse(sessionStorage.getItem('permissions')):localStorage.getItem('permissions')?JSON?.parse(localStorage.getItem('permissions')):[],
}

const authSlice = createSlice({
    name:"auth",
    initialState:initAuth,
    reducers:{
        login: (state,action) =>{
            if(action.payload.rememberMe){
                localStorage.setItem('token',action.payload.token)
                localStorage.setItem('userDetails',JSON.stringify(action.payload.userDetails))
            }else{
                sessionStorage.setItem('token',action.payload.token)
                sessionStorage.setItem('userDetails',JSON.stringify(action.payload.userDetails))
            }
            state.token = action.payload.token
            state.userDetails = action.payload.userDetails
        },
        logout: (state) =>{
            localStorage.clear()
            sessionStorage.clear()
            state.token = null
            state.userDetails = null
            state.permissions = null
        },
        handlePermissions: (state,action)=>{
            state.modulePermissions = action.payload.module
            state.activityPermissions = action.payload.activity
        },
        userCredentials: (state, action)=>{
            if (action.userDetails){
                localStorage.setItem('userDetails',JSON.stringify(action.userDetails))
            }
            state.userDetails = action.userDetails
        }
    }
})

export const {login, logout, handlePermissions,userCredentials} = authSlice.actions;
export default authSlice.reducer;