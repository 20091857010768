import React, { useEffect, useState } from "react";
import { CompanyDetails } from "./CompanyDetails";
import { CompanyPlan } from "./CompanyPlan";
import { CompanyPermission } from "./CompanyPermission";
import { useLocation, useNavigate } from "react-router";
import { MEDIA_URL } from "../../../api/axios";
import Swal from 'sweetalert2'
import { useCompanyServices } from "../../../services/controller/companyServices";
import { useUserServices } from "../../../services/controller/userServices";
import { permissions,companyModules } from "../data/initialData";

const initCompany = {
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    group_name: null,
    logo: null,
    logo_url: null,
    address_line_1: null,
    address_line_2: null,
    country: null,
    location: null,
    state: null,
    district: null,
    city: null,
    pincode: null,
    username: null,
    password: null,
    image: null,
    image_url: null,
}

const initCompanyPlan = {
  renewal_date: null,
  renewal_time: null,
  extended_date: null,
  staff_limit: null,
  device_limit: null,
  modules: [],
}

export const CompanyAdd = () => {
  const [active, setActive] = useState(1);
  const [companyId, setCompanyId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [moduleCodeList, setModuleCodeList] = useState([]);
  const [activityCodes, setActivityCodes] = useState({});
  const [company, setCompany] = useState(initCompany);
  const [companyPlan, setCompanyPlan] = useState(initCompanyPlan);

  const navigate = useNavigate();
  const location = useLocation();

  const { getCompanyWithId, postCompanyPermission } = useCompanyServices();
  const {getUserWithId ,postUserPermissions} = useUserServices();

  useEffect(() => {
    if (edit.id) {
      const {
        admin_details,
        created_by_details,
        group_detials,
        group_profile_details,
        subscription_history,
        user_data,
        logo,
        image,
        ...others
      } = edit;
      const {
        fk_group,
        user_permissions,
        activity_permissions,
        groups,
        module_permissions,
        ...admin_det_others
      } = admin_details|| user_data || {};
      setCompany({
        ...others,
        ...admin_det_others,
        fk_role:others.role_details?.id,
        image:null,
        logo_url: MEDIA_URL + logo,
        image_url: MEDIA_URL + image,
      });
      // setSelectedRole(edit.fk_role)
      if(location.pathname==="/user-add"){
        const {module_permissions,activity_permissions} = edit
        handleModuleSelection(module_permissions)
        handleActivitySelection(activity_permissions)
      }
    }
  }, [edit]);

  useEffect(()=>{
    let allCompanyDetails = {
      ...{company,edit,moduleCodeList,activityCodes,companyId,active,companyPlan}
    }
    if(company.first_name)
    localStorage.setItem("companyDetails",JSON.stringify(allCompanyDetails))
  },[company])

  useEffect(() => {
    if (location?.state?.company) handleCompanyGet(location?.state?.company);
    else if(location?.pathname === "/user-add") handleReloadData()
  }, [location.state]);

  const handleReloadData = () =>{
    let data = localStorage.getItem('companyDetails')
    if(data){ data = JSON.parse(data)
    if(data?.edit)
      setEdit({...data.edit})
    else{ 
      if(data?.company) setCompany({...data?.company})
      if(data?.companyPlan) setCompanyPlan({...data?.companyPlan})
      if(data?.moduleCodeList) setModuleCodeList(data?.moduleCodeList)
      if(data?.activityCodes) setActivityCodes(data?.activityCodes)
      if(data?.companyId) setCompanyId(data?.companyId)
      if(data?.active) setActive(data?.active)
    }
  }
  // else handleResetAll()
  }

  const handleResetAll = () =>{
    setEdit(false)
    setCompany(initCompany)
    setCompanyPlan(initCompanyPlan)
    setModuleCodeList([])
    setActivityCodes([])
    setActive(1)
    setCompanyId(null)
  }

  const handleActivitySelection = (data) =>{
    let tempActivityCode = {};
    let tempList = data || [];
    if (tempList.length > 0) {
      Object.keys(permissions).forEach((title) => {
        Object.keys(permissions[title]).forEach((sub_title) => {
          permissions[title][sub_title].forEach((x) => {
            if (tempList?.includes(x.code)) {
              tempActivityCode[title + sub_title] =
                tempActivityCode[title + sub_title] || [];
              tempActivityCode[title + sub_title].push(x.code);
            }
          });
        });
      });
      setActivityCodes({ ...tempActivityCode });
    }
  }

  const handleModuleSelection = (data) => {
    let tempList = [...moduleCodeList];
    let filteredList = [];
    companyModules.forEach((item) => {
      if (
        item.code === data.code ||
        item.code === data.parent ||
        data.code === item.parent
      ) {
        filteredList.push({ code: item.code, parent: item.parent });
      }
    });
    // console.log(filteredList.every(i=>tempList.findIndex(x=>x.code==i.code)>-1))
    if (
      filteredList.some(
        (i) => tempList.findIndex((x) => x.code == i.code) > -1
      ) &&
      !data?.parent
    ) {
      tempList = tempList.filter(
        (x) => filteredList.findIndex((i) => i.code === x.code) == -1
      );
    } else if (tempList.some((i) => i.code == data.code) && data?.parent) {
      tempList = tempList.filter((x) => x.code !== data.code);
    } else {
      tempList = [...tempList, ...filteredList];
    }
    setModuleCodeList([...tempList]);
  };

  const handleSubmitPermission = async (e,action) => {
    e.preventDefault();
    try {
      let resp;
      var companyPermissions = [];
      Object.keys(activityCodes).forEach((x) =>
        activityCodes[x]?.forEach((y) =>
          companyPermissions.push({ code: y, is_active: false })
        )
      );
      let tempCompanyModuleList = moduleCodeList.map((data) => ({
        code: data.code,
        is_active: true,
      }));
      // console.log(activityCodes)
      // console.log({activity_permissions:companyPermissions,module_permissions:tempCompanyModuleList})
      // return 0
      if (action==='company'){
        resp = await postCompanyPermission(companyId, {
          activity_permissions: companyPermissions,
          module_permissions: tempCompanyModuleList,
        });
      }
      else if (action==='user'){
        let userId = companyId
        if(edit.id) userId = edit.id
        resp = await postUserPermissions(userId, {
          activity_permissions: companyPermissions,
          module_permissions: tempCompanyModuleList,
        });
      }
      
      if (resp?.success) {
        Swal.fire("Success", "", "success");
        setCompanyId(false);
        setEdit(false);
        setModuleCodeList([]);
        setActivityCodes([]);
        setActive(1);
        if(location.pathname === "/user-add"){
          navigate('/user-list')
          localStorage.setItem('companyDetails',false)
        }else{
        navigate("/");
        localStorage.setItem('companyDetails',false)}
      }
    } catch (err) {
      // console.log(err)
      var message = err?.response?.data?.message || "Something went wrong pls try again later !";
      if (err?.response?.data?.errors && typeof err?.response?.data?.errors !== "string") {
        message = Object.values(err?.response?.data?.errors)[0];
      }
      Swal.fire("Error", message, "error");
    }
  };

  const handleCompanyGet = async (id) => {
    try {
      let resp;
      if(location.pathname==="/user-add"){
        resp = await getUserWithId(id)
      }else
      resp = await getCompanyWithId(id);
      if (resp.success) {
        setEdit(resp.data);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div className="company-add-cont pb-1">
      <div className={`company-progress-bar row mx-0 rounded-2`}>
        <span
          className={`company-progress-loader ${active > 1 && "act2"} ${
            active > 2 && "act3"
          }`}
        >
          <div className="circle"></div>
          <div className="circle animation2"></div>
          <div className="circle animation3"></div>
        </span>
        <div
          className={`col-3 col-4 bar-text border-0 ${active > 0 && "active"}`}
          style={{ zIndex: "3" }}
        >
          1. Basic Details
        </div>
        {location.pathname != "/user-add" && (
          <div
            className={`col-3 col-4 bar-text ${active > 1 && "active"}`}
            style={{ zIndex: "3" }}
          >
            2. Plan Details
          </div>
        )}
          <div
            className={`col-3 col-4 bar-text ${active > 1 && "active"}`}
            style={{ zIndex: "3" }}
          >
            {location.pathname ==='/user-add'?'2':'3'}. Modules
          </div>
        {/* <div
          className={`col-4 bar-text ${active > 2 && "active"}`}
          style={{ zIndex: "3" }}
        >
          3. Modules
        </div> */}
      </div>
      <div className="company-add">
        <h3>
          {location.pathname != "/user-add"
            ? active === 1
              ? "Company Details"
              : active === 2
              ? "Company Plan Details"
              : active === 3 && "Access Permissions"
            : active === 1
            ? "User Details"
            : active === 3
            ? "Access Permissions"
            : ""}
        </h3>
        {/* <div className="company-details-cont row justify-content-between mx-0 my-2 p-0"> */}
        {active === 2 ? (
          <CompanyPlan
            {...{
              companyPlan,
              setCompanyPlan,
              moduleCodeList,
              setModuleCodeList,
              handleModuleSelection,
              edit,
              active,
              setActive,
              companyId,
              setCompanyId,
            }}
          />
        ) : active === 3 ? (
          <CompanyPermission
            {...{
              handleActivitySelection,
              moduleCodeList, 
              setModuleCodeList,
              handleSubmitPermission,
              setActive,
              company,
              activityCodes,
              setActivityCodes,
            }}
          />
        ) : (
          <CompanyDetails
            {...{
              setModuleCodeList,
              edit,
              active,
              setActive,
              setCompanyId,
              setEdit,
              location,
              company,
              setCompany,
              setCompanyPlan,
            }}
          />
        )}
        {/* </div> */}
      </div>
    </div>
  );
};
