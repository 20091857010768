import React from "react";
import searchIcon from "../../../../assets/icons/search.png";
import { GrRefresh } from "react-icons/gr";

const ProfitWiseTable = (props) => {
  const { itemWiseProfit } = props;

  let totalQty = 0;
  let totalSales = 0;
  let totalSalesR = 0;
  let totalCost = 0;
  let totalProfit = 0;

  return (
    <div className="mx-3 mt-3 item-wise-pft">
      <div className="mt-1 d-flex justify-content-end" style={{backgroundColor:"rgba(77, 48, 114, 1)"}}>
        <div className="col-3 p-2 stock-ledger-search d-flex align-items-center me-1">
          <div className="col-2 me-3">
            <GrRefresh className="bg-light m-1 p-1 rounded-1" size={28} />
          </div>
          <div className="item_seach_bar_cont rounded-2 col-9 py-3">
            <img src={searchIcon} className="search_img me-3 ms-2 py-2" />
            <input
              // value={search}
              //   onChange={handleSearch}
              className="item_search_bar rounded-2 border-0 "
              placeholder="Search"
              type="text"
            />
          </div>
        </div>
      </div>

      <div className="cashbook-table-main1">
        <table className="cash-table-top1 ">
          <thead>
            <tr className="cashbook-table-thead-th1 p-5">
              <th className="ps-3">Code</th>
              <th>Name</th>
              <th>Batch No</th>
              <th>Qty</th>
              <th>Sales</th>
              <th>Sales Return</th>
              <th>Cost</th>
              <th>Profit</th>
              <th>Profit%</th>
            </tr>
          </thead>

          <tbody>
            {itemWiseProfit.length>0 ? itemWiseProfit.map((data,index)=>{
              const item_data =data?.item_profit?.item_profit_value
              const batches = item_data?.batch || []
              if (batches.length > 0) {
                return (
                  <React.Fragment key={index}>
                    <tr className="cashbook-table-thead-td1 ps-5">
                      <td colSpan={9}>{(item_data?.item_name).toUpperCase()}</td>
                    </tr>
                    { batches.map((d, i) => {

                       if (d?.quantity > 0) {
                        console.log(totalQty)
                        totalQty = totalQty + d?.quantity;
                        totalSales = totalSales +d?.total_sale;
                        totalSalesR = totalSalesR + d?.sales_return;
                        totalCost = totalCost + d?.total_cost;
                        totalProfit = totalProfit + d?.profit;
                      }
                      
                      return d?.quantity>0 && (
                          <tr key={i} className="cashbook-table-row text-black">
                            <td className="ps-3">{item_data?.item_code}</td>
                            {/* <td></td> */}
                            <td>{(item_data?.item_name).toUpperCase()}</td>
                            {/* <td></td> */}
                            <td>{d?.batch_no || "----"}</td>
                            <td>{d?.quantity || 0}</td>
                            {/* <td>{d?.sales || 0}</td> */}
                            <td>{d?.total_sale?.toFixed(2) || 0}</td>
                            <td>{d?.sales_return?.toFixed(2)}</td>
                            <td>{d?.total_cost?.toFixed(2)}</td>
                            <td>{d?.profit?.toFixed(2)}</td>
                            <td>{(d?.profit / d?.total_sales) * 100 || 0.0} %</td>
                          </tr>
                          )})}
                  </React.Fragment>
                );
              }else{
                  let proPerc = 0; 
                  proPerc = (item_data?.profit / item_data?.total_sales) * 100 || 0.0;
                  if (item_data.quantity > 0) {
                    totalQty = totalQty + item_data?.quantity;
                    totalSales = totalSales + item_data?.total_sales;
                    totalSalesR = totalSalesR + item_data?.sales_return;
                    totalCost = totalCost + item_data?.total_cost;
                    totalProfit = totalProfit + item_data?.profit;
                  }
                  return (
                    <React.Fragment key={index}>
                      <tr className="cashbook-table-thead-td1 ps-5">
                        <td colSpan={9}>{(item_data?.name).toUpperCase()}</td>
                      </tr>
                      {item_data.quantity > 0 && (
                        <tr key={index} className="cashbook-table-row text-black">
                           {/* style={{backgroundColor:"rgb(217, 191, 252)"}} */}
                          <td className="ps-3">{item_data?.code}</td>
                          <td>{(item_data?.name).toUpperCase()}</td>
                          <td>{"---------"}</td>
                          <td>{item_data?.quantity}</td>
                          <td>{item_data?.total_sales?.toFixed(2)}</td>
                          <td>{item_data?.sales_return?.toFixed(2)}</td>
                          <td>{item_data?.total_cost?.toFixed(2)}</td>
                          <td>{item_data?.profit?.toFixed(2)}</td>
                          <td>{proPerc.toFixed(2) || 0} %</td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
              }}): (
              <tr>
                <td colSpan={9} className="text-center fs-4 text-black pe-5">
                  No report yet
                </td>
              </tr>
            )}
            <tr className="item_profit_table-bottom1">
              <td colSpan={3} className="py-4"></td>
              <td style={{ textAlign: "start" }} className="">
                {totalQty}
              </td>
              <td style={{ textAlign: "start" }} className="">
                {totalSales.toFixed(2)}
              </td>
              <td style={{ textAlign: "start" }} className="">
                {totalSalesR.toFixed(2)}
              </td>
              <td style={{ textAlign: "start" }} className="">
                {totalCost.toFixed(2)}
              </td>
              <td style={{ textAlign: "start" }} className="">
                {totalProfit.toFixed(2)}
              </td>
              <td style={{ textAlign: "start" }} className=""></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfitWiseTable;
