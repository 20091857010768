import React, { useEffect, useState } from "react";
import useItemServices from "../../../../services/master/itemServices";
import { Dropdown } from "semantic-ui-react";
import { BsTrashFill } from "react-icons/bs";
import Swal from "sweetalert2";
import useOnKey from "../../../../hooks/onKeyFunct/onKeyFunct";
import { useLocation, useNavigate } from "react-router";
import dayjs from "dayjs";
import { StockPop } from "../../components/StockPop";
import { Modal } from "react-bootstrap";
import useSalesServices from "../../../../services/transactions/salesServices";
// import usePurchaseServices from "../../../../services/transactions/purchcaseServices";
// import usePurchaseReturnServices from "../../../../services/transactions/purchaseReturn";

const PurchaseTable = (props) => {
  const {
    from,
    permissions,
    purchaseInvoiceRef,
    tableItemRef,
    setTableItemRef,
    handleGetCode,
    handleSetEdit,
    tableHeadList,
    setPurchaseItemModal,
    tableItem,
    setTableItem,
    edit,
    handlePurchAllCalc,
    purchaseAdd,
    tableItemList,
    setTableItemList,
    tableEdit,
    setEdit,
    itemNameList,
    setItemNameList,
    purchaseOrReturnList,
    handleTableItemReset,
    handlePurchaseAllReset,
    setShowBatch,
    batchType,
  } = props;
  const [unitList, setUnitList] = useState();
  const [tableItemListRef, setTableItemListRef] = useState(null);
  const [showStock, setShowStock] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const [checkBatchActive,setCheckBatchActive] = useState(false);
  const { getItemNameList, getProperty } = useItemServices();
  const { getSalesItem } = useSalesServices();
  const [handleKeyDown, formRef] = useOnKey(
    tableItemRef,
    setTableItemRef,
    purchaseInvoiceRef,
    "repeat"
  );
  const [handleKeyDown2, formRef2] = useOnKey(
    tableItemListRef,
    setTableItemListRef,
    tableItemRef,
    false,
    tableItemList
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, [purchaseAdd.total]);

  useEffect(() => {
    getTableData();
    if (location?.state?.fromItemAdd) {
      let item = location.state?.item;
      const { purchase_rate, discount_1_percentage, tax_gst, fk_unit } =
        item?.item_data || {};
      let tempItem = { ...tableItem };
      tempItem = {
        ...tempItem,
        code: item?.item_data?.code,
        item_name: item?.item_data?.name,
        fk_items: item?.item_data?.id,
        rate: purchase_rate || 0,
        discount_1_percentage: discount_1_percentage || 0,
        tax_gst: tax_gst || 0,
        fk_units: fk_unit,
        fk_unit: fk_unit,
        unit: fk_unit,
        cgst_or_igst: item?.tax_gst / 2 || 0,
        sgst: item?.tax_gst / 2 || 0,
        margin: item?.margin,
        sales_rate: item?.retail_rate,
      };
      setTableItem((data) => ({ ...data, ...tempItem }));
      navigate(null, { state: null });
      document
        .getElementById("tableItemFkItem")
        ?.parentNode?.nextSibling?.firstChild?.focus();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (tableItemList?.length > 10)
      document
        .getElementById("tableItemFkItem")
        .scrollIntoView({ behavior: "smooth" });
  }, [tableItemList]);

  const getTableData = async () => {
    const minFunct = (data) => {
      let list = [];
      data.forEach((x) => {
        if (x.property_type === "unit") {
          list.push({ value: x["id"], text: x["property_value"] });
        }
      });
      setUnitList(list);
    };

    const handleDataNameList = (data) => {
      let tempList = [];
      // console.log("hoiiiiiiiiiiiiiiiiiiiiiiii", data);
      if (Array.isArray(data)) {
        data?.forEach((x) => {
          const {
            discount_1_percentage,
            tax_gst,
            purchase_rate,
            retail_rate,
            margin,
            batch_active,
          } = x;
          tempList.push({
            ...{
              batch_active,
              discount_1_percentage,
              tax_gst,
              purchase_rate,
              retail_rate,
              margin,
            },
            text: x.name,
            description: x.code,
            value: x.id,
            unit: x.fk_unit,
          });
        });
      } else {
        console.log("Data is not an array", data);
      }
      setItemNameList([...tempList]);
    };
    const handleItemNameReturnList = (data,iData) => {
      // console.log("hoi",data);
      // console.log("alldata",iData)
      let tempList = [];
      data?.map((x) => {
        // console.log(x)
        const { id, code, name, ...others } = x;
        if (x.batch_active){
        tempList.push({
          ...others,
          text: x.item_name,
          description: x.item_code,
          value: x.batch_list[0]?.fk_items,
        });
        }else if(iData.length>0){
          const matchitem =  iData.find((item)=>item.code === x.item_code)
          if (matchitem){
            tempList.push({
              ...{
               ...matchitem
              },
              text: matchitem.name,
              description: matchitem.code,
              value: matchitem.id,
              unit: matchitem.fk_unit,
              item_name: matchitem.name,
              item_code: matchitem.code,
            });
          }
        }
      });
      setItemNameList([...tempList]);
    };
    try {
      let res;
      let res3;
      let res2 = await getProperty();
      if (from === "purch Return") {
        res = await getSalesItem();
        res3 = await getItemNameList();
      }
      else res = await getItemNameList();
      // console.log("Nokk Nokk", res);
      // console.log("Nokki",res3)
      if (res2?.success) minFunct(res2.data);
      if (res?.success) {
        console.log("looooooooooook",batchType)
        if (batchType === "batch") {
          if (from !== "purch Return") handleDataNameList(res.data);
          else handleItemNameReturnList(res.data.items);
        } else if(from === "purch Return") {
          handleItemNameReturnList(res.data.items,res3.data);
        } else {
          handleDataNameList(res.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
console.log("itemNameList",itemNameList)
  const handleItemNameSelection = (e, { value }) => {
    // Swal.fire()
    let data = value?.toUpperCase();
    if (data) {
      navigate("/add", {
        state: { fromPurchase: true, name: data },
      });
    }
  };

  // handle table item changing-----------------------------------

  const handleDropOpen = (e, item, state, toTableItem) => {
    let data = item?.options[0];
    if (state.fk_items) {
      data = item?.options?.filter((x) => x.value == state.fk_items)[0];
    }
    if (data) {
      setItemSelected({ data, e, state, toTableItem });
    }
  };

  const handleKeyDownStockPopup = (e, fromList,checkBatch) => {
    console.log("look at this",checkBatch)
    if (e?.type === "keydown" && e?.code === "Enter") {
      if (from === "purch Return" && checkBatch) setShowStock(true);
      if (fromList) handleKeyDown2(e);
      else handleKeyDown(e);
    }
  };

  const handleSelectItemFromDrop = (e, data, state, toTableItem) => {
    let item_data;
    if (data.value){
      item_data =data?.options?.filter((x) => x?.value === data?.value)[0] || {};
      console.log("Item Data: ", item_data);
      if (item_data.batch_active===false){
        setItemSelected({ data: item_data,...{ e, state, toTableItem } })
        console.log("Item Selected without batch: ", { data: item_data, ...{ e, state, toTableItem } });
        handleChangeTableItem(e,item_data,state,toTableItem)
      }else{
        var newObj = Object.fromEntries(Object.entries(item_data)?.filter(([key, value]) => value !== null));
        console.log("New Object: ", newObj);
        if (newObj) {
          newObj.code = itemSelected.item_code;
          setItemSelected({ data: newObj, ...{ e, state, toTableItem } });
          console.log("Item Selected with batch: ", { data: newObj, ...{ e, state, toTableItem } });
        }
      }
      if (e?.type == "click") handleKeyDown(e);
    } else {
      handleTableItemReset();
    }
    if (e.type === "click" && data?.value && item_data.batch_active === true) {
      console.log("Click Event");
      setShowStock(true);
      let tdElem =
        e.target.parentElement.parentElement.parentElement.parentElement;
      // let dropMenuElem = e.target.parentElement;
      if (tdElem) {
        tdElem?.querySelector("input")?.blur();
        tdElem?.nextSibling?.querySelector("input")?.focus();
      }
    }else{
      handleKeyDown(e)
    }
  };
  // console.log("itemSelected", itemSelected);
  const handlebatchChange = (data) => {
    handleChangeTableItem(
      itemSelected.e,
      data,
      itemSelected.state,
      itemSelected.toTableItem,
      true
    );
  };

  const handleChangeTableItem = (e, data, state, toTableItem, batch) => {
    // toTableItem is used to check if the state to be set to tableItem or tableItemList
    // if toTableItem is not true then it contains the index of tableItemList
    let tempItem = { ...state };
    let newObj;
    if (data?.value || batch) {

      if (!batch) {
        console.log("no batch")
        var item_data =
          data?.options?.filter((x) => x?.value === data?.value)[0] || {};
        newObj = Object.fromEntries(
          Object.entries(item_data)?.filter(([key, value]) => value !== null)
        );
      } else newObj = data;

      const { purchase_rate, discount_1_percentage, tax_gst, fk_unit } = newObj || {};
      tempItem = {
        ...tempItem,
        rate: batch ? newObj?.rate : purchase_rate || 0,
        discount_1_percentage: discount_1_percentage || 0,
        tax_gst: tax_gst || 0,
        fk_units: fk_unit,
        fk_unit: fk_unit,
        // quantity: itemData ? 1 : 0,
        item_name: batch ? newObj?.item_name : newObj?.text,
        code: newObj?.description,
        fk_items: batch ? newObj?.fk_items : newObj?.value,
        fk_unit: newObj?.unit,
        margin: newObj?.margin,
        sales_rate: newObj?.retail_rate,
        batch_no: newObj?.batch_no,
        expiry_date: newObj?.expiry_date,
      };
    } else if (data?.name === "name") {
      handleTableItemReset();
      return 0;
    }
    if (e?.target?.value === "") {
      tempItem = { ...tempItem, [e.target.name]: "" };
    } else if (e?.target?.type === "number") {
      tempItem = { ...tempItem, [e.target.name]: parseFloat(+e.target.value) };
    } else {
      tempItem = { ...tempItem, [e?.target?.name]: e?.target?.value };
    }
    // let calculatedData = tempItem
    // if(!data)
    let name = e?.target?.name;
    if (data) name = data.name;
    console.log("tempItem",tempItem);
    let calculatedData = handleAmountCalculation(tempItem, name, state);
    if (toTableItem === true) setTableItem(calculatedData);
    else {
      let tempList = [...tableItemList];
      tempList.splice(toTableItem, 1, { ...calculatedData });
      setTableItemList([...tempList]);
      handlePurchAllCalc([...tempList], false, false);
    }
  };

  //  calculating table item values

  const handleAmountCalculation = (tempItem, name, state) => {
    // console.log("Check",tempItem)
    let value = {};
    let total, cost;
    if (tempItem.rate && tempItem.quantity) {
      total = tempItem.total;
      cost = tempItem.cost;
      total = tempItem.quantity * tempItem.rate;
      cost = tempItem.rate;
      value = {
        value: tempItem.quantity * tempItem.rate,
        total: total,
        cost: cost,
      };
      tempItem = { ...tempItem, ...value };
      if (name !== "discount_1_amount" && tempItem.discount_1_percentage) {
        value = {
          ...value,
          discount_1_amount:
            value.value -
            (value.value -
              tempItem.discount_1_percentage * (value.value / 100)),
          value:
            tempItem.quantity * tempItem.rate -
            tempItem.quantity *
              tempItem.rate *
              (tempItem.discount_1_percentage / 100),
          // discount_1_amount: (value.quantity*value.rate)*(tempItem.discount_1_percentage/100)
        };
      } else if (name !== "discount_1_amount") {
        value = {
          ...value,
          discount_1_amount: 0,
        };
      }

      tempItem = { ...tempItem, ...value };
      if (name === "discount_1_amount" && tempItem.discount_1_amount) {
        value = {
          ...value,
          discount_1_percentage:
            (tempItem.discount_1_amount / value.value) * 100,
        };
      } else if (name === "discount_1_amount") {
        value = { ...value, discount_1_percentage: 0 };
      }
      tempItem = { ...tempItem, ...value };
      if (tempItem.value && tempItem.discount_1_amount) {
        tempItem.discount_1_amount = parseFloat(tempItem.discount_1_amount);
        let total =
            parseFloat(tempItem.quantity * tempItem.rate) -
            parseFloat(tempItem.discount_1_amount),
          value = {
            ...tempItem,
            value:
              parseFloat(tempItem.quantity * tempItem.rate) -
              parseFloat(tempItem.discount_1_amount),
            total: total,
            cost: total / tempItem.quantity || 1,
          };
      } else if (name !== "margin" && name !== "sales_rate") {
        value = {
          ...value,
          value: tempItem.quantity * tempItem.rate,
          total: tempItem.quantity * tempItem.rate,
          cost: tempItem.rate,
        };
      }
      tempItem = { ...tempItem, ...value };

      if (tempItem.tax_gst) {
        let totalTaxAmnt = +tempItem.tax_gst * (+tempItem.value / 100);
        let sgst = (totalTaxAmnt / 2)?.toFixed(2);
        let isVat = tableHeadList?.filter((x) => x.state == "vat")[0]?.visible;
        let total = +tempItem.value + sgst * 2;
        value = {
          ...value,
          total: total,
          cost: total / tempItem.quantity || 1,
        };

        if (isVat) {
          value = {
            ...value,
            vat_perc: sgst * 2,
          };
        } else {
          value = {
            ...value,
            cgst_or_igst: sgst,
            sgst: sgst,
          };
        }
      } else {
        value = { ...value, cgst_or_igst: 0, sgst: 0, vat_perc: 0 };
      }

      tempItem = { ...tempItem, ...value };
      if (name !== "sales_rate" && name !== "name") {
        if (tempItem.margin > 0 && tempItem.cost > 0) {
          value = {
            ...tempItem,
            sales_rate:
              +tempItem.cost?.toFixed(2) +
              +tempItem.cost?.toFixed(2) * (+tempItem.margin / 100),
          };
        } else {
          value = { ...value, sales_rate: 0 };
        }
      }
      tempItem = { ...tempItem, ...value };
      if (name !== "margin" && name !== "name") {
        if (tempItem.sales_rate > 0 && tempItem.cost > 0) {
          value = {
            ...value,
            margin: parseFloat(
              ((tempItem.sales_rate - tempItem.cost) / tempItem.cost) * 100
            ),
          };
        } else if (name == "sales_rate") {
          value = { ...value, margin: 0 };
        }
      }
    } else {
      tempItem = {
        ...tempItem,
        value: 0,
        sgst: 0,
        cgst_or_igst: 0,
        total: 0,
        cost: 0,
        sales_rate: 0,
      };
    }
    tempItem = { ...tempItem, ...value };
    let tempItemKeys = Object.keys(tempItem);
    tempItemKeys?.forEach((key) => {
      let number = parseFloat(tempItem[key]);
      if (number?.toFixed(2) && !Number.isInteger(number) && number) {
        tempItem = { ...tempItem, [key]: parseFloat(number?.toFixed(2)) };
      }
    });

    return tempItem;
  };

  // -----------------------------------------------------

  const handleAddOpenBatch = (e) => {
    e.preventDefault();
    if (e.type === "keydown") {
      if (e?.key !== "Enter") return 0;
    }
    if (
      !tableEdit &&
      (!tableItem.item_name || !tableItem.quantity || !tableItem.rate)
      //  || !tableItem.batch_no
    ) {
      handleKeyDown(e);
      Swal.fire({
        title: "please enter Essential details firs",
        text: "Enter Rate , Quantity, and Select Item First",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500,
      });
      return 0;
    }

    let tempList = [...tableItemList];
    tempList.push({ ...tableItem });
    setTableItemList([...tempList]);
    handlePurchAllCalc([...tempList]);
    if (purchaseAdd.isBatch) setShowBatch(true);
    else {
      handleTableItemReset();
      handleKeyDown(e);
    }
  };

  const handlePrev = () => {
    if (purchaseOrReturnList?.length > 0) {
      if (!edit) {
        handlePurchaseAllReset(true);
        setEdit({ ...purchaseOrReturnList[0] });
        handleSetEdit(purchaseOrReturnList[0]);
      } else {
        let ind = purchaseOrReturnList?.findIndex((x) => edit.id === x.id);
        if (ind !== purchaseOrReturnList?.length - 1) {
          handlePurchaseAllReset(true);
          setEdit({ ...purchaseOrReturnList[ind + 1] });
          handleSetEdit(purchaseOrReturnList[ind + 1]);
        } else {
          Swal.fire("No more purchase to edit", "go for next", "warning");
        }
      }
    } else {
      Swal.fire("No more purchase to edit", "go for next", "warning");
    }
  };

  const handleNext = () => {
    if (purchaseOrReturnList?.length > 0)
      if (!edit) {
        Swal.fire("No more purchase to edit", "go for prev", "warning");
      } else if (edit?.id === purchaseOrReturnList[0]?.id) {
        handlePurchaseAllReset(true);
        handleGetCode(true);
      } else {
        handlePurchaseAllReset(true);
        let ind = purchaseOrReturnList?.findIndex((x) => edit.id === x.id);
        if (ind !== purchaseOrReturnList[0]) {
          setEdit(purchaseOrReturnList[ind - 1]);
          handleSetEdit(purchaseOrReturnList[ind - 1]);
        } else {
          handlePurchaseAllReset(true);
          Swal.fire("No more purchase to edit", "go for prev", "warning");
        }
      }
  };

  const AdjustHeightOfTable = () => {
    let tableTr = [];
    tableTr.push(
      <tr key={0}>
        <td className="border-0" colSpan={tableHeadList.length + 2}></td>
      </tr>
    );
    for (let i = 0; i < 8 - purchaseAdd.total_items || 0; i++) {
      tableTr.push(
        <tr key={i + 1}>
          <td className="border-0" colSpan={tableHeadList.length + 2}></td>
        </tr>
      );
    }
    return tableTr;
  };

  const handleTableItemDelete = async (data, i) => {
    if (tableItemList?.length < 2 && edit) {
      Swal.fire({
        title: "There is only one item exist.",
        text: "You cant delete.",
        icon: "warning",
        timer: 1500,
      });
      return 0;
    }
    let tempList = [...tableItemList];
    tempList.splice(i, 1);
    setTableItemList([...tempList]);
    handlePurchAllCalc(tempList, false);
  };

  const search = (options, searchValue) => {
    searchValue = searchValue.toUpperCase();
    return options.filter((option) => {
      const text = option?.text?.toString().toUpperCase() || '';
      const description = option?.description?.toString().toUpperCase() || '';
      return (
        text.includes(searchValue) ||
        description.includes(searchValue)  
      );
    });
  };

  const handleFocus = (e) => {
    if (!tableItem[e.target.name])
      setTableItem((data) => ({ ...data, [e.target.name]: "" }));
  };

  const handleBlur = (e) => {
    if (
      !tableItem[e.target.name] ||
      tableItem[e.target.name] == "" ||
      tableItem[e.target.name] == "0"
    ) {
      setTableItem((data) => ({ ...data, [e.target.name]: 0 }));
    }
  };
  //  console.log('Heading',tableHeadList)
  return (
    <>
      <div
        style={{ boxShadow: "0px 0px 5px rgba(77, 48, 114, 1)" }}
        className="mx-2 mt-1 purchase-table-item-container px-0 "
      >
        <table className="table table-secondary purchase-table mb-0 ">
          <thead className="purchase-table-header">
            <tr>
              <th width="30">SL</th>
              {tableHeadList?.length > 0 &&
                tableHeadList.map((item, i) => {
                  if (item.visible && item.purchaseShow)
                    return i === 0 ? (
                      <th width="600" className="text-start" colSpan={1}>
                        {item.title}
                      </th>
                    ) : (
                      <th>{item.title}</th>
                    );
                  else return null;
                })}
              <th className="py-1 text-end">
                <div
                  className="btn purchase-add-btn my-0 py-0"
                  onClick={() => setPurchaseItemModal(true)}
                >
                  +
                </div>
              </th>
              {/* <th className="py-1 text-end"></th> */}
            </tr>
          </thead>
          <tbody className="purchase-table-body">
            {tableItemList?.length > 0 &&
              tableItemList.map((data, i) => {
                const confirmDelete = async () => {
                  Swal.fire({
                    title: "Delete Item",
                    text: "Do you want to delete Item ?",
                    showDenyButton: true,
                    showCancelButton: false,
                    denyButtonText: "Cancel",
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                      await handleTableItemDelete(data, i);
                    },
                    preDeny: () =>
                      Swal.fire({
                        title: "Canceled",
                        showConfirmButton: false,
                        timer: 1500,
                      }),
                  });
                };
                // console.log(";;;;;;;;;;;;;;;;;;;;;;;;;;;:::::::::::::::::::::",data)
                return (
                  <tr
                    id="purchSaletableBodyTr"
                    ref={(el) => (formRef2.current[i] = el)}
                  >
                    <td>{i + 1}</td>
                    {tableHeadList?.length > 0 &&
                      tableHeadList.map((item, index) => {
                        if (item.visible && item.purchaseShow) {
                          return index === 0 ? (
                            <td className="text-start ps-3 pe-2" colSpan={1}>
                              <Dropdown
                                selection
                                onChange={(e, a) => {
                                  let item ;
                                  if (a?.value !== "" || undefined) {
                                      item =a?.options?.filter((x) => x?.value === a?.value)[0] || {};
                                      console.log("item batch edit",item?.batch_active)
                                      setCheckBatchActive(item?.batch_active)
                                  }
                                  if (from === "purch Return" && item?.batch_active)
                                    handleSelectItemFromDrop(e, a, data, i);
                                  else handleChangeTableItem(e, a, data, i);
                                }}
                                onOpen={(e, a) =>
                                  handleDropOpen(e, a, data, false)
                                }
                                onKeyDown={(e) =>
                                  handleKeyDownStockPopup(e, true,checkBatchActive)
                                }
                                upward={
                                  purchaseAdd.total_items > 4 ? true : false
                                }
                                search={search}
                                disabled={
                                  ((from === "purch" &&
                                    !permissions.includes(1170)) ||
                                    (from === "purch Order" &&
                                      !permissions.includes(1246)) ||
                                    (from === "purch Return" &&
                                      !permissions.includes(1208))) &&
                                  item.readOnly
                                }
                                placeholder="SELECT"
                                className="purchase_search_drop border-0 w-100 ps-2"
                                name={"name"}
                                value={data.fk_items || data.name}
                                options={itemNameList}
                              />
                            </td>
                          ) : item.state === "fk_unit" ? (
                            <td>
                              <select
                                onChange={(e) =>
                                  handleChangeTableItem(e, null, data, i)
                                }
                                onFocus={(e) => e.target.classList.add("show")}
                                onKeyDown={handleKeyDown2}
                                name="fk_unit"
                                value={data.fk_unit}
                                disabled={
                                  ((from === "purch" &&
                                    !permissions.includes(1170)) ||
                                    (from === "purch Order" &&
                                      !permissions.includes(1246)) ||
                                    (from === "purch Return" &&
                                      !permissions.includes(1208))) &&
                                  item.readOnly
                                }
                                style={{
                                  WebkitAppearance: "none",
                                  fontSize: "10px",
                                  padding: "3.5px 1px",
                                }}
                                className="purchase_input border-0 w-100 text-center"
                              >
                                {unitList &&
                                  unitList.map((x, i) => (
                                    <option key={i} value={x.value}>
                                      {x.text}
                                    </option>
                                  ))}
                              </select>
                            </td>
                          ) : item.state === "expiry_date" ? (
                            <td colSpan={1}>
                              <input
                                onKeyDown={handleKeyDown}
                                name={item.state}
                                onChange={(e) =>
                                  handleChangeTableItem(e, null, data, i)
                                }
                                disabled={
                                  ((from === "purch" &&
                                    !permissions.includes(1170)) ||
                                    (from === "purch Order" &&
                                      !permissions.includes(1246)) ||
                                    (from === "purch Return" &&
                                      !permissions.includes(1208))) &&
                                  item.readOnly || checkBatchActive===false
                                }
                                value={
                                  (data[item.state] &&
                                    dayjs(data[item.state]).format(
                                      "YYYY-MM-DD"
                                    )) ||
                                  ""
                                }
                                type="date"
                                className="purchase_input border-0 w-100 text-center"
                              />
                            </td>
                          ) : item.state === "batch_no" ?(
                            <td colSpan={1}>
                              <input
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                name={item.state}
                                onChange={(e) =>
                                  handleChangeTableItem(e, null, data, i)
                                }
                                disabled={((from === "purch" &&
                                  !permissions.includes(1170)) ||
                                  (from === "purch Order" &&
                                    !permissions.includes(1246)) ||
                                  (from === "purch Return" &&
                                    !permissions.includes(1208))) && item.readOnly || checkBatchActive===false}
                                value={
                                data[item.state]||"0"
                                }
                                type={item.state == "batch_no" ? "text" : "number"}
                                className="purchase_input border-0 w-100 text-center"
                              />
                            </td>
                          ):(
                            <td>
                              <input
                                onChange={(e) =>
                                  handleChangeTableItem(e, null, data, i)
                                }
                                onKeyDown={handleKeyDown2}
                                name={
                                  item.state == "vat" ? "tax_gst" : item.state
                                }
                                type={
                                  item.state == "batch_no" ? "text" : "number"
                                }
                                disabled={
                                  ((from === "purch" &&
                                    !permissions.includes(1170)) ||
                                    (from === "purch Order" &&
                                      !permissions.includes(1246)) ||
                                    (from === "purch Return" &&
                                      !permissions.includes(1208))) &&
                                  item.readOnly
                                }
                                placeholder="0"
                                className="purchase-table-items-input "
                                value={
                                  item.state === "vat"
                                    ? data.tax_gst || ""
                                    : data[item?.state] || ""
                                }
                              />
                            </td>
                          );
                        } else return null;
                      })}
                    <td>
                      <div
                        onClick={() => confirmDelete()}
                        className="text-center w-100"
                      >
                        {((from === "purch" && !permissions.includes(1171)) ||
                          (from === "purch Return" &&
                            !permissions.includes(1247))) && (
                          <BsTrashFill className="mb-1 btn p-0" size={"16px"} />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            <tr className="input-tr" ref={formRef}>
              <td></td>
              {tableHeadList?.length > 0 &&
                tableHeadList.map((item, i) => {
                  if (item.visible && item.purchaseShow)
                    return item.state === "item_name" ? (
                      <td
                        className="purchase_search_drop_td text-start ps-3 pe-2"
                        colSpan={1}
                      >
                        <Dropdown
                          clearable
                          selection
                          required
                          onChange={(e, data) => {
                            let item ;
                            if (data?.value !== "" || undefined) {
                                item =data?.options?.filter((x) => x?.value === data?.value)[0] || {};
                                console.log("item batch",item?.batch_active)
                                setCheckBatchActive(item?.batch_active)
                            }
                            if (from === "purch Return" && item?.batch_active)
                              handleSelectItemFromDrop(
                                e,
                                data,
                                tableItem,
                                true,
                              );
                            else
                              handleChangeTableItem(e, data, tableItem, true);
                          }}
                          onOpen={(e, data) =>
                            handleDropOpen(e, data, tableItem, true)
                          }
                          onKeyDown={(e) => {
                            console.log("item",checkBatchActive)
                            handleKeyDownStockPopup(e,false,checkBatchActive)
                          }}
                          upward={purchaseAdd.total_items > 4 ? true : false}
                          search={search}
                          placeholder="SELECT"
                          className="purchase_search_drop border-0 w-100 ps-2"
                          allowAdditions
                          id="tableItemFkItem"
                          onAddItem={handleItemNameSelection}
                          name={"name"}
                          value={
                            tableItem.fk_items === "" || tableItem.fk_items
                              ? tableItem.fk_items
                              : ""
                          }
                          options={itemNameList}
                        />
                      </td>
                    ) : item.state === "fk_unit" ? (
                      <td colSpan={1}>
                        <select
                          // onFocus={(e)=>{e.target.classList.add("show","display");console.log(e.target.querySelector("ul.dropdown-menu"))}}
                          onKeyDown={handleKeyDown}
                          name={"fk_unit"}
                          onChange={(e) =>
                            handleChangeTableItem(e, null, tableItem, true)
                          }
                          value={
                            tableItem.fk_unit === "" || tableItem.fk_unit
                              ? tableItem.fk_unit
                              : ""
                          }
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "none",
                            fontSize: "10px",
                            padding: "3.5px 1px",
                          }}
                          className="purchase_input border-0 w-100 text-center"
                        >
                          {unitList &&
                            unitList.map((x, i) => (
                              <option key={i} value={x.value}>
                                {x.text}
                              </option>
                            ))}
                        </select>
                      </td>
                    ) : item.state === "expiry_date" ? (
                      <td colSpan={1}>
                        <input
                          // onFocus={(e)=>console.log(e.target)}
                          onKeyDown={handleKeyDown}
                          name={item.state}
                          onChange={(e) =>
                            handleChangeTableItem(e, null, tableItem, true)
                          }
                          disabled={item.readOnly ||checkBatchActive===false}
                          value={
                            (tableItem[item.state] &&
                              dayjs(tableItem[item.state]).format(
                                "YYYY-MM-DD"
                              )) ||
                            ""
                          }
                          type="date"
                          className="purchase_input border-0 w-100 text-center"
                        />
                      </td>
                    ) : item.state === "batch_no" ?(
                      <td colSpan={1}>
                        <input
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          name={item.state}
                          onChange={(e) =>
                            handleChangeTableItem(e, null, tableItem, true)
                          }
                          disabled={item.readOnly || checkBatchActive===false}
                          value={
                            tableItem[item.state] === "" ||
                            tableItem[item.state] ||
                            tableItem[item.state] === 0
                              ? tableItem[item.state]
                              : "0"
                          }
                          type={item.state == "batch_no" ? "text" : "number"}
                          className="purchase_input border-0 w-100 text-center"
                        />
                      </td>
                    ):(
                      <td colSpan={1}>
                        <input
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          name={item.state == "vat" ? "tax_gst" : item.state}
                          onChange={(e) =>
                            handleChangeTableItem(e, null, tableItem, true)
                          }
                          disabled={item.readOnly}
                          value={
                            tableItem[
                              item.state === "vat" ? "tax_gst" : item.state
                            ] === "" ||
                            tableItem[
                              item.state === "vat" ? "tax_gst" : item.state
                            ] ||
                            tableItem[
                              item.state === "vat" ? "tax_gst" : item.state
                            ] === 0
                              ? tableItem[
                                  item.state === "vat" ? "tax_gst" : item.state
                                ]
                              : "0"
                          }
                          type={item.state == "batch_no" ? "text" : "number"}
                          className="purchase_input border-0 w-100 text-center"
                        />
                      </td>
                    );
                  else return null;
                })}
              <td className="align-top">
                {!permissions.includes(1169) && (
                  <input
                    onKeyDown={handleAddOpenBatch}
                    onClick={handleAddOpenBatch}
                    type="button"
                    className="table-item-add-btn rounded-1 btn-sm align-middle"
                    value={"+"}
                  />
                )}
              </td>
            </tr>

            {<AdjustHeightOfTable />}
          </tbody>
          <tfoot>
            <tr className="purchase-table-green">
              <td colSpan={3} className="col-2 text-start">
                <div className="d-flex justify-items-start">
                  <div
                    style={{ background: "#4A00A8" }}
                    className="btn bg-none outline-none text-light border-none"
                    onClick={handlePrev}
                  >
                    {"<"} Previous
                  </div>
                  <div
                    style={{ background: "#707070" }}
                    className="btn bg-none outline-none text-light border-none"
                    onClick={handleNext}
                  >
                    Next {">"}
                  </div>
                </div>
              </td>
              {tableHeadList?.length > 0 &&
                tableHeadList?.map((item, i) => {
                  if (i > 1 && item.purchaseShow)
                    return item.state === "discount_1_amount" &&
                      item.visible ? (
                      <td className="item">
                        <div className="btn bg-success outline-none text-light border-none px-2 mb-2">
                          {purchaseAdd.total_disc?.toFixed(2) || '0.00'}
                        </div>
                      </td>
                    ) : item.state === "value" && item.visible ? (
                      <td className="item">
                        <div className="btn bg-success outline-none text-light border-none px-2 mb-2">
                          {purchaseAdd.total_value || '0.00'}
                        </div>
                      </td>
                    ) : item.state === "cgst_or_igst" ||
                      item.state === "sgst" ||
                      (item.state === "vat_perc" && item.visible) ? (
                      <td className="item">
                        <div className="btn bg-success outline-none text-light border-none px-2 mb-2">
                          {purchaseAdd.total_scGst || '0.00'}
                        </div>
                      </td>
                    ) : item.state === "total" && item.visible ? (
                      <td className="item">
                        <div className="btn bg-success outline-none text-light border-none px-2 mb-2">
                          {purchaseAdd.total_total || '0.00'}
                        </div>
                      </td>
                    ) : (
                      item.visible && <td></td>
                    );
                  else return null;
                })}
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="purchase-detail-container  px-3 py-0 mx-2 mt-1">
        <div className="col-3 col-4 row mx-0">
          <div className="col-5 text-end">Total Item :</div>
          <div className="col-7">{purchaseAdd.total_items || 0}</div>
        </div>
        <div className="col-3 col-4 row mx-0">
          <div className="col-5 text-end">Item :</div>
          <div className="col-7">{purchaseAdd.total_qty || 0}</div>
          <div className="col-5 text-end">HSN :</div>
          <div className="col-7">323</div>
        </div>
        <div className="col-2 row mx-0">
          <div className="col-5 text-end">CTC :</div>
          <div className="col-7">{purchaseAdd.total_CTC || 0}</div>
        </div>
        <div className="col-2 row mx-0">
          <div className="col-5 text-end">Godown :</div>
          <div className="col-7"></div>
        </div>
        <div className="col-1">M : {purchaseAdd.total_margin || 0}%</div>
      </div>
      <Modal
        show={showStock}
        size="lg"
        centered
        onHide={() => setShowStock(false)}
      >
        <StockPop
          handleChange={handlebatchChange}
          {...{
            itemSelected,
            setShowStock,
          }}
        />
      </Modal>
    </>
  );
};

export default PurchaseTable;
