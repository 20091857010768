import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import renewImg from '../../../assets/images/renew.svg'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { companyModules } from "../data/initialData";
import { useCompanyServices } from '../../../services/controller/companyServices';
import Swal from 'sweetalert2';

const RenewPlan = (props) => {
    const { companyId } = props
    const [show, setShow] = useState(false);
    const { postPlanRenew } = useCompanyServices()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModules, setShowModules] = useState(false);
    const [moduleCodeList, setModuleCodeList] = useState([]);
    const [renewal_date, setRenewalDate] = useState('')
    const [renewal_time, setRenewalTime] = useState('')

    const initCompanyPlan = {
        renewal_date: null,
        renewal_time: null,
        extended_date: null,
        staff_limit: null,
        device_limit: null,
        modules: [],
    }
    const [companyPlan, setCompanyPlan] = useState(initCompanyPlan);


    console.log(moduleCodeList, "++++++++++++++++++++++++++++++++++")
    const handleModuleSelection = (data) => {
        let tempList = [...moduleCodeList];
        let filteredList = [];
        companyModules.forEach((item) => {
            if (
                item.code === data.code ||
                item.code === data.parent ||
                data.code === item.parent
            ) {
                filteredList.push({ code: item.code, parent: item.parent });
            }
        });
        // console.log(filteredList.every(i=>tempList.findIndex(x=>x.code==i.code)>-1))
        if (
            filteredList.some(
                (i) => tempList.findIndex((x) => x.code == i.code) > -1
            ) &&
            !data?.parent
        ) {
            tempList = tempList.filter(
                (x) => filteredList.findIndex((i) => i.code === x.code) == -1
            );
        } else if (tempList.some((i) => i.code == data.code) && data?.parent) {
            tempList = tempList.filter((x) => x.code !== data.code);
        } else {
            tempList = [...tempList, ...filteredList];
        }
        setModuleCodeList([...tempList]);
    };

    const handleModuleSelectAll = () => {
        if (companyModules?.length == moduleCodeList?.length) {
            setModuleCodeList([]);
        } else {
            let tempList = companyModules?.map((data) => ({
                code: data.code,
                parent: data.parent,
            }));
            setModuleCodeList([...tempList]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        let response;
        if (moduleCodeList?.length < 0) {
            Swal.fire("warning", 'please select at least 1 module.', "warning");
            return 0;
        }
        // console.log(data)
        try {
            let tempCompanyPlan = {
                ...companyPlan,
                modules: moduleCodeList.map((data)=>(
                    {
                        code: data.code,
                        is_active: true
                    }
                )),
            }
            let data = {
                modules: tempCompanyPlan,
                renewal_date: renewal_date,
                renewal_time: renewal_time
            }
            response = await postPlanRenew(companyId, data)
            if (response?.success) {
                Swal.fire(response?.message, '', 'success')
            }
            else {
                Swal.fire(response.message, '', 'error')
            }
        }
        catch (err) {
            console.log('error')
        }
    }
    return (
        <div>
            <>
                <Button className='mx-3' variant="primary" onClick={handleShow}>
                    <span className='me-2 '><img src={renewImg} alt="" /></span>
                    Renew Plan
                </Button>

                <Modal show={show} onHide={handleClose}>
                    <form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Renew Plan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Renewal Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder=""
                                        onChange={(e) => setRenewalDate(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Renewal Date</Form.Label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                            <TimePicker
                                                onChange={(e) => setRenewalTime(e)}
                                                label="" />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Form.Group>
                                <div>
                                    <div className="comp-details-cont-1 col-5 col-12 border rounded-2">
                                        <div className="d-flex align-items-center gap-3 fs-5">
                                            Modules{" "}
                                            <div
                                                onClick={() => setShowModules(true)}
                                                className="company-plan-btn btn module"
                                            >
                                                Choose Modules
                                            </div>{" "}
                                        </div>
                                        <div className="module-cont w-100">
                                            {companyModules?.map((data) => {
                                                return (
                                                    moduleCodeList?.findIndex((item) => item.code === data.code) >
                                                    -1 && (
                                                        <div
                                                            onClick={() => handleModuleSelection(data)}
                                                            className={`comp-module-item active`}
                                                        >
                                                            <img src={data.icon} width={"25rem"} alt="" />
                                                            {data.name}
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <Modal
                                        show={showModules}
                                        centered
                                        size="lg"
                                        onHide={() => setShowModules(false)}
                                    >
                                        <div className="comp-plan-module-modal pt-4 px-5">
                                            <div
                                                onClick={handleModuleSelectAll}
                                                className={`module-select-all btn ${companyModules?.length == moduleCodeList?.length && "clear"
                                                    }`}
                                            >
                                                {companyModules?.length == moduleCodeList?.length
                                                    ? "Clear All"
                                                    : "Select All"}
                                            </div>
                                            <div className="module-items">
                                                {companyModules?.map((data) => (
                                                    <div
                                                        onClick={() => handleModuleSelection(data)}
                                                        className={`comp-module-item ${moduleCodeList?.findIndex((item) => item.code === data.code) >
                                                            -1 && "active"
                                                            }`}
                                                    >
                                                        <img src={data.icon} width={"25rem"} alt="" />
                                                        <div className="text-center">{data.name}</div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="text-end">
                                                <div
                                                    onClick={() => setShowModules(false)}
                                                    className="btn comp-module-btn px-5 fs-5"
                                                >
                                                    Done
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>

                                </div>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button type='submit' variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        </div>
    )
}

export default RenewPlan