import { useSelector } from "react-redux";
import "./DotMatrix1.css";
import { SheetFormat } from "./components/SheetFormat";

export const DotMatrix1 = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeight,
    tableTrBody,
    setTableTrBody,
    tableHead,
    hsnCalc,
    tableRowContent,
    GetContent,
    salesAdd,
  } = props;

  const auth = useSelector((state) => state.auth.userDetails);
  const itemList = [];
  const minNoOfItems = 8;
  const maxNoOfItems = 15;
  let sheetList = [];
  let lastRow = [];
  let tableRowContentNew = tableTrBody;
  tableRowContentNew.map((tdData) =>
    console.log(tdData)
  );
  const configureSheets = () => {
    if (tableTrBody.length > 0) {
      lastRow = tableTrBody[tableTrBody.length - 1];
    }
    let count = 1;
    while (tableRowContentNew.length > 0) {
      if (tableRowContentNew.length > maxNoOfItems) {
        itemList.push({
          content: tableRowContentNew.splice(0, maxNoOfItems),
          isLastRow: false,
        });
      } else if (tableRowContentNew.length < minNoOfItems) {
        itemList.push({
          content: tableRowContentNew.splice(0, maxNoOfItems),
          isLastRow: true,
        });
      } else {
        itemList.push({
          content: tableRowContentNew.splice(0, minNoOfItems),
          isLastRow: false,
        });
      }
      count++;
    }
    sheetList = itemList.map((item, index) => (
      <SheetFormat
        key={index}
        showSheet={true}
        showTableBody={true}
        tableBodyContent={item.content}
        auth={auth}
        isLastRow={item.isLastRow}
        tableHead={tableHead}
        itemNumber={index * maxNoOfItems}
        maxNoOfItems={maxNoOfItems}
        minNoOfItems={minNoOfItems}
        {...{
          c_address,
          c_name,
          c_number,
          delivery_add,
          c_gstin,
          vehicle_no,
          driver,
          total_qty,
          total_disc,
          total_value,
          total_sgst,
          total,
          roundOff,
          TableHeight,
          tableTrBody,
          setTableTrBody,
          tableHead,
          hsnCalc,
          tableRowContent,
          GetContent,
          salesAdd,
        }}
      />
    ));
  };
  configureSheets();
  return <div className="d-flex flex-column">{sheetList}</div>;
};
