import React, { useState } from 'react'
import userProfileIcon from '../../../assets/images/iconamoon_questionmark-circle-fill.png'
import { TextField } from "@mui/material";
import vitezLogo from '../../../assets/images/VITEZ LOGO-01 1.svg'
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { useUserServices } from '../../../services/controller/userServices';

const AddRecovery = (props) => {
    const {setFirstLogin}=props
    const [recQuest, setRecQuest] =useState({question:null,answer:null})
    const [recQuestList, setRecQuestList] = useState([])
    const [questId, setQuestId] = useState(0)

    const {postSecurityQuestions} = useUserServices()
 
    const navigate = useNavigate()

    const handleChange = (e) =>{
      let name = e.target.name
      let value = e.target.value || null
      setRecQuest(data=>({...data,[name]:value}))
    }

    const handleAddQuest =async (e,addOnly) =>{
      e.preventDefault()
      console.log("id",questId)
      if(questId>1 && !addOnly){
        try{
          let resp = await postSecurityQuestions({questions:[...recQuestList,{...recQuest,id:questId}]})
          if(resp.success){
            setFirstLogin(false)
            navigate('/')
          }
        }catch(err){
          Swal.fire('Error',err?.response?.message,'error')
          setRecQuestList([])
          setRecQuest({question:null,answer:null})
          setQuestId(0)
        }
          // return 0
      }else{
        setRecQuestList(data=>[...data,{...recQuest,id:questId}])
        setQuestId((prev)=>prev+1)
        setRecQuest({question:null,answer:null})
      }
    }

    

  return (
    <form
    // onSubmit={handleSubmit}
    className="d-flex flex-column align-items-center railway-font"
    style={{ width: "70%", height: "fit-content" }}
  >
    <div><img className='' src={vitezLogo} alt="" /></div>
    <div className="d-flex mt-3">
       
      <div className="d-flex flex-column justify-content-center mt-2">
        <img src={userProfileIcon} alt="" />
      </div>
      <div className="p-2">
        <h1 className="p-0 m-0" style={{ font: "27px" }}>
        Add Recovery Question 
        </h1>
        <p>
        Check Your mesaage . We've Sent You the PIN at 9087657389</p>
      </div>
    </div>

    <div className="w-100">
      <TextField
        onChange={handleChange}
        value={recQuest.question||""}
        name="question"
        className="auth-input-field my-3 "
        id="outlined-basic"
        label="Enter Question"
        variant="outlined"
      />
      <TextField
        onChange={handleChange}
        value={recQuest.answer||""}
        name="answer"
        className="auth-input-field my-3 "
        id="outlined-basic"
        label="Enter Answer"
        variant="outlined"
      />
    </div>
    <div className="w-100 d-flex justify-content-between align-items-center py-3">
        <hr className='count-hr'/>
        <div style={{fontFamily:'sans-serif',font:'15px'}}><b> {'('+(questId+1)+'/'+ (questId<=2? 3:(questId+1))+')'} </b></div>
        <hr className='count-hr'/>  
    </div>

    <div style={{ width: "100%" }}>
      <button
        onClick={handleAddQuest}
        className="btn-login rounded py-3 fs-5 d-flex px-0 align-items-center justify-content-center" > {questId<2 ? 'Next' : 'Login'}
      </button>
      
      {questId>1&&<button
        type="submit"
        onClick={(e)=>handleAddQuest(e,true)}
        className="btn-login mt-3 questions-add rounded py-3 fs-5 d-flex px-0 align-items-center justify-content-center"
      > Add More Question
      </button>}
    </div>

  </form>
  )
}

export default AddRecovery