import "./sidebar.css";
import Transaction from "../../assets/sidebar_icons/trans.png";
import Reports from "../../assets/sidebar_icons/reports.png";
import Dashboard from "../../assets/sidebar_icons/menu.png";
import User from "../../assets/sidebar_icons/person.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import userProf from "../../assets/icons/prof.jpeg";
import companyList from "../../assets/icons/company-list.png";
import accimg from "../../assets/sidebar_icons/Accounts.png";
import stockimg from "../../assets/sidebar_icons/stock 1.png";
import cusimg from "../../assets/sidebar_icons/customer 1.png";
import salimg from "../../assets/sidebar_icons/sales-report.png";
import staffimg from "../../assets/sidebar_icons/staff 1.png";
import otherimg from "../../assets/sidebar_icons/others 1.png";
import proimg from "../../assets/sidebar_icons/profit 1.png";
import purimg from "../../assets/sidebar_icons/purchase.png";
import fin from "../../assets/sidebar_icons/finance.png";
import sal from "../../assets/sidebar_icons/sales 2.png";
import ind from "../../assets/sidebar_icons/industry.png";
import hr from "../../assets/sidebar_icons/hr.png";
import inv from "../../assets/sidebar_icons/inve.png";
import sup from "../../assets/sidebar_icons/supplier 2.png";


export const navigationList = [
  {
    text: "Accounts",
    code: 112,
    navigate: "/account-master",
    main: "master",
    sub: "Account",
  },
  {
    text: "Customer",
    code: 114,
    navigate: "/customer-master",
    main: "master",
    sub: "Customer",
  },
  {
    text: "Supplier",
    code: 115,
    navigate: "/supplier-master",
    main: "master",
    sub: "Supplier",
  },
  {
    text: "Staff",
    code: 123,
    navigate: "/staff-list",
    main: "master",
    sub: "Staff",
  },
  { text: "Items",
    code: 134,
    navigate: "/list",
    main: "master",
    sub: "Item" },
  {
    text: "Opening Stock",
    code: 135,
    navigate: "/opening-stock",
    main: "master",
    sub: "Opening Stock",
  },
  {
    text: "Material Composition",
    code: 149,
    navigate: "/material-composition-product",
    main: "master",
    sub: "Material Composition",
  },
  {
    text: "Purchase",
    code: 100,
    navigate: "/purchase-transaction",
    main: "transaction",
    main_sub: "Purchase",
    sub: "Purchase",
  },
  {
    text: "Purchase Return",
    code: 101,
    navigate: "/purchase-return",
    main: "transaction",
    main_sub: "Purchase",
    sub: "Purchase Return",
  },
  {
    text: "Purchase Order",
    code: 103,
    navigate: "/purchase-order",
    main: "transaction",
    main_sub: "Purchase",
    sub: "Purchase Order",
  },
  {
    text: "Sales",
    code: 104,
    navigate: "/sales-transaction",
    main: "transaction",
    main_sub: "Sales",
    sub: "Sales",
  },
  {
    text: "Sales Return",
    code: 105,
    navigate: "/sales-return",
    main: "transaction",
    main_sub: "Sales",
    sub: "Sales Return",
  },
  {
    text: "Sales order",
    code: 107,
    navigate: "/sales-order",
    main: "transaction",
    main_sub: "Sales",
    sub: "Sales Order",
  },
  {
    text: "Payment",
    code: 108,
    navigate: "/payment-transaction",
    main: "transaction",
    main_sub: "Finance",
    sub: "Payment",
  },
  {
    text: "Receipt",
    code: 109,
    navigate: "/receipt-transaction",
    main: "transaction",
    main_sub: "Finance",
    sub: "Receipt",
  },
  {
    text: "Stock Journal",
    code: 110,
    navigate: "/stock-journal",
    main: "transaction",
    main_sub: "Inventory",
    sub: "Stock Journal",
  },
  {
    text: "Account Journal",
    code: 116,
    navigate: "/account-journal",
    main: "transaction",
    main_sub: "Finance",
    sub: "Account Journal",
  },
  {
    text: "Staff Attendance",
    code: 125,
    navigate: "/staff-attendance",
    main: "transaction",
    main_sub: "Human Resources",
    sub: "Staff Attendance",
  },
  {
    text: "Pay Roll",
    code: 126,
    navigate: "/pay-roll",
    main: "transaction",
    main_sub: "Human Resources",
    sub: "Pay Roll",
  },
  {
    text: "Cheque Register",
    code: 130,
    navigate: "/cheque-register",
    main: "transaction",
    main_sub: "Finance",
    sub: "Cheque Register",
  },
  {
    text: "Production",
    code: 148,
    navigate: "/production-transaction",
    main: "transaction",
    main_sub: "Manufacturing",
    sub: "Production",
  },
  {
    text: "Account Ledger",
    code: 122,
    navigate: "/account-reports",
    main: "report",
    main_sub: "Accounts",
    sub: "Account Ledger",
  },
  {
    text: "Stock Ledger",
    code: 139,
    navigate: "/stock-reports",
    main: "report",
    main_sub: "Stock Summary",
    sub: "Stock Ledger",
  },
  {
    text: "Day Book",
    code: 120,
    navigate: "/day-book",
    main: "report",
    main_sub: "Accounts",
    sub: "Day Book",
  },
  {
    text: "Cust Outstanding",
    code: 118,
    navigate: "/customer-outstandings",
    main: "report",
    main_sub: "Customer Report",
    sub: "Cust Outstanding",
  },
  {
    text: "Sup Outstanding",
    code: 119,
    navigate: "/supplier-outstandings",
    main: "report",
    main_sub: "Supplier Report",
    sub: "Sup Outstanding",
  },
  {
    text: "Sales Report",
    code: 106,
    navigate: "/sales-book",
    main: "report",
    main_sub: "Sales Report",
    sub: "Sales Report",
  },
  {
    text: "Sales Order Report",
    code: 106,
    navigate: "/sale-book-order",
    main: "report",
    main_sub: "Order Reports",
    sub: "Sales Order Report",
  },
  {
    text: "Staff Outstanding",
    code: 129,
    navigate: "/staff-outstandings",
    main: "report",
    main_sub: "Staff Report",
    sub: "Staff Outstanding",
  },
  {
    text: "Tax Reports",
    code: 132,
    navigate: "/tax-report",
    main: "report",
    main_sub: "Other Reports",
    sub: "Tax Reports",
  },
  {
    text: "Cash Book",
    code: 121,
    navigate: "/cashbook-report",
    main: "report",
    main_sub: "Accounts",
    sub: "Cash Book",
  },
  {
    text: "Stock Journal",
    code: 111,
    navigate: "/Stock-journal-report",
    main: "report",
    main_sub: "Stock Summary",
    sub: "Stock Journal Report",
  },
  {
    text: "Item Wise Profit",
    code: 136,
    navigate: "/profit-report",
    main: "report",
    main_sub: "Profit Report",
    sub: "Item Wise Profit",
  },
  {
    text: "Purchase Report",
    code: 102,
    navigate: "/purchase-book",
    main: "report",
    main_sub: "Purchase Report",
    sub: "Purchase Report",
  },
  {
    text: "Purchase Order Report",
    code: 102,
    navigate: "/purchase-order-book",
    main: "report",
    main_sub: "Order Reports",
    sub: "Purchase Order Report",
  },
  {
    text: "Barcode Register",
    code: 140,
    navigate: "/barcode-register",
    main: "report",
    main_sub: "Other Reports",
    sub: "Barcode Register",
  },
  {
    text: "Item History",
    code: 137,
    navigate: "/item-history",
    main: "report",
    main_sub: "Stock Summary",
    sub: "Item History",
  },
  {
    text: "Bill Wise Ledger",
    code: 146,
    navigate: "/bill-wise-ledger",
    main: "report",
    main_sub: "Other Reports",
    sub: "Bill Wise Ledger",
  },
  {
    text: "Bill Wise Profit",
    code: 147,
    navigate: "/bill-wise-profit",
    main: "report",
    main_sub: "Profit Report",
    sub: "Bill Wise Profit",
  },
  {
    text: "Cheque Register",
    code: 131,
    navigate: "/cheque-register-report",
    main: "report",
    main_sub: "Other Reports",
    sub: "Cheque Register Report",
  },
  {
    text: "Stock Value Report",
    code: 138,
    navigate: "/stock-value-report",
    main: "report",
    main_sub: "Stock Summary",
    sub: "Stock Value Report",
  },
  {
    text: "Staff Salary & Attendance",
    code: [127, 128],
    navigate: "/StaffAttendance",
    main: "report",
    main_sub: "Staff Report",
    sub: "Staff Salary & Attendance",
  },
  {
    text: "Production report",
    code: 150,
    navigate: "/production-report",
    main: "report",
    main_sub: "Other Reports",
    sub: "Production Report",
  },
  {
    text: "Trial balance",
    code: 141,
    navigate: "/trial-balance",
    main: "report",
    main_sub: "Accounts",
    sub: "Trial Balance",
  },
  {
    text: "Group Wise Trial balance",
    code: 142,
    navigate: "/group-trial-balance",
    main: "report",
    main_sub: "Accounts",
    sub: "Group Wise Trial Balance",
  },
  {
    text: "Balance Sheet",
    code: 143,
    navigate: "/balance-sheet",
    main: "report",
    main_sub: "Accounts",
    sub: "Balance Sheet",
  },
  {
    text: "Trade Profit And Loss",
    code: 151,
    navigate: "/traid-profit-loss",
    main: "report",
    main_sub: "Accounts",
    sub: "Trade Profit And Loss",
  },
  {
    text: "Chart Of Account",
    code: 117,
    navigate: "/chart-of/account",
    main: "report",
    main_sub: "Other Reports",
    sub: "Chart Of Account",
  },
];

const Sidebar = ({ perm, setPage, moduleCodeList, setModuleCodeList }) => {
  const [masterActive, setMasterActive] = useState(false);
  const [ArrowActive, setArrowActive] = useState(false);
  const [ReportsActive, setReportsActive] = useState(false);
  const [DashActive,setDashActive] = useState(false);
  const [isActive, setIsActive] = useState("");
  const auth = useSelector((state) => state.auth);
  const [filteredReportObject, setFilteredReportObject] = useState({});
  const [expandedReportKeys, setExpandedReportKeys] = useState({});
  const [filteredTransObject, setFilteredTransObject] = useState({});
  const [expandedTransKeys, setExpandedTransKeys] = useState({});

  const ImageOptions={
    'Accounts':accimg,
    'Stock Summary':stockimg,
    'Customer Report':cusimg,
    "Supplier Report":sup,
    "Sales Report":salimg,
    "Staff Report":staffimg,
    "Other Reports":otherimg,
    "Profit Report":proimg,
    "Purchase Report":purimg,
    'Purchase':purimg,
    'Sales':sal,
    'Finance':fin,
    'Manufacturing':ind,
    'Inventory':inv,
    'Human Resources':hr,

  }

  const location = useLocation();
  const navigate = useNavigate();

  const handleCheck = (data) => {
    // console.log(data);
    let tempModule = [...moduleCodeList];
    if (typeof data.code === "number") {
      let ind = tempModule.findIndex((x) => x.code === data.code);
      if (ind > -1) {
        tempModule.splice(ind, 1);
      } else {
        tempModule.push({ code: data.code, parent: null });
      }
    } else if (Array.isArray(data.code)) {
      data.code.forEach((code) => {
        let ind = tempModule.findIndex((x) => x.code === code);
        if (ind > -1) {
          tempModule.splice(ind, 1);
        } else {
          tempModule.push({ code: code, parent: null });
        }
      });
    }
    setModuleCodeList([...tempModule]);
  };
  const handleMasterActive = () => {
    if (masterActive === false) {
      setMasterActive(!masterActive);
      setArrowActive(false);
      setReportsActive(false);
      setDashActive(false);
    } else setMasterActive(!masterActive);
  };

  const handleArrowActive = () => {
    if (ArrowActive === false) {
      setArrowActive(!ArrowActive);
      setMasterActive(false);
      setReportsActive(false);
      setDashActive(false);
    } else setArrowActive(!ArrowActive);
  };

  const handleReportActive = () => {
    if (ReportsActive === false) {
      setReportsActive(!ReportsActive);
      setArrowActive(false);
      setMasterActive(false);
      setDashActive(false);
    } else setReportsActive(!ReportsActive);
  };
  const handleDashActive = ()=>{
    if (DashActive === false){
      setArrowActive(false);
      setMasterActive(false);
      setReportsActive(false);
      setDashActive(!DashActive)
      navigate("/")
    } else setDashActive(!DashActive);
  }

  const convertArrayToFilteredObject = (items,type) =>
    items
      .filter((item) => item.main === type)
      .reduce((acc, item) => {
        acc[item.main_sub] = acc[item.main_sub] || [];
        acc[item.main_sub].push(item);
        return acc;
      }, {});

  // useEffect(() => {
  //   const filteredNavigationList = navigationList.filter(
  //     (data) =>{
  //       if (Array.isArray(data.code)){
  //         data.code.forEach((code)=>{
  //           auth?.modulePermissions?.some((x) => x === code || perm)
  //         })
  //       }else{
  //         auth?.modulePermissions?.some((x) => x === data.code || perm)
  //       }
  //     } 
  //   );
  //   const result1 = convertArrayToFilteredObject(filteredNavigationList,'report');
  //   setFilteredReportObject(result1);
  //   const result2 = convertArrayToFilteredObject(filteredNavigationList,'transaction');
  //   setFilteredTransObject(result2)
  // }, [filteredReportObject,filteredTransObject]);
  useEffect(() => {
    const filteredNavigationList = navigationList.filter((data) => {
        if (Array.isArray(data.code)) {
            return data.code.some((code) =>
                auth?.modulePermissions?.some((x) => x === code || perm)
            );
        } else if (typeof data.code === "number"){
            return auth?.modulePermissions?.some((x) => x === data.code || perm);
        }
    });

    const result1 = convertArrayToFilteredObject(filteredNavigationList,'report');
    setFilteredReportObject(result1);
    const result2 = convertArrayToFilteredObject(filteredNavigationList,'transaction');
    setFilteredTransObject(result2)
}, [navigationList, auth, perm]);

  
 const handleKeyToggle = (key) => {
    setExpandedReportKeys((prevKeys) => {
      const newExpandedKeys = { ...prevKeys };
      Object.keys(newExpandedKeys).forEach((k) => {
        newExpandedKeys[k] = false;
      });
      newExpandedKeys[key] = !prevKeys[key];
      return newExpandedKeys;
    });
  };

  const handleKeyToggle1 = (key) => {
    setExpandedTransKeys((prevKeys) => {
      const newExpandedKeys = { ...prevKeys };
      Object.keys(newExpandedKeys).forEach((k) => {
        newExpandedKeys[k] = false;
      });
      newExpandedKeys[key] = !prevKeys[key];
      return newExpandedKeys;
    });
  };
  return (
    <div
      className={`sidebar  pb-5 ${
        auth?.userDetails?.fk_group === "Controller" && "company"
      } ${perm && "h-100 pt-2 permission"}`}
    >
      {!perm && (
        <div className="company-logo-cont mb-3">
          {auth?.userDetails?.fk_group === "Controller" && (
            <div className="company-logo pb-5 h-100 pt-4">
              <div className="d-flex text-light gap-3">
                <img
                  className="header-user-prof-img company"
                  src={userProf}
                  alt="user"
                />
                <span>
                  <h3>{auth?.userDetails?.username}</h3>
                  {auth?.userDetails?.fk_role}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{ userSelect: "none" }}
        className={`SidebarItems mt-0 mt-5 mx-0 px-0`}
      >
        {auth?.userDetails?.fk_group === "Controller" && !perm && (
          <>
            <div
              onClick={() => navigate("/")}
              className={`SidebarItem mb-1 admin ${masterActive && "active"}`}
            >
              <img src={companyList} className="sidebar_icon" width={"25px"} />
              Company
            </div>
          </>
        )}

        {("Company Agency".includes(auth?.userDetails?.fk_group) || perm) && (
          <>
            {!perm && (
              <div
                onClick={() => {
                  if (!perm) handleDashActive();
                }}
                className={`SidebarItem mb-1 ${
                  location.pathname === "/" && "active"
                }`}
              >
                <img src={Dashboard} className="sidebar_icon" width={"25px"} />
                Dashboard
              </div>
            )}
            {navigationList.filter(
              (data) =>
                data.main === "master" &&
                auth?.modulePermissions?.findIndex((x) => x === data.code) > -1
            )?.length >
              0 /* || auth?.userDetails?.fk_group==="Controller"*/ && (
              <>
                <div
                  onClick={() => handleMasterActive()}
                  className={`SidebarItem mt-3 mb-1 ${
                    masterActive && "active"
                  }`}
                >
                  <img src={User} className="sidebar_icon" width={"25px"} />
                  Master
                </div>
                <div
                  className={`sidebar_span_cont ${!masterActive && "d-none"}`}
                >
                  {navigationList.map(
                    (data) =>
                      auth?.modulePermissions?.findIndex(
                        (x) => x === data.code || perm
                      ) > -1 &&
                      data.main === "master" && (
                        <span className="SidebarSpan d-flex ms-5 ps-3 pb-1">
                          <div className="SidebarItemText">
                            {perm && location.pathname !== "/company-add" && (
                              <input
                                type="checkbox"
                                onChange={() => handleCheck(data)}
                                checked={
                                  moduleCodeList?.findIndex(
                                    (x) => x.code === data.code
                                  ) > -1
                                    ? true
                                    : false
                                }
                                className="sidebar-checkbox"
                              />
                            )}
                            <button
                              disabled={
                                location.pathname !== "/company-add" &&
                                moduleCodeList?.findIndex(
                                  (x) => x.code === data.code
                                ) == -1
                              }
                              onClick={(e) => {
                                if (!perm) navigate(data.navigate);
                                else {
                                  e.preventDefault();
                                  setPage({ main: data.main, sub: data.sub });
                                  setIsActive(data.text);
                                }
                              }}
                              className={`sidebar-items-button handle-key ${
                                isActive == data.text && "text-warning fw-bold"
                              }`}
                            >
                              {" "}
                              {data.text}
                            </button>
                          </div>
                        </span>
                      )
                  )}
                </div>
              </>
            )}

            {/* {(navigationList.filter(
              (data) =>
                data.main === "transaction" &&
                auth?.modulePermissions?.findIndex((x) => x === data.code) > -1
            )?.length > 0 ||
              auth?.userDetails?.fk_group === "Controller") && (
              <>
                <div
                  onClick={() => handleReportActive()}
                  className={`SidebarItem mt-3 mb-1 ${
                    ReportsActive && "active"
                  }`}
                >
                  <img
                    className="sidebar_icon"
                    src={Transaction}
                    width={"25px"}
                  />
                  Transactions
                </div>
                <div
                  className={`sidebar_span_cont ${!ReportsActive && "d-none"}`}
                >
                  {navigationList.map(
                    (data) =>
                      auth?.modulePermissions?.findIndex(
                        (x) => x === data.code || perm
                      ) > -1 &&
                      data.main === "transaction" && (
                        <span className="SidebarSpan d-flex ms-5 ps-3">
                          <div className="SidebarItemText">
                            {perm && (
                              <input
                                onChange={() => handleCheck(data)}
                                checked={
                                  moduleCodeList?.findIndex(
                                    (x) => x.code === data.code
                                  ) > -1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                                className="sidebar-checkbox"
                              />
                            )}
                            <button
                              disabled={
                                moduleCodeList?.findIndex(
                                  (x) => x.code === data.code
                                ) == -1
                              }
                              onClick={(e) => {
                                if (!perm) navigate(data.navigate);
                                else {
                                  e.preventDefault();
                                  setPage({ main: data.main, sub: data.sub });
                                  setIsActive(data.text);
                                }
                              }}
                              className={`sidebar-items-button ${
                                isActive == data.text && "text-warning fw-bold"
                              }`}
                            >
                              {" "}
                              {data.text}
                            </button>
                          </div>
                        </span>
                      )
                  )}
                </div>
              </>
            )} */}

             {(navigationList.filter(
              (data) =>
                data.main === "transaction" &&
                auth?.modulePermissions?.findIndex((x) => x === data.code) > -1
            )?.length >0 || auth?.userDetails?.fk_group==="Controller" )
            && (
            <>
            <div
              onClick={() => handleReportActive()}
              className={`SidebarItem mt-3 mb-1 ${ReportsActive && "active"}`}
            >
              <img className="sidebar_icon" src={Transaction} width={"25px"} />
              Transactions
            </div>
            <div
              className={`sidebar_span_cont ${!ReportsActive ? "d-none" : ""}`}
            >
              {Object.keys(filteredTransObject).map((key) => (
                <div key={key}>
                  <div
                    className="my-2 py-2 d-flex ms-4 ps-2"
                    onClick={() => handleKeyToggle1(key)}
                    // style={{backgroundColor:'#111'}}
                  >
                    <img
                      className="sidebar_icon ms-1"
                      src={ImageOptions[key]||Reports}
                      width={"20px"}
                    />
                   
                    <div  className="text-light trans-font-side-bar fw-bold ps-3">{key}</div>
                  </div>

                  {expandedTransKeys[key] && (
                    <div className="ms-3 ps-3">
                      {filteredTransObject[key].map((item) => (
                        <span key={item.code} className="SidebarSpan d-flex ms-3 ps-2 pb-2">
                          <div className="SidebarItemText">
                            {perm && (
                              <input
                                onChange={() => handleCheck(item)}
                                checked={
                                  (typeof item.code === "number" &&
                                    moduleCodeList?.some(
                                      (x) => x.code === item.code
                                    )) ||
                                  (Array.isArray(item.code) &&
                                    item.code.length > 0 &&
                                    moduleCodeList?.some((x) =>
                                      item.code.includes(x.code)
                                    ))
                                }
                                type="checkbox"
                                className="sidebar-checkbox"
                              />
                            )}
                            <button
                              disabled={
                                moduleCodeList?.findIndex((x) =>
                                  Array.isArray(item.code)
                                    ? item.code.includes(x.code)
                                    : x.code === item.code
                                ) === -1
                              }
                              onClick={(e) => {
                                if (!perm) {
                                  navigate(item.navigate);
                                } else {
                                  e.preventDefault();
                                  setPage({ main: item.main, sub: item.sub });
                                  setIsActive(item.text);
                                }
                              }}
                              className={`sidebar-items-button handle-key ${
                                isActive === item.text
                                  ? "text-warning fw-bold"
                                  : ""
                              }`}
                            >
                              {item.sub}
                            </button>
                          </div>
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            </>)}

            {navigationList.filter(
              (data) =>
                data.main === "report" &&
                auth?.modulePermissions?.findIndex((x) => x === data.code) > -1
            )?.length >
              0 /* || auth?.userDetails?.fk_group==="Controller"*/ && (
            <>
            <div
              onClick={() => handleArrowActive()}
              className={`SidebarItem mt-3 mb-1 ${ArrowActive && "active"}`}
            >
              <img className="sidebar_icon" src={Reports} width={"25px"} />
              Reports
            </div>
            <div
              className={`sidebar_span_cont ${!ArrowActive ? "d-none" : ""}`}
            >
              {Object.keys(filteredReportObject).map((key) => (
                <div key={key}>
                  <div
                    className="my-2 py-2 d-flex ms-4 ps-2"
                    onClick={() => handleKeyToggle(key)}
                    // style={{backgroundColor:'#111'}}
                  >
                    <img
                      className="sidebar_icon ms-1"
                      src={ImageOptions[key]||Reports}
                      width={"20px"}
                    />
                   
                    <div className="text-light fw-bold ps-3 trans-font-side-bar">{key}</div>
                  </div>

                  {expandedReportKeys[key] && (
                    <div className="ms-3 ps-3">
                      {filteredReportObject[key].map((item) => (
                        <span key={item.code} className="SidebarSpan d-flex ms-3 ps-2 pb-2">
                          <div className="SidebarItemText">
                            {perm && (
                              <input
                                onChange={() => handleCheck(item)}
                                checked={
                                  (typeof item.code === "number" &&
                                    moduleCodeList?.some(
                                      (x) => x.code === item.code
                                    )) ||
                                  (Array.isArray(item.code) &&
                                    item.code.length > 0 &&
                                    moduleCodeList?.some((x) =>
                                      item.code.includes(x.code)
                                    ))
                                }
                                type="checkbox"
                                className="sidebar-checkbox"
                              />
                            )}
                            <button
                              disabled={
                                moduleCodeList?.findIndex((x) =>
                                  Array.isArray(item.code)
                                    ? item.code.includes(x.code)
                                    : x.code === item.code
                                ) === -1
                              }
                              onClick={(e) => {
                                if (!perm) {
                                  navigate(item.navigate);
                                } else {
                                  e.preventDefault();
                                  setPage({ main: item.main, sub: item.sub });
                                  setIsActive(item.text);
                                }
                              }}
                              className={`sidebar-items-button handle-key ${
                                isActive === item.text
                                  ? "text-warning fw-bold"
                                  : ""
                              }`}
                            >
                              {item.sub}
                            </button>
                          </div>
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            </>)}
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
