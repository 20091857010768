import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import dayjs from "dayjs";

export const SheetHeader = (props) => {
  const { 
    auth,
    count,
    c_address,
    delivery_add,
    salesAdd,
    vehicle_no
   } = props;
   let character="="
  return (
    <>
        <h3 class="text-center-1">Tax Invoice</h3>
        <header>
          <div class="company-details-1">
            <div>Invoice Number: <strong>{salesAdd.documents_no}</strong></div>
            <div>Invoice Date:<strong>{dayjs(salesAdd.date).format('DD/MM/YYYY')}</strong></div>
          </div>
        </header>
        <div class="all-details-1 row">
          <section class="col-6 billing-details-1">
            <h5>{auth?.others?.group_name}</h5>
            <div>{auth?.others?.address_line_2 || "Address1"}</div>
            <div>{auth?.others?.address_line_1 || "Address2"}</div>
            <div>{auth?.others?.mobile || "Mobile"}</div>
          </section>
          <section class="col-6 invoice-details-1">
            <h5>Bill To</h5>
            <div>Client Name</div>
            <div>{c_address|| "Address"}</div>
          </section>
        </div>
        <p style={{'margin':0,'padding':0}}>{character.repeat(132)}</p>
    </>
  );
};

