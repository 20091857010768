import { useEffect, useState } from "react";
import searchIcon from "../../../../assets/icons/search.png";
import { GrRefresh } from "react-icons/gr";
import { useNavigate } from "react-router";
import dayjs from "dayjs";

export const StockTable = (props) => {
  const { stockList, batchType } = props;

  // console.log(stockList)

  const [searchedList, setSearchedList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setSearchedList(stockList);
  }, [stockList]);

  const AdjustTableHeight = () => {
    let a = [];
    for (let i = 0; i < 7 - stockList?.length; i++) {
      a.push(
        <tr>
           {batchType !== "batchless" && (
          <td className="border-bottom-0">
            <div className="border-0 text-start"></div>
          </td> )}
          {batchType !== "batchless" && (
          <td className="border-bottom-0">
            <div width="110" className="border-0 text-start"></div>
          </td> )}
          <td className="border-bottom-0">
            <div className="border-0 prple_td margin-r"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 grn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 grn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 grn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 grn_td margin-r"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 brn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 brn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 brn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 brn_td"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 brn_td margin-r"></div>
          </td>
          {/* <td className="border-bottom-0">
            <div className="border-0 grey_td margin-r"></div>
          </td>
          <td className="border-bottom-0">
            <div className="border-0 grey_td margin-r"></div>
          </td> */}
          <td className="border-bottom-0">
            <div className="border-0 prple_td" width="70"></div>
          </td>
        </tr>
      );
    }
    return a;
  };

  const handleSearch = async (e) => {
    try {
      let tempData,
        tempList = stockList;
      if (stockList) {
        let value = e.target.value.toLocaleLowerCase();
        if (value != "") {
          if (stockList.length > 0) {
            tempData = tempList?.filter((x) => {
              console.log(x);
              let searchInString = `${
                x.item_code?.toLocaleLowerCase() +
                " " +
                x.item_name?.toLocaleLowerCase()
              }`;
              let search = searchInString?.includes(value);
              if (search) {
                return true;
              }
            });
            setSearchedList(tempData);
          }
        } else {
          setSearchedList(stockList);
        }
      }
    } catch {}
  };

  return (
    <div className="mt-3">
      <div
        style={{ background: "rgba(77, 48, 114, 1)" }}
        className="w-100 d-flex justify-content-end"
      >
        <div className="col-3 p-2 stock-ledger-search d-flex align-items-center">
          <div className="col-2 me-3  ">
            <GrRefresh
              onClick={() => setSearchedList(stockList)}
              className="bg-light text-dark m-1 rounded-1 p-1 "
              size={28}
            />
          </div>
          <div className="item_seach_bar_cont rounded-2  col-9 py-3">
            <img src={searchIcon} className="search_img me-3 ms-2 py-2" />
            <input
              // value={search}
              onChange={handleSearch}
              className="item_search_bar rounded-2 border-0 py-1"
              placeholder="Search"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="stock-ledger-cont">
        <table className="stock-ledger w-100 me-0 pe-0">
          <thead>
            <tr>
              { batchType !== "batchless" && (
                <th className={batchType === "batchless" ? "hidden-content" : ""}>
                  <div className="text-start">Batch</div>
                </th>
              )} 
              { batchType !== "batchless" && (
                <th width="140" className={batchType === "batchless" ? "hidden-content" : ""}>
                  <div className="text-start">Expiry Date</div>
                </th>
              )}
              <th>
                <div className="prple_th rounded-top-2 margin-r">Op.stock</div>
              </th>
              <th>
                <div className="grn_th rounded-top-2 rounded-end-0">
                  Purchase
                </div>
              </th>
              <th>
                <div className="grn_th">S.return</div>
              </th>
              <th>
                <div className="grn_th">S.Journal Add</div>
              </th>
              <th>
                <div className="grn_th rounded-top-2 rounded-start-0 margin-r">
                  Production
                </div>
              </th>
              <th>
                <div className="brn_th rounded-top-2 rounded-end-0">
                  P.return
                </div>
              </th>
              <th>
                <div className="brn_th">R.return</div>
              </th>
              <th>
                <div className="brn_th">Sales</div>
              </th>
              <th>
                <div className="brn_th">S.Journal Less</div>
              </th>
              <th>
                <div className="brn_th rounded-top-2 rounded-start-0 margin-r">
                  Service
                </div>
              </th>
              {/* <th>
              <div className="grey_th rounded-top-2 margin-r">S.journal Add</div>
            </th>
            <th>
              <div className="grey_th rounded-top-2 margin-r">S.journal Less</div>
            </th> */}
              <th>
                <div className="prple_th rounded-top-2">Cl.stock</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {searchedList?.length > 0 &&
              searchedList.map((data, i) => {
                return (
                  <>
                    <tr className="stock-ledger-item-tr">
                      <td className="" colspan={13}>
                        {data.item_name}
                      </td>
                    </tr>

                    {data.batch.map((item, key) => {
                      return (
                        <tr key={i}>
                          {batchType !== "batchless" && (
                            <td>
                              <div
                                style={{ height: "2.9rem" }}
                                className={batchType === "batchless" ? "hidden-content text-start d-flex align-items-center" : "text-start d-flex align-items-center"}
                              >
                                {item?.batch_no || "------"}
                              </div>
                            </td>
                          )} 
                          {batchType !== "batchless" && (                             <td>
                              <div
                                style={{ height: "2.9rem" }}
                                width="110"
                                className={batchType === "batchless" ? "hidden-content text-start d-flex align-items-center" : "text-start d-flex align-items-center"}
                              >
                                {(item?.exp &&
                                  dayjs(item?.exp).format("DD/MM/YYYY")) ||
                                  "----------"}
                              </div>
                            </td>
                          )}
                          <td>
                            <div className="prple_td margin-r">
                              {item?.opening_stock || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="grn_td">
                              {item?.purchase_batch_qty || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="grn_td">
                              {item?.sales_return_batch_qty || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="grn_td">
                              {item?.stock_journal_batch_qty_add || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="grn_td margin-r">
                              {item?.total_purchase_quantity || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="brn_td">
                              {item?.purchase_return_batch_qty || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="brn_td">
                              {item?.total_purchase_quantity || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="brn_td">
                              {item?.sales_batch_qty || "0"}
                            </div>
                          </td>
                          <td>
                            <div className="brn_td">
                              {Math.abs(item?.stock_journal_batch_qty_less) ||
                                "0"}
                            </div>
                          </td>
                          <td>
                            <div className="brn_td margin-r">
                              {item?.total_purchase_quantity || "0"}
                            </div>
                          </td>
                          {/* <td>
                        <div className="grey_td margin-r">
                          {item?.stock_journal_batch_qty_add || "0"}
                        </div>
                      </td>
                      <td>
                        <div className="grey_td margin-r">
                          {item?.stock_journal_batch_qty_less || "0"}
                        </div>
                      </td> */}
                          <td>
                            <div className="prple_td">
                              {item?.closing_stock || "0"}
                            </div>
                          </td>
                          {/* <td colSpan={2}>
                        hello
                      </td>  */}
                        </tr>
                      );
                    })}
                  </>
                );
              })}
            <AdjustTableHeight />
          </tbody>
        </table>
      </div>
      <div className="row">
        {/* <div className="w-100 d-flex justify-content-end mb-1">
          <div
            onClick={() => navigate(-1)}
            className="btn btn-dark col-1 col-2 py-0"
          >
            Exit
          </div>
        </div> */}
      </div>
    </div>
  );
};
