import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import "../A5Format1.css";
import { toWords } from "number-to-words";


export const SheetFooter = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeigth,
    tableHead,
    // tableTrBody,
    tableBodyContent,
    hsnCalc,
    setTableTrBody,
    count,
    auth
  } = props;

  return (
    <>
        <section class="totals">
          <div>
            <div>Subtotal: {total_value}</div>
            <div>CGST : {total_sgst}</div>
            <div>SGST : {total_sgst}</div>
            <div>
              <strong >Total: {total}</strong>
            </div>
          </div>
        </section>
        <footer>
          <p>Thank you for your business!</p>
        </footer>
    </>
  );
};
