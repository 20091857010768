import React, { useEffect, useState } from "react";
import { TfiEmail, TfiPrinter } from "react-icons/tfi";
import { BsWhatsapp, BsFiletypePdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import { Form } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import XLSK from 'sheetjs-style';
import XLSX from 'sheetjs-style';
import { Tooltip } from '@mui/material';

export const TaxDetails = (props) => {
  const { params, setParams, excelData } = props;
  const [type, setType] = useState('');
  const [list, setList] = useState();
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    // setList()
  }, [excelData, params]);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async () => {
    let tempData = null;
    let flattenedData = [];
    let reportType = '';
    let reportFileName = '';

    if (excelData) {
      if (params.report_type === 'item_wise') {
        reportType = 'ITEM WISE REPORT';
        reportFileName = 'item wise report';

        flattenedData = excelData?.item_wise_report?.flatMap(report =>
          report?.item_wise_common?.map(item => ({
            document_number: report.document_number,
            date: report.date?.slice(0, 10).split("-").reverse().join("-"),
            supp_invoice_no: report.supp_invoice_no,
            supp_invoice_date: report.supp_invoice_date,
            gst_number: report.gst_number,
            party: report.party,
            item_name: item.item_name,
            tax_gst: item.tax_gst,
            hsn: item.hsn,
            fk_unit: item.fk_unit,
            value: item.value,
            quantity: item.quantity,
            cgst: item.cgst,
            sgst: item.sgst,
            total: item.total
          }))
        );
      } 
      else if (params.report_type === 'hsn_wise') {
        reportType = 'HSN WISE REPORT';
        reportFileName = 'hsn wise report';
        flattenedData = excelData?.hsn_wise_report;
      }
      else if (params.report_type === 'percentage_wise'){
        reportType = 'PERCENTAGE WISE REPORT';
        reportFileName = 'percentage wise report';

        flattenedData = excelData?.percentage_wise?.map(item => ({
            "Tax": item.tax,
            "Documents no": item.documents,
            "Date": item.date?.slice(0, 10).split("-").reverse().join("-"),
            "Quantity": item.quantity,
            "Value": item.value,
            "CGST": item.cgst,
            "SGST": item.sgst,
            "VAT": item.tax,
            "CESS 1": item.cess1,
            "CESS 2": item.cess2,
            "Total": item.total
          }))
        
      }

      else if (params.report_type === 'tax_summary'){
        reportType = 'TAX SUMMARY REPORT';
        reportFileName = 'tax summary report';

        flattenedData = excelData?.summery?.map(item => ({
          "S/N": item.s_no,
          "Tax": item.tax,
          "Value": item.value,
          "CGST": item.cgst,
          "SGST": item.sgst,
          "Total": item.total
        }))
      }

      else if (params.report_type === 'date_summary'){
        reportType = 'DATE SUMMARY REPORT';
        reportFileName = 'date summary report';

        flattenedData = excelData?.summery?.map(item => ({
          "Date": item.date.slice(0, 10).split("-").reverse().join("-"),
          "Doc No": item.documents,
          "Tax": item.tax,
          "Value": item.value,
          "CGST": item.cgst,
          "SGST": item.sgst,
          "Total": item.total
        }))
      }


      tempData = flattenedData;

      if (tempData && tempData.length > 0) {
        const headerRow = Object.keys(tempData[0]);
        const reportNameRow = [`${params?.category}  ${reportType}      from: ${params?.from_date}     date: ${params?.to_date}`];
        const dataWithHeaders = [headerRow, ...tempData.map(item => Object.values(item))];

        const ws = XLSX.utils.aoa_to_sheet([reportNameRow]);

        const headerRange = { s: { r: 0, c: 0 }, e: { r: 0, c: headerRow.length - 1 } };
        if (!ws['!merges']) ws['!merges'] = [];
        ws['!merges'].push(headerRange);

        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A2' });
        XLSX.utils.sheet_add_aoa(ws, dataWithHeaders.slice(1), { origin: 'A3' });

        ws['A1'].s = {
          font: {
            bold: true,
            sz: 14
          },
          alignment: {
            horizontal: "center"
          },
          fill: {
            fgColor: { rgb: "d2c5e3" }
          },
        };

        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + "2";
          if (!ws[address]) continue;
          ws[address].s = {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center"
            }
          };
        }

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, reportFileName + fileExtension);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      setParams({ ...params, [e.target.name]: null });
    } else setParams({ ...params, [e.target.name]: e.target.value });
  };

  return (
    <div className="row mt-2 mx-0 d-flex">
      <div className="col-12 px-0 ">
        <div className="col-12 px-0 mt-1 d-flex gap-3 justify-content-start">
          <div
            style={{ background: "#b30b00", border: "#b30b00 2px", }}
            className="reports-btn btn rounded-1 col-1 col-2 py-1"
          >
            <BsFiletypePdf className="me-2 text-" size={18} />
            PDF
          </div>
          <div
            onClick={exportToExcel}
            style={{ background: "#02723b", border: "solid #02723b 2px" }}
            className="reports-btn btn rounded-1 col-1 col-2 py-1"
          >
            <RiFileExcel2Line className="me-2" size={18} />
            Excel
          </div>
          <div
            style={{ background: "rgba(75, 75, 75, 1)", border: "rgba(75, 75, 75, 1) 2px" }}
            className="reports-btn btn rounded-1 col-1 col-2 py-1"
          >
            <TfiPrinter size={18} className="me-2 h-100" />
            Print
          </div>
          <div
            style={{ background: "#ea4335", border: "solid #ea4335 2px" }}
            className="reports-btn btn rounded-1 col-1 col-2 py-1"
          >
            <TfiEmail size={18} className="me-2 h-100" />
            Email
          </div>
          <div
            style={{ background: "rgba(77, 135, 42, 1)", border: "solid rgba(77, 135, 42, 1) 2px" }}
            className="reports-btn btn rounded-1 col-1 col-2 py-1"
          >
            <BsWhatsapp size={18} className="me-2 h-100" />
            Whatsapp
          </div>
        </div>
        <div className="row mx-0 mt-2">
          <div className="d-flex col-7 px-0">
            <Form.Group className="col-5 pe-4 ps-0 mx-0 d-flex align-items-center mt-1">
              <Form.Label className="col-2 purchase-input-label pb-1">
                From
              </Form.Label>
              <Form.Control
                name="from_date"
                onChange={handleChange}
                value={params?.from_date || new Date().toISOString(0, 10)}
                className="purchase-input-text me-2 text-start"
                type="date"
              />
            </Form.Group>
            <Form.Group className="col-5 pe-4 ps-0 mx-0 d-flex align-items-center mt-1">
              <Form.Label className="col-2 purchase-input-label pb-1">
                Upto
              </Form.Label>
              <Form.Control
                name="to_date"
                value={params?.to_date || new Date().toISOString(0, 10)}
                onChange={handleChange}
                className="purchase-input-text me-2 text-start"
                type="date"
              />
            </Form.Group>
          </div>
          <div className="col-5 mt-1">
            <div className="row d-flex align-item-center px-0  mx-0">
              <div className="col">
                <Form.Group className="w-100  px-0 ps-1">
                  <Form.Select
                    name="category"
                    value={params.category}
                    onChange={handleChange}
                    className="purchase-input-text text-start"
                  >
                    <option value="sales">Sales</option>
                    <option value="purchase">Purchase</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col  align-item-center">
                <Form.Group className="  px-0 ps-1">
                  <Form.Select
                    name="report_type"
                    value={params?.report_type}
                    onChange={handleChange}
                    className="purchase-input-text align-middle text-start"
                  >
                    <option value="item_wise">Item Wise</option>
                    <option value="hsn_wise">HSN Wise</option>
                    <option value="percentage_wise">Percentage Wise</option>
                    <option value="tax_summary">Tax Summary</option>
                    <option value="date_summary">Date Summary</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
