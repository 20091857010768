import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useReportsServices } from "../../../services/reports/reports";
import SalesOrderEntery from "./components/SalesOrderEntery";
import SalesOrderRegisterTable from "./components/SalesOrderRegisterTable";
import SalesBookTable from "../salesBook/components/SalesBookTable";
import SalesOrderTable from "./components/SalesOrderTable";


const SalesOrderBook = () => {
    const [salesBookList, setSalesBookList] = useState([]);
    const [saleRegisterList, setSaleRegisterList] = useState([]);
    const [show, setShow] = useState(false);
    const [colshow, setColShow] = useState(false);
    const [params, setParams] = useState({
      from_date: new Date().toISOString().slice(0, 10),
      to_date: new Date().toISOString().slice(0, 10),
      salesman: null,
      billtype: null,
      customer: null,
      care_off: null,
      report_type: 'sales_order',
    });
  
    const navigate = useNavigate();
    const location = useLocation();
  
    const { getSalesBook, getSaleRegister } = useReportsServices();
  
    useEffect(() => {
      getData();
    }, [params]);
  
    const getData = async () => {
      try {
        const response = await getSalesBook(params);
        if (response.success) {
          setSalesBookList(response.data);
        }
        const response1 = await getSaleRegister(params);
        if (response1.success) {
          setSaleRegisterList(response1.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    return (
        <div className="item_add zoom-responsive-view">
          <div className="itemList_header row mx-0">
            <div className="page_head ps-4 d-flex justify-content-between">
              <div>
                <div className="fs-5 fw-600 mt-3">
                  {location.pathname === "/sale-book-register" && "active"
                    ? "Sale Order Register"
                    : "Sales Order Book"}
                </div>
                <div className="page_head_items my-2">
                  <div
                    onClick={() => {
                      navigate("/sale-book-order");
                    }}
                    className={`page_head_item ${
                      location.pathname === "/sale-book-order" && "active"
                    }`}
                  >
                    Sales Order Book
                  </div>
                  <div
                    onClick={() => {
                      navigate("/sale-book-register");
                    }}
                    className={`page_head_item ${
                      location.pathname === "/sale-book-register" && "active"
                    }`}
                  >
                    Sales Order Register
                  </div>
                </div>
              </div>
    
              <div className="d-flex align-items-center h-100 me-2">
                <div
                  className="p-2 me-2 choose-acc-btn rounded-2 text-light cursor"
                  onClick={() => setShow(true)}
                >
                  Filter Account
                </div>
                <div
                  className="p-2 choose-acc-btn rounded-2 text-light cursor "
                  onClick={() => setColShow(true)}
                >
                  Column Settings
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div style={{boxShadow:"0px 0px 5px 1px #c1aeda"}} className="p-1 pt-2 bg-white rounded-1">
              {location.pathname === "/sale-book-register" && "active" ? (
                <>
                  <SalesOrderEntery
                    {...{
                      params,
                      setParams,
                    }}
                  />
                  <SalesOrderRegisterTable
                    {...{
                      saleRegisterList,
                      setSaleRegisterList,
                    }}
                  />
                </>
              ) : (
                <>
                  <SalesOrderEntery
                    {...{
                      params,
                      setParams,
                    }}
                  />
                  <SalesOrderTable
                    {...{
                      salesBookList,
                    }}
                  />
                </>
              )}
    
              <div className="row mt-3">
                <div className="w-100 d-flex justify-content-end mb-3">
                  <div onClick={()=>navigate(-1)} className="btn btn-dark col-1 col-2 py-0 me-2">Exit</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default SalesOrderBook