import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import pawReset from '../../../assets/icons/paw_reset.svg'
import { useCompanyServices } from '../../../services/controller/companyServices';
import Swal from 'sweetalert2';

const ChangePassword = (props) => {
    const { companyId } = props
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { postCompanyPswReset } = useCompanyServices()

    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const handleReset = () => {
        setPassword1("")
        setPassword2("")
    }
    useEffect(() => {
        
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log('9999999999999999999999999999999999')
        try {
            if (password1 == password2) {
                let response
                let password = {password:password1}
                
                response = await postCompanyPswReset(companyId, password)
                if (response?.success) {
                    Swal.fire(response.message, '', 'success',)
                }
                else {
                    Swal.fire(response.message, '', 'error',)
                }
            }

        }
        catch (err) {
            console.log('error')
        }

        handleReset()
        handleClose()
    }



    console.log(companyId)
    return (
        <>
            <Button variant="primary " onClick={handleShow}>
                <span className='pe-3'><img src={pawReset} alt="" /></span>
                Change Password
            </Button>
            <form >

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={(e) => setPassword1(e.target.value)}
                                    name='password1'
                                    value={password1 || ""}
                                    // autoFocus
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={(e) => setPassword2(e.target.value)}
                                    value={password2 || ""}
                                    // autoFocus
                                    required
                                />
                            </Form.Group>



                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button  type='submit' style={{ backgroundColor: "rgba(90, 19, 182, 1)" }} onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </form>
        </>
    )
}

export default ChangePassword