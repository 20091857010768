import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import extendPlan from '../../../assets/images/extended.svg'
import { useCompanyServices } from '../../../services/controller/companyServices';
import Swal from 'sweetalert2';
const ExtendedPlan = (props) => {
    const {companyId} = props
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [date, setDate] = useState("")

    const { postPlanExtend } = useCompanyServices()
    console.log(date)
    const handleSubmit = async (e) => {
        e.preventDefault()
       
        const dates = { extended_date: date }
        try {
            let response
            response = await postPlanExtend(companyId, dates)
            if (response?.success) {
                Swal.fire(response.message, '', 'success',)
            }
            else {
                Swal.fire(response.message, '', 'error',)
            }
        }
        catch (err) {
            console.log('error')
        }
        handleClose()
    }



    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <span className='me-2'><img src={extendPlan} alt="" /></span>
                Extend Plan
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Extend Plan</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Extended To</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder=""
                                autoFocus
                                name="extended_date"
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type='submit' variant="primary">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default ExtendedPlan