import React, { useEffect, useState } from "react";
import "../A5Format1.css";
import { TableContainer } from "./TableContainer";
import { SheetHeader } from "./SheetHeader";
import { SheetFooter } from "./SheetFooter";

export const SheetFormat = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeigth,
    tableHead,
    // tableTrBody,
    tableBodyContent,
    hsnCalc,
    setTableTrBody,

    showTableBody,
    showSheet,
    auth,
    count,
    isLastRow,
    salesAdd,
    itemNumber,
    noOfItems,
  } = props;
  return (
    <div
      style={showSheet ? {} : { display: "none" }}
      className="m-2 main-sheet print-page"
    >
      <div className="invoice">
        <div className="s-head">
          <SheetHeader  auth={auth} count={count} {...{c_address, delivery_add, salesAdd, vehicle_no}} />
        </div>
        <div className="s-body pt-2">
          <TableContainer {...props}/>
        </div>
        <div className="s-foot"> 
          {isLastRow && <SheetFooter auth={auth} count={count} {...props} />}
        </div>
      </div>
    </div>
  );
};
