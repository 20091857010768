import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Dropdown, Icon } from 'semantic-ui-react'
import Swal from 'sweetalert2';
import useAccountServices from '../../../../services/master/accountServices';

const AccountCodeChange = (props) => {
    const {loadAccountList} = props
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);

    const { getAccountList, postAccountCode } = useAccountServices();
    const [accountList, setAccountList] = useState([]);

    const [codeAdd, setCodeAdd] = useState({
        old_code: null,
        fk_account: null,
        new_code: null,
    })


    const getAccountData = async () => {
        const response = await getAccountList()
        let tempList = []
        if (response?.success) {
            response.data.map(acc => {

                let a
                if (acc.name && acc.code) {
                    a = { key: acc.code, value: acc.id, text: acc.name?.toUpperCase(), description: acc.code }
                }
                if (acc.reserved == false) {

                    tempList.push(a)
                }

            })
            setAccountList(tempList)
        }
    }

    useEffect(() => {
        getAccountData()
    }, [])

    useEffect(() => {
        
    }, [codeAdd])

    const search = (options, searchValue) => {
        searchValue = searchValue.toUpperCase();
        return options?.filter((option) => {
            return (
                option?.value?.toString()?.includes(searchValue) ||
                option?.text?.includes(searchValue) ||
                option?.description?.includes(searchValue)
            );
        });
    };

    const handleChange = (e, data) => {
        if (data) {
            console.log(data.options)
            let acc_data = data.options.filter((x) => x.value === data.value)[0];
            if (data.name.includes('fk_account')) {
                console.log('hello')
                setCodeAdd(
                    { ...accountList, [data.name]: acc_data.value, old_code: acc_data.key }
                )
            }
        }
        if (e.target.name == 'new_code') {
            console.log(e.target.value)
            setCodeAdd({
                ...codeAdd, [e.target.name]: e.target.value
            })
        }

        
    }
    const handleReset = () => {
        setCodeAdd({
            old_code: null,
            fk_account: null,
            new_code: null,
        })
    }
    const handleTOUpperCase = (data) => {
        let keysOfData, tempData = { ...data }
        if (typeof data == 'object')
            keysOfData = Object.keys(data)
        if (!keysOfData?.length > 0) return 0
        keysOfData.map(item => {
            if (typeof data[item] == 'string' && item != 'method') {
                let itemTemp = data[item]?.toUpperCase()
                tempData = { ...tempData, [item]: itemTemp }
            }
        })
        return tempData
    }

    const handleSubmit = async (data) => {
        data.preventDefault()
        try {
            const submitData = handleTOUpperCase(codeAdd)
            let response
            response = await postAccountCode(submitData)
            if (response?.success) {
                Swal.fire(response.message, '', 'success')
            }
            else {
                Swal.fire(response.message, '', 'error')
            }

        }
        catch (err) {
            Swal.fire(err?.response?.data?.message, '', 'error')
        }
        getAccountData()
        handleReset()
        handleClose()
        loadAccountList()
    }


    return (
        <div >
            <Button variant="primary" onClick={handleShow}>
                Account Code Change
            </Button>
            <form   >


                <Modal className='ac-code-chg-model'  show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Account Code Change</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Form  >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Account</Form.Label>
                                <Dropdown
                                    //   disabled={edit}
                                    // clearable
                                    selection
                                    search={search}
                                    onChange={handleChange}
                                    className="cheque-reg-select text-dark item d-flex align-items-center py-0 form-control"
                                    name="fk_account"
                                    placeholder="Select Account Details"
                                    value={codeAdd?.fk_account || ""}
                                    options={accountList}

                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <Form.Label>Old Account Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            autoFocus
                                            className='border border-3 border-dark'
                                            value={codeAdd?.old_code || ''}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <Form.Label>Enter New Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            autoFocus
                                            name='new_code'
                                            className='border border-3 border-dark'
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button onClick={handleSubmit} type='submit' variant="primary" >
                            Save Changes
                        </Button>

                    </Modal.Footer>
                </Modal>
            </form>
        </div>
    )
}

export default AccountCodeChange