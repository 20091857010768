import './stockLedger.css'
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { ReportDetails } from './components/ReportDetails';
import { useReportsServices } from "../../../services/reports/reports";
import { StockTable } from "./components/StockTable";
import { useUserServices } from '../../../services/controller/userServices';

export const StockLedger = () => {
  const [stockList, setStockList] = useState([])
  const [batchType,setBatchType] = useState('')
  const [paramsToReport, setParamsToReport] = useState({
    from_date: (new Date().toISOString().slice(0, 10)),
    to_date: (new Date().toISOString().slice(0, 10)),
    item_code: null,
  })

  const { getStockLedger, batchWiseStockReport } = useReportsServices()
  const { getUserProfile }= useUserServices();
  useEffect(() => {
    getData()
  }, [paramsToReport,])

  const getData = async () => {
    try {
      // const response = await getStockLedger(paramsToReport)
      const response2 = await batchWiseStockReport(paramsToReport)
      const response3 = await await getUserProfile();
      if (response3.success) {
        setBatchType(response3?.data?.user_data?.group_profile_details?.is_batch)
        setStockList(response2?.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const navigate = useNavigate()

  return (
    <div className='item_add zoom-responsive-view'>
      <div className="itemList_header row mx-0">
        <div className="page_head ps-4 d-flex justify-content-between">
          <div>
            <div className='fw-600 fs-5 mt-3'>Stock Ledger</div>
            <div className='page_head_items mb-2 mt-1'>
              <div onClick={() => navigate("/stock-reports")} className={`page_head_customer active`}>Details</div>

            </div>
          </div>
          <div className="d-flex px-0 align-items-center customer-add-btn">
            {/* <div onClick={()=>{navigate("/customer-add");setToEdit(false)}} className="btn btn-primary add-btn px-0">+ &nbsp; Add Customer</div> */}
          </div>
        </div>
      </div>
      <div className='p-3'>
        <div className="stock-jdetails-cont col-12 p-1 ps-3 rounded-1 w-100 bg-white  h-100 pe-4">
          <ReportDetails from={"stock"} {...{
            stockList, setStockList,
            paramsToReport, setParamsToReport,
          }} />
          <StockTable {...{
            stockList, setStockList,
            paramsToReport, setParamsToReport,batchType
          }} />
        </div>
      </div>
    </div>
  )
}
