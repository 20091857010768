import { useEffect, useState } from "react";
import { StockJournalDetails } from "./components/StockJournalDetails";
import "./StockJournal.css";
import { Dropdown, Modal } from "react-bootstrap";
import { StockJournalEdit } from "./components/StockJournalEdit";
import useItemServices from "../../../services/master/itemServices";
import useStockJournalServices from "../../../services/transactions/stockJournal";
import Swal from "sweetalert2";
import useSalesServices from "../../../services/transactions/salesServices";
import { useSelector } from "react-redux";
import { useUserServices } from "../../../services/controller/userServices";

export const StockJournal = () => {
  const [showJournalFilter, setShowJournalFilter] = useState(false);
  const [itemNameList, setItemNameList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [stockTableItemList, setStockTableItemList] = useState([]);
  const [stockJList, setstockJList] = useState([]);
  const [edit, setEdit] = useState();
  const [tableEdit, setTableEdit] = useState();
  const [showStock, setShowStock] = useState(false);
  const permissions = useSelector((state) => state.auth.activityPermissions);
  const [stockJAdd, setStockJAdd] = useState({
    id: null,
    code: null,
    narration: null,
    salesman: null,
    date: new Date().toISOString(),
    total_items: null,
    total_value: null,
    total_qty: null,
  });
  const [stockTableItem, setStockTableItem] = useState({
    name: null,
    code: null,
    qty: null,
    qty_type: "add",
    cost: null,
    unit: null,
    value: null,
    godown: null,
  });

  const { postStockJ, getStockJ, putStockJ, deleteStockJ } =
    useStockJournalServices();
  const { getCode, getItemNameList,getProperty } = useItemServices();
  const { getSalesItem } = useSalesServices();
   const {getUserProfile}= useUserServices();
  useEffect(() => {
    getData();
    handleGetCode();
  }, []);

  useEffect(() => {
    if (edit) {
      const { items, ...others } = edit;
      setStockJAdd(others);
      setStockTableItemList(items);
    }
    handleGetCode();
  }, [edit]);

  const handleClearAll = () => {
    setStockJAdd({
      code: null,
      narration: null,
      salesman: null,
      date: new Date().toISOString(),
      total_items: null,
      total_value: null,
      total_qty: null,
    });
    handleResetStockTabe();
    setStockTableItemList([]);
    setEdit(false);
    handleGetCode();
  };

  const handleResetStockTabe = () => {
    setStockTableItem({
      name: null,
      code: null,
      unit: null,
      qty: null,
      qty_type: "add",
      cost: null,
      value: null,
      godown: null,
    });
  };

  useEffect(() => {
    if (stockTableItemList?.length > 0) {
      let totalValue = stockTableItemList?.reduce((a, b) => {
        return b.value ? parseFloat(a) + parseFloat(b.value) : 0;
      }, 0);
      let totalqty = stockTableItemList?.reduce((a, b) => {
        return b.qty ? parseFloat(a) + parseFloat(b.qty) : 0;
      }, 0);
      let totalItems = stockTableItemList?.reduce((a, b) => {
        return a + 1;
      }, 0);

      let tempStockJAdd = {
        ...stockJAdd,
        total_items: totalItems,
        total_value: totalValue,
        total_qty: totalqty,
      };
      setStockJAdd(tempStockJAdd);
    }
  }, [stockTableItemList, stockTableItem]);

  const handleAddToTableList = async () => {
    if (!stockTableItem.qty || !stockTableItem.code) {
      Swal.fire({
        title: "Warning !",
        text: "Please select an Item and enter quantity",
        showConfirmButton: false,
        timer: 1700,
      });
      return 0;
    }
    if (tableEdit && edit) {
      let submitData = { ...stockJAdd, item: [stockTableItem] };
      const response = await putStockJ(submitData, edit.id);
      if (!response.success) return 0;
    }
    // const values = Object.values(stockTableItem).filter((x) => x !== null);
    // if (values?.length > 1) {
      const tempList = stockTableItemList;
      tempList.push(stockTableItem);
      setStockTableItemList(tempList);
      handleResetStockTabe();
    // }
  };

  const calcTotalVal = (data) => {
    if (edit) return data;
    data.map((item) => {
      let totalValue = 0;
      if (item.items?.length > 0) {
        item.items.map((x) => {
          totalValue += x?.value || 0;
        });
      }
      item.totalValue = totalValue;
    });
    return data;
  };

  const minFunct = (data) => {
    let list = [];
    data.map((x) => {
      if (x.property_type === "unit") {
        list.push({ value: x["id"], text: x["property_value"] });
      }
    });
    setUnitList(list);
  };

  const handleGetCode = async () => {
    try {
      let response = await getCode();
      if (response.success && !edit) {
        let code = response.data.filter(
          (x) => x.types === "STOCK_JOURNAL_CODE"
        )[0];
        setStockJAdd((data) => ({ ...data, code: code?.next_code }));
      }
    } catch (err) {}
  };

  const handleDataNameListMixed = (data,iData) => {
    let tempList = [];
    data?.map((x) => {
      const { id, code, name, ...others } = x;
    if (x.batch_active){
      tempList.push({
        ...others,
        text: x.item_name,
        description: x.item_code,
        value: x.item_code,
        barcode:x.batch_list[0]?.barcode,
      });
      }else if(iData.length>0){
        const matchitem =  iData.find((item)=>item.code === x.item_code)
        console.log("MAtch Item",matchitem)
        if (matchitem){
          tempList.push({
            ...{
            ...matchitem
            },
            text: matchitem.name,
            description: matchitem.code,
            value: matchitem.code,
            unit: matchitem.fk_unit,
            item_name: matchitem.name,
            item_code: matchitem.code,
            barcode: matchitem.barcode,
            org_value: matchitem.value
          });
        }
      }
    });
    setItemNameList([...tempList]);
  };
 
  const handleDataNameListBatch = (data) => {
    let tempList = [];
    data?.map((x) => {
      const { id, code, name, ...others } = x;
      tempList.push({
        ...others,
        text: x.item_name,
        description: x.item_code,
        value: x.item_code,
        barcode:x.batch_list[0]?.barcode,
      });
    });
    setItemNameList([...tempList]);
  };
 
  const handleDataNameListBatchless = (data) => {
    let tempList = [];
    data?.map((x) => {
      const {
        discount_1_percentage,
        tax_gst,
        purchase_rate,
        retail_rate,
        margin,
        batch_active,
      } = x;
      tempList.push({
        ...{
          batch_active,
          discount_1_percentage,
          tax_gst,
          purchase_rate,
          retail_rate,
          margin,
        },
        text: x.name,
        description: x.code,
        value: x.code,
        unit: x.fk_unit,
        org_value:x.value
        
      });
    });
    setItemNameList([...tempList]);
  };
  console.log("Itemlist",itemNameList)
  const getData = async () => {
    try {
      let response1 = await getUserProfile();
      let response2 = await getSalesItem();
      let response5 = await getItemNameList();
      let response3 = await getProperty();
      let response4 = await getStockJ();
      let batch_type;
      if (response1?.success) {
        batch_type=response1?.data?.user_data?.group_profile_details?.is_batch
      }

      console.log("Batch Type :",batch_type)
      if (batch_type==='mixed') {
        handleDataNameListMixed(response2?.data?.items,response5?.data);
      }
      if (batch_type==='batch'){
        handleDataNameListBatch(response2?.data?.items)
      }
      if (batch_type==='batchless'){
        handleDataNameListBatchless(response5?.data)
      }

      // if (response2.success) {
      //   let tempList = [];
      //   response2.data.items.map((x) => {
      //     const { id, code, name, ...others } = x;
      //     let a = {
      //       ...others,
      //       text: x.item_name,
      //       description: x.item_code,
      //       // content: (
      //       //   <Dropdown.Item 
      //       //   // onClick={() => setShowStock(true)}
      //       //   >
      //       //     {x.item_name}
      //       //   </Dropdown.Item>
      //       // ),
      //       value: x.item_code,
      //     };
      //     if(x.batch_list?.length>0)
      //     tempList.push(a);
      //   });
      //   setItemNameList(tempList);
      // }
      if (response3.success) {
        minFunct(response3.data);
      }
      if (response4.success) {
        let tempList = [];
        // filtering stock according to document no
        response4?.data?.map((item) => {
          // if(!item) return 0
          let tempData = {};
          if (tempList?.length < 1) {
            tempData = {
              id: item?.journal_master?.id,
              code: item?.journal_master?.document_number,
              narration: item?.journal_master?.narration,
              salesman: item?.journal_master?.salesman,
              date: item?.journal_master?.date,
              total_items: parseInt(item?.count),
              items: [
                {
                  name: item?.item_add?.name,
                  code: item?.item_add?.code,
                  qty: item?.count,
                  qty_type: item?.add_qty ? "add" : "less",
                  cost: item?.cost,
                  unit: item?.unit,
                  value: item?.value,
                  godown: item?.godown,
                },
              ],
            };
            tempList.push(tempData);
          } else if (
            tempList.findIndex((x) => {
              return x.code == item?.journal_master?.document_number;
            }) == -1
          ) {
            tempList.push({
              id: item?.journal_master?.id,
              code: item?.journal_master?.document_number,
              narration: item?.journal_master?.narration,
              salesman: item?.journal_master?.salesman,
              date: item?.journal_master?.date,
              total_items: parseInt(item?.count),
              items: [
                {
                  name: item?.item_add?.name,
                  code: item?.item_add?.code,
                  qty: item?.count,
                  qty_type: item?.add_qty ? "add" : "less",
                  cost: item?.cost,
                  unit: item?.unit,
                  value: item?.value,
                  godown: item?.godown,
                },
              ],
            });
          } else {
            tempList.map((data) => {
              if (data.code == item?.journal_master?.document_number) {
                data.total_items =
                  parseInt(data.total_items) + parseInt(item?.count);
                data?.items.push({
                  name: item?.item_add?.name,
                  code: item?.item_add?.code,
                  qty: item?.count,
                  qty_type: item?.add_qty ? "add" : "less",
                  cost: item?.cost,
                  unit: item?.unit,
                  value: item?.value,
                  godown: item?.godown,
                });
              }
            });
          }
        });
        const tempListData = calcTotalVal(tempList);
        setstockJList([...tempListData?.reverse()]);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response,
        submitData = { ...stockJAdd, item: stockTableItemList };
      console.log(submitData);
      // return 0
      if (!edit) {
        response = await postStockJ(submitData);
      } else {
        response = await putStockJ(submitData, edit?.id);
      }
      if (response.success) {
        Swal.fire(
          `Stock journal ${edit ? "edited" : "saved"} successfully`,
          "",
          "success"
        );
        setEdit(false);
        handleClearAll();
        getData();
      } else {
        Swal.fire(response.data, "", "error");
      }
    } catch (err) {
      Swal.fire(
        "Something went wrong",
        `Failed to ${edit ? "edit" : "add"} stock journal`,
        "error"
      );
    }
  };

  return (
    <div className="item_add zoom-responsive-view-stk-jrn">
      <div className="itemList_header row mx-0 mb-3">
        <div className="page_head ps-4 d-flex justify-content-between">
          <div>
            <div className="fw-600 fs-5 mt-3">Stock Journal</div>
            <div className="page_head_items mb-1">
              <div className={`page_head_item active`}>Journal</div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4">
        <StockJournalDetails
          {...{
            showStock,
            setShowStock,
            setShowJournalFilter,
            stockJAdd,
            setStockJAdd,
            setStockTableItemList,
            edit,
            stockTableItem,
            setStockTableItem,
            handleAddToTableList,
            itemNameList,
            unitList,
            stockTableItemList,
            handleClearAll,
            setStockTableItemList,
            handleResetStockTabe,
            handleSubmit,
            tableEdit,
            setTableEdit,
            deleteStockJ,
            stockJList,
            setEdit,
          }}
        />
      </div>
      <Modal
        className="stk-jro-model-zoom "
        show={showJournalFilter}
        centered
        size="lg"
        onHide={() => setShowJournalFilter(false)}
      >
        <Modal.Body className="p-0 rounded-3  ">
          <StockJournalEdit
            list={stockJList}
            title={"Stock Journal List"}
            setShow={setShowJournalFilter}
            from={"stockjrl"}
            {...{
              edit,
              getData,
              setEdit,
              handleClearAll,
              permissions,
            }}
          />
        </Modal.Body>
      </Modal>
      {/* </div> */}
    </div>
  );
};
