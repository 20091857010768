import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import "../A5Format1.css";


export const TableContainer = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeigth,
    tableHead,
    // tableTrBody,
    tableBodyContent,
    hsnCalc,
    setTableTrBody,
    isLastRow,
    itemNumber,
    noOfItems
  } = props;
  const [newTableBody, setNewTableBody] = useState([]);
  let count = itemNumber
  const generatenewTableData = () => {
    const indices = [0, 1, 3, 5, 7];
    const newBody = tableBodyContent.map((item) => 
      item.filter((_, index) => indices.includes(index))
    );
    setNewTableBody(newBody);
  };

  useEffect(() => {
    generatenewTableData();
  }, [tableBodyContent]);
  return (
    <>
      <section class="items">
          <table>
            <thead>
              <tr>
                <th>SL.NO</th>
                <th>ITEM NAME</th>
                <th>QTY</th>
                <th>S RATE</th>
                <th>DISC %</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                newTableBody.length>0 && newTableBody.map((d,i)=>{
                  count++;
                  return(
                    <tr key={i}>
                      <td>{count}</td>

                      {d.map((x)=>{
                        return(
                          <td>{x}</td>
                        )
                      })}
                    </tr>
                  )})
              }
            </tbody>
          </table>
        </section>
    </>
  );
};
