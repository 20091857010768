import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import img from '../../assets/images/Ellipse.png'
import pic from '../../assets/images/demo-profile.png'
import './ActivityLog.css'
import { useAuthServices } from '../../services/controller/authServices'
import { MEDIA_URL } from '../../api/axios'
const ActivityLog = () => {
// let currentDate = new Date();
// let dateString = currentDate.toLocaleDateString('en-GB');
const [activityLog,setActivityLog]=useState([])
const [fromDate,SetFromDate]=useState()
const [toDate,SetToDate]=useState()
const {getActivityLog}=useAuthServices()
const getActivity = async()=>{
    try{
        const resp = await getActivityLog()
        setActivityLog(resp?.data)
        console.log(resp?.data)
    }catch(err){

    }
} 
useEffect(()=>{
    getActivity()
},[])

const groupedActivity = activityLog.reduce((acc, curr) => {
    const createdAt = new Date(curr.created_at);
    const date = createdAt.toLocaleDateString();
//   const time = `${createdAt.getHours()}:${createdAt.getMinutes()}`;
    const key = `${date}`;
    if (!acc[key]) {
    acc[key] = [];
    }
    acc[key].push(curr);

    return acc;
}, {});

const filteredActivity = Object.keys(groupedActivity).reduce((acc, date) => {
    const currentDate = new Date(date);
    if (currentDate >= fromDate && currentDate <= toDate) {
        acc[date] = groupedActivity[date];
    }
    return acc;
}, {});


    // console.log("Grouped Activity:", groupedActivity);


  return (
    <div className='m-3'>
        <div className='main-page w-100 mx-0' style={{"height":"650px","overflow":"scroll"}}>
        <div className='row d-flex mx-2 stick'>
            <div className='p-3 col-5 '><b>Activity Log</b></div>
            <div className=" mt-2 col-7 row d-flex justify-content-between align-items-center mx-0 px-0 p-1">
                    
                    <div className="col-2 col-3 mx-1  d-flex align-items-center mt-1 border border-secondary rounded">
                        <Dropdown
                        clearable
                        selection
                        required
                        // search={search}
                        // onKeyDown={handleKeyDown1}
                        // onChange={handleDropdownChangeItem}
                        className="purchase-input-text table-drop d-flex align-items-center py-0 form-control custom-dropdown-width border-dark"
                        name="item"
                        placeholder="select"
                        // value={selectedItem}
                        // options={allItem}
                        />
                    </div>
                    <div className="col-2 col-3 mx-3  d-flex align-items-center mt-1 border border-secondary rounded">
                        <Dropdown
                        clearable
                        selection
                        required
                        // search={search}
                        // onKeyDown={handleKeyDown1}
                        // onChange={handleDropdownChangeItem}
                        className="purchase-input-text table-drop d-flex align-items-center py-0 form-control custom-dropdown-width border-dark"
                        name="item"
                        placeholder="select"
                        // value={selectedItem}
                        // options={allItem}
                        />
                    </div>
                    <Form.Group className="col-3 mx-1 pe-4 ps-0 mx-0 d-flex align-items-start mt-1">
                        <Form.Label className="col-2 col-3 purchase-input-label align-middle">
                            From
                        </Form.Label>
                        <Form.Control
                            onChange={(e)=>SetFromDate(e.target.value)}
                            required
                            name="from_date"
                            value={fromDate}
                            className="purchase-input-text me-1"
                            placeholder="Document number"
                            type="date"
                        />
                    </Form.Group>
                    <Form.Group className="col-3 pe-4 mx-1 ps-0 mx-0 d-flex align-items-start mt-1">
                        <Form.Label className="col-1 col-2 purchase-input-label align-middle ms-2">
                           To
                        </Form.Label>
                        <Form.Control
                            onChange={(e)=>SetToDate(e.target.value)}
                            required
                            name="to_date"
                            value={toDate}
                            className="purchase-input-text me-2"
                            placeholder="Document number"
                            type="date"
                        />
                    </Form.Group>
                </div>
        </div>
        {Object.keys(groupedActivity).map(n=>

         <div className="row mx-0 pb-0 pt-0 ps-2 d-flex">
         <div className='col-2 ps-5 pt-5'>
             <div className='fs-6 ps-3'>
                 <span className='accordion-item1'></span>
                     <div className="accordion-item1">
                         <b className='move-cont'>{n}</b>
                         <img src={img} alt="" className='accordion-img1 ms-4'/> 
                     </div>
                 <span className='accordion-item1'></span>
             </div>
         </div>
         <div className='col-10 p-5 ps-4 pb-0 border-left activity-cont' >
            {groupedActivity[n].map(r=>
                <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={(r?.user?.image)?MEDIA_URL+r?.user?.image:pic} alt="" className='col-4 activity-icon'/>
                            <div className='col-8'>
                                <div>{r?.user?.full_name}</div>
                                <div>{r?.user?.fk_role}</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>{r?.description}</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>{
                    (new Date(r?.created_at).getHours()<13?new Date(r?.created_at).getHours():(new Date(r?.created_at).getHours()-12))+
                    ':'+
                    (new Date(r?.created_at).getMinutes()<10?('0'+new Date(r?.created_at).getMinutes()):new Date(r?.created_at).getMinutes())+
                    (new Date(r?.created_at).getHours()<13?" AM":" PM")
                    }</div>
                </div>
            )}
             

         </div>
     </div>
        
        
        )}
       
        {/* <div className="row mx-0 pb-0 pt-0 ps-2 d-flex ">
            <div className='col-2 ps-5 pt-5'>
                <div className='fs-6 ps-3'>
                    <span className='accordion-item1'></span>
                        <div className="accordion-item1">
                            <b className='move-cont'>{dateString}</b>
                            <img src={img} alt="" className='accordion-img1 ms-4'/> 
                        </div>
                    <span className='accordion-item1'></span>
                </div>
            </div>
            <div className='col-10 p-5 ps-4 pb-0  border-left activity-cont'>
                <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={pic} alt="" className='col-4'/>
                            <div className='col-8'>
                                <div>Heily Charly</div>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>It is a long established fact that a reader will be distracted by the  readable content</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>10:00 AM</div>
                </div>

                <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={pic} alt="" className='col-4'/>
                            <div className='col-8'>
                                <div>Heily Charly</div>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>It is a long established fact that a reader will be distracted by the  readable content</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>10:00 AM</div>
                </div>

                <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={pic} alt="" className='col-4'/>
                            <div className='col-8'>
                                <div>Heily Charly</div>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>It is a long established fact that a reader will be distracted by the  readable content</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>10:00 AM</div>
                </div>
            </div>
        </div>
        <div className="row mx-0 pb-0 pt-0 ps-2 d-flex ">
            <div className='col-2 ps-5 pt-5'>
                <div className='fs-6 ps-3'>
                    <span className='accordion-item1'></span>
                        <div className="accordion-item1">
                            <b className='move-cont'>{dateString}</b>
                            <img src={img} alt="" className='accordion-img1 ms-4'/> 
                        </div>
                    <span className='accordion-item1'></span>
                </div>
            </div>
            <div className='col-10 p-5 ps-4 pb-0  border-left activity-cont'>
            <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={pic} alt="" className='col-4'/>
                            <div className='col-8'>
                                <div>Heily Charly</div>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>It is a long established fact that a reader will be distracted by the  readable content</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>10:00 AM</div>
                </div>

               <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={pic} alt="" className='col-4'/>
                            <div className='col-8'>
                                <div>Heily Charly</div>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>It is a long established fact that a reader will be distracted by the  readable content</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>10:00 AM</div>
                </div>

               <div className=' row d-flex mb-4'>
                    <div className='col-10 d-flex cont-block p-3 mt-1'>
                        <div className='col-3 row d-flex align-items-center ps-2'>
                            <img src={pic} alt="" className='col-4 demo-pic'/>
                            <div className='col-8'>
                                <div>Heily Charly</div>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className='col-9 d-flex'>
                            <div className='black-border my-2'></div>
                            <div className='px-3 py-1'>It is a long established fact that a reader will be distracted by the  readable content</div>
                        </div>
                    </div>
                    <div className='col-2 time-cont d-flex align-items-center ps-5'>10:00 AM</div>
                </div>
            </div>
        </div> */}
    </div>
    </div>
    
  )
}

export default ActivityLog