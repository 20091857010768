import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import "../A4Format2.css";
import { toWords } from "number-to-words";


export const SheetFooter = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeigth,
    tableHead,
    // tableTrBody,
    tableBodyContent,
    hsnCalc,
    setTableTrBody,
    count,
    auth
  } = props;

  return (
    <>
      <div id={"footer-content"} className="">
        <div className="row bg border-top border-bottom mt-2 border-secondary m-0">
          <div className="col-8 border-end border-secondary">
            <div className="row border-bottom border-secondary">
              <p className="font-monospace mt-2">
                {total && toWords(total).toUpperCase()+' ONLY'} 
              </p>
              <p>
                DECLARATION <br />
                We declare that this invoice shows the actual price of the goods
                described and that all particulars are true and correct.
              </p>
            </div>
            <div className="row px-3 py-1">
              <div className="row">A/C Details</div>
              <div className="row">Bank Name :</div>
              <div className="row">A/c Number :</div>
              <div className="row">IFSC Code :</div>
            </div>
          </div>
          <div className="col-4 py-4">
            <div className="row">
              <div className="col-5 text-end">
                <div className="">Value:</div>
                <div className="">CGST:</div>
                <div className="">SGST:</div>
                <div className="">Total:</div>
                <div className="">Received:</div>
              </div>
              <div className="col-6">
                <div className="">{total_value}</div>
                <div className="">{total_sgst}</div>
                <div className="">{total_sgst}</div>
                <div className="">{total}</div>
                <div className="">------</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row  border-secondary m-0">
          <div className="col-8 border-end border-secondary">
            <div className="row">
              <div className="col-8 border-end border-secondary ">
                <p className=" mt-2 p-3 font-monospace">
                  (To be Prepared in Quadruplicate*) Duplicate for Supplier /
                  Transporter
                </p>
              </div>
              <div className="col-4 mt-2 p-3">Salesman</div>
            </div>
          </div>
          <div className="col-4 p-3">
            <p>For {auth?.others?.group_name} Authorized Signatory</p>
          </div>
        </div>
      </div>
    </>
  );
};
