import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import "../A4Format2.css";
import dayjs from "dayjs";

export const SheetHeader = (props) => {
  const { 
    auth,
    count,
    c_address,
    delivery_add,
    salesAdd,
    vehicle_no
   } = props;
   console.log('Auth ---------:  ', auth)
  return (
    <>
      <div id={"header-content"} className="">
        <div className="print-header text-center print-header-company">
          <div className="row d-flex justify-content-between">
            <div className="w-auto">
              <div className="row mx-1 my-2 gap-2">
                <div className="col-3 text-end">GSTIN: </div>
                <div className="w-auto">{auth?.others?.GSTIN || "8359234723"}</div>
              </div>
            </div>  
            <div className="w-auto">
              <div className="row mx-0 gap-2">
                <div className="col-4 text-end">Invoice: </div>
                <div className="w-auto"> {salesAdd?.documents_no || "" }</div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="text-center">
              <div className="mb-0">
                <h4 className="text-uppercase m-0">
                  {auth?.others?.group_name}
                </h4>
                <p className="m-0">
                  {auth?.others?.address_line_2 || "address1"}
                </p>
                <p className="m-0">
                  {auth?.others?.address_line_1 || "address2"}
                </p>
                <p className="m-0">{auth?.others?.mobile || "mobile"}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 fs-5 mb-2 text-center">
              <b>TAX INVOICE</b>
            </div>
          </div>
        </div>

        <div className="row border border-secondary mx-1 my-1">
          <div className="col-6">
            <div className="">
              <div>Reverse Charge : No</div>
              <div>Invoice No : {salesAdd.documents_no}</div>
              <div>Invoice Date : {dayjs(salesAdd.date).format('DD/MM/YYYY')}</div>
            </div>
          </div>
          <div className="col-6 border-start border-secondary">
            <div><b>Despatch Details</b></div>
            <div>Vehicle No: {vehicle_no}</div>
            <div>
              Place of Supply: 
              {delivery_add}</div>
          </div>
        </div>
        <div
          className="row border mx-1 border-secondary"
          style={{
            height: "120px",
          }}
        >
          <div className="w-100 text-start position-relative ps-2 py-0 d-flex">
            <p className="h-100 d-flex flex-column w-100 p-3 mb-0">
              <div style={{ height: "fit-content" }}>
                Name & Address of Receiver :
              </div>
              <br />
              {/* {c_name?.toUpperCase()} */}
              <br />
              <div className="d-flex flex-wrap mt-2">
               {c_address}
              </div>
            </p>
            {/* <div className="w-100 h-100 p-2">
                <div className="text-center">MOBILE: {c_number}</div>
                <div className="text-center">GST Number: {c_gstin}</div>
              </div> */}
            {/* <div className="col-5 h-100 w-100 d-flex flex-column p-2">
                <div style={{ height: "fit-content" }}>Delivery to :</div>{" "}
                <br />
                {delivery_add?.toUpperCase()}
              </div> */}
            <div
              style={{ minHeight: "100%" }}
              className="border-secondary h-100 p-0 m-0"
            ></div>
            <div className="d-flex flex-column gap-2 w-100 h-100 text-end p-3">
              {/* <div className="row mx-0">
                  <div style={{ height: "fit-content" }}>Vehicle No :</div>{" "}
                  {vehicle_no}
                </div>
                <div className="row mx-0">
                  <div style={{ height: "fit-content" }}>Driver :</div>{" "}
                  {driver?.toUpperCase()}
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

