import React from "react";
import tempImg from "../../../assets/images/image.jpg";
import { toWords } from "number-to-words";
import { Rnd } from "react-rnd";

export const NormalA42 = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    taxPerc,
    total_qty,
    total_disc,
    total_value,
    total_cgst,
    total_sgst,
    total,
    roundOff,
    hsn,
    TableHeight,
    tableHead,
    tableTrBody,
    hsnCalc,
  } = props;

  return (
    <div className="m-2 border border-secondary pt-2" id="new">
      <div style={{ height: "15rem" }} className="text-center row mx-0 px-2">
        <Rnd
          default={{
            x: 80,
            y: 0,
            height: "100px",
            width: "500px",
          }}
          style={{ padding: "0px" }}
          // enableResizing="false"
          bounds="parent"
        >
          <div className="mb-3 print-normala4-2-img-cont">
            <img
              src={tempImg}
              className="print-normala4-2-img"
              alt={"comp logo"}
            />
          </div>
        </Rnd>
        <Rnd
          default={{
            x: 200,
            y: 100,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <p>
            <b>
              Thirnavaya Road Near Supriya Hospital
              <br />
              Puthanathani
              <br />
              Mobile : 787897898
              <br />
              Gst Number :
            </b>
            42323434
          </p>
        </Rnd>
      </div>
      <div className="border-top border-bottom mt-1 border-secondary text-center py-2">
        <b>TAX INVOICE</b>
      </div>
      <div className="row mx-0" style={{ height: "5rem" }}>
        <Rnd
          default={{
            x: 30,
            y: 5,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <div className="d-flex gap-3" style={{ width: "fit-content" }}>
            <div>Invoice No:</div>
            <div>4332424</div>
          </div>
        </Rnd>

        <Rnd
          default={{
            x: 30,
            y: 25,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <div className="d-flex gap-3">
            <div>Date:</div>
            <div>20/04/2023</div>
          </div>
        </Rnd>
        <Rnd
          default={{
            x: 322,
            y: 0,
          }}
          minHeight="100%"
          enableResizing="false"
          bounds="parent"
        >
          <div
            className="border-start border-secondary p-0 m-0"
            style={{ height: "5rem" }}
          ></div>
        </Rnd>
        <Rnd
          default={{
            x: 360,
            y: 5,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <div className="d-flex gap-3">
            <div>Invoice No:</div>
            <div>4332424</div>
          </div>
        </Rnd>
        <Rnd
          default={{
            x: 360,
            y: 25,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <div className="d-flex gap-3">
            <div>Date:</div>
            <div>20/04/2023</div>
          </div>
        </Rnd>
      </div>
      <div className="row mx-0 border-top border-bottom border-secondary">
        <div className="col-6 py-1 ps-4 border-end border-secondary">
          {"Details of Reciever ( Billed to )"}
        </div>
        <div className="col-6 py-1 ps-4 ">
          {"Details of Consignee ( Shipped to )"}
        </div>
      </div>
      <div
        style={{ height: "7rem" }}
        className="border-bottom border-secondary"
      >
        <Rnd
          default={{
            x: 20,
            y: -10,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <div>
            <b>SR Agencies</b>
            <br />
            BYPASS ROAD MALAPPURAM
            <br />
            MALAPPURAM KUNNUMMAL
            <br />
            KERALA 5435435
            <br />
          </div>
        </Rnd>
        <Rnd
          default={{
            x: 332,
            y: -20,
          }}
          minHeight="100%"
          enableResizing="false"
          // dragAxis="x"
          bounds="parent"
        >
          <div
            className="border-start border-secondary p-0 m-0"
            style={{ height: "7rem" }}
          ></div>
        </Rnd>
        <Rnd
          default={{
            x: 350,
            y: -10,
          }}
          enableResizing="false"
          bounds="parent"
        >
          <div>
            MOOCHIKAL ROAD
            <br />
            VALLUVAMBURAM PO
            <br />
            KOZHIKODE DIST
            <br />
            4545345
          </div>
        </Rnd>
      </div>

      <div className="border-top border-bottom mt-3 border-secondary">
        <table className="table mb-0">
          <thead>
            <tr>
              {tableHead.length > 0 &&
                tableHead.map((data, i) => (
                  <th
                    style={{
                      // border: '2px solid black',
                      padding: "0",
                      margin: "0px",
                      overflow: "auto",
                    }}
                    className={`border-bottom ${
                      i !== 0 && "border-start"
                    } border-secondary`}
                  >
                    <div
                      style={{
                        resize: "horizontal",
                        overflow: "auto",
                        width: "100%",
                        minWidth: "100%",
                        height: "fit-content",
                        margin: "0px",
                        padding: "6px",
                        // border: '1px solid black',
                        display: "block",
                      }}
                    >
                      {data}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {tableTrBody.length > 0 &&
              tableTrBody.map((data, i) => {
                const a = [];
                data.map((item) =>
                  a.push(
                    <td className="border-0 border-start border-end-0 border-secondary">
                      {item}
                    </td>
                  )
                );
                return (
                  <tr>
                    <td className="border-0">{i + 1}</td>
                    {a}
                  </tr>
                );
              })}
            <TableHeight />
            <tr>
              {tableHead.length > 0 &&
                tableHead.map((data, i) => {
                  // console.log(data)
                  if (data.match("Qty"))
                    return (
                      <td className="border-0 border-start border-secondary">
                        {total_qty}
                      </td>
                    );
                  else if (data.match("Value"))
                    return (
                      <td className="border-0 border-start border-secondary">
                        {total_value}
                      </td>
                    );
                  else if (data.includes("Disc") && !data.includes("%"))
                    return (
                      <td className="border-0 border-start border-secondary">
                        {total_disc}
                      </td>
                    );
                  else if (data.match(/^CGST|SGST/))
                    return (
                      <td className="border-0 border-start border-secondary">
                        {total_sgst}
                      </td>
                    );
                  else if (data.includes("Total"))
                    return (
                      <td className="border-0 border-start border-secondary">
                        {total}
                      </td>
                    );
                  else {
                    return (
                      <td
                        className={`border-0 ${
                          i !== 0 && "border-start"
                        } border-secondary`}
                      ></td>
                    );
                  }
                })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row mx-0">
        <div className="col-6 p-2 border-end border-secondary px-0">
          <div className="border-bottom border-secondary pb-2 px-2">
            {total &&
              toWords(total)?.slice(0, 1)?.toUpperCase() +
                toWords(total)?.slice(1)}
          </div>
          <div className="px-2 pt-2">
            <b>
              <u>BANK DETAILS</u>
            </b>
            <br />
            Account no : 3443243243243
            <br />
            IFSC : VDS34324324
            <br />
            CSB BANK : PUTHANATHANI
          </div>
        </div>
        <div className="col-6 pe-0 pt-2">
          <div className="row mx-0 justify-content-end">
            <div className="col-8 text-end">Round off :</div>
            <div className="col-3 text-end">{roundOff}</div>
          </div>
          <div className="row mx-0 justify-content-end">
            <div className="col-8 text-end">
              <b>Bill Total :</b>
            </div>
            <div className="col-3 text-end">
              {(total - roundOff)?.toFixed(0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
