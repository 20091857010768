import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Dropdown, Icon } from 'semantic-ui-react'
import useItemServices from '../../../../services/master/itemServices';
import Swal from 'sweetalert2';

const ItemCodeChange = (props) => {
    const {getData} = props
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { getItemList, postCodeChange } = useItemServices();
    const [itemList, setItemList] = useState([])
    // const [itemData, setItemData] = useState('')

    const [itemAdd, setItemAdd] = useState({
        old_code: null,
        fk_item: null,
        new_code: null,
        item_name: null,
    })

    const getItemData = async () => {
        const response = await getItemList()
        let tempList = []
        if (response?.success) {
            response.data.map(item => {
                let a
                if (item.name && item.code) {
                    a = { key: item.code, value: item.id, text: item.name?.toUpperCase(), description: item.code }
                }
                tempList.push(a)
            })
            setItemList(tempList)
        }
    }

    const search = (options, searchValue) => {
        searchValue = searchValue.toUpperCase();
        return options?.filter((option) => {
            return (
                option?.value?.toString()?.includes(searchValue) ||
                option?.text?.includes(searchValue) ||
                option?.description?.includes(searchValue)
            );
        });
    };

    const handleChange = (e, data) => {
        console.log(e.target.name)
        if (data) {
            let item_data = data.options.filter((x) => x.value === data.value)[0];
            console.log(item_data)
            if (data.name.includes('fk_item')) {
                setItemAdd({
                    ...itemAdd,
                    [data.name]: item_data.value, old_code: item_data.key, item_name: item_data.text
                })
            }

        }
        if (e.target.name == 'new_code') {
            console.log(e.target.value,'9999')
            setItemAdd({
                ...itemAdd,
                [e.target.name]: e.target.value
            })
        }
        console.log(itemAdd, '777777777')

    }

    const handleReset = () =>{
        setItemAdd({
            old_code: null,
            fk_item: null,
            new_code: null,
            item_name: null,
        })
    }

    const handleTOUpperCase = (data) => {
        let keysOfData, tempData = { ...data }
        if (typeof data == 'object')
            keysOfData = Object.keys(data)
        if (!keysOfData?.length > 0) return 0
        keysOfData.map(item => {
            if (typeof data[item] == 'string' && item != 'method') {
                let itemTemp = data[item]?.toUpperCase()
                tempData = { ...tempData, [item]: itemTemp }
            }
        })
        return tempData
    }

    useEffect(() => {
        getItemData()
        

    }, [])

    useEffect(()=>{
        
    },[itemAdd])

    const handleSubmit = async (data) => {
        console.log('hai')
        data.preventDefault()
        try {
           
            const submitData = handleTOUpperCase(itemAdd)
            let response
            response = await postCodeChange(submitData)
            console.log(response)
            if (response?.success) {
                Swal.fire(response.message, '', 'success', )
            }
            else{
                Swal.fire(response.message, '','error')
            }

        }
        catch (err) {
            Swal.fire( err?.response?.data?.message, '','error')
        }
        getData()
        handleReset()
        handleClose()
        getItemData()
        
    }

    return (

        <div>
            <Button variant="primary" onClick={handleShow}>
                Item Code Change
            </Button>
            <form  >


                <Modal className='ac-code-chg-model' show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Item Code Change</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form  >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Item</Form.Label>
                                <Dropdown
                                    //   disabled={edit}
                                    // clearable
                                    selection
                                    search={search}
                                    onChange={handleChange}
                                    className="cheque-reg-select text-dark item d-flex align-items-center py-0 form-control"
                                    name="fk_item"
                                    placeholder="Select Account Details"
                                    value={itemAdd?.fk_item || ""}
                                    options={itemList}

                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <Form.Label>Old Item Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            autoFocus
                                            className='border border-3 border-dark'
                                            value={itemAdd?.old_code || ''}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <Form.Label>Enter New Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            autoFocus
                                            name='new_code'
                                            className='border border-3 border-dark'
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button onClick={handleSubmit} type='submit' variant="primary" >
                            Save Changes
                        </Button>
                      
                    </Modal.Footer>
                </Modal>
            </form>
        </div>
    )
}

export default ItemCodeChange