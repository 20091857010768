import React, { useEffect, useState } from 'react'
import { GrRefresh } from "react-icons/gr";
import searchIcon from "../../../../assets/icons/search.png";
// import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

const BalanceSheetTables = (props) => {
    const { balanceSheetData } = props
    const [stockValue, setStockValue] = useState()

    const [value, setValue] = useState(0.00)
    var total_bal = 0;
    var countAsset = 0;
    const [newValue, setNewValue] = useState()
    const [tableValue, setTableValue] = useState({
        total_asset: 0.0,
        total_liability: 0.00,
        diff_amount: 0.00,
    })

    useEffect(() => {
        if (newValue?.account_details?.length > 0) {
            const totalAsset = newValue.account_details.reduce((a, b) => b.closing_balance > 0 ? a + b.closing_balance : a, 0) + (value > 0 ? value : 0.0)
            const totalLiab = newValue.account_details.reduce((a, b) => b.closing_balance < 0 ? a + b.closing_balance : a, 0) + (value < 0 ? value : 0.0)
            console.log('totalAsset',totalAsset)
            console.log('totalLiab',totalLiab)
            const diffAmount = totalAsset - Math.abs(totalLiab)
            setTableValue(data => ({ ...data, total_asset: totalAsset?.toFixed(2), total_liability: totalLiab?.toFixed(2), diff_amount: diffAmount.toFixed(2) }))
        }
    }, [newValue])

    useEffect(() => {

        if (stockValue == "fifo") {
            setValue(balanceSheetData?.fifo)
            // console.log(balanceSheetData?.fifo.toFixed(2),"data")
        }
        else if (stockValue == "avgCost") {
            setValue(balanceSheetData?.average_cost_method)
        }
        else {
            setValue(balanceSheetData?.closing_stock_value)
        }

    }, [stockValue, balanceSheetData, tableValue])

    const handleChange = (data) => {
        if (data.target.name == "stock-value") {
            setStockValue(data.target.value)
        }
        if (data.target.name == "btnOk") {
            setNewValue(balanceSheetData)
        }

    }

    console.log(tableValue?.total_asset)

    return (
        <>
            <div style={{backgroundColor:"rgba(77, 48, 114, 1)"}} className=' mt-3 d-flex justify-content-between rounded-top border-bottom border-2'>
                <div className='col-7 ms-2 d-flex justify-content-between'>

                    <div className='d-flex align-items-center'>
                        <p className='text-white mt-3'>Stock Value Balance</p>
                        <p className='bg-white ms-2 px-5 py-1 rounded'>{value | 0.00}</p>
                    </div>

                    <div className='d-flex align-items-center '>
                        <p className='text-white pt-3 me-3'>stock value type </p>
                        <select value={stockValue} onChange={handleChange} className='py-1' name="stock-value" id="">
                            {/* <option value=""></option> */}
                            <option value="lastCost">Last Purchase cost</option>
                            <option value="fifo">FIFO</option>
                            <option value="avgCost">Average cost</option>
                        </select>
                    </div>

                    <div className='d-flex align-items-center'>
                        <button name='btnOk' onClick={handleChange} className='px-3 py-1 rounded'>ok</button>
                    </div>
                </div>
                <div className="m-0 p-0 col-3 p-2 stock-ledger-search d-flex align-items-center me-1">
					<div className="col-2 me-3">
						<GrRefresh className="bg-light m-1 p-1 rounded-1" size={28} />
					</div>
					<div className="item_seach_bar_cont rounded-2 col-9 py-3">
						<img src={searchIcon} className="search_img me-3 ms-2 py-2" />
						<input
							// value={search}
							// onChange={handleSearch}
							className="item_search_bar rounded-2 border-0 "
							placeholder="Search"
							type="text"
						/>
					</div>
				</div>

            </div>
            <div className='d-flex'>
                <div className='w-100' style={{ height: '31rem', overflow: 'hidden', overflowY: 'scroll' }}>

                    <table className='table table-striped border border-5 border-dark'>
                        <thead>
                            <tr className='bal-sheet-table-head '>
                                {/* <th>Sn</th> */}
                                <th>Assets</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                newValue?.account_details?.length > 0 && 
                                newValue?.account_details?.map((data)=>{
                                    console.log(value)

                                    return(
                                        <>
                                        <tr className='bg-asset-color'>
                                            <td className=''></td>
                                            <td className=''>{value > 0 ? value.toFixed(2) : "0.00"}</td>
                                        </tr>
                                        </>
                                    )

                                }
                                    
                                )

                            } */}

                            {
                                newValue?.account_details?.length > 0 &&
                                newValue?.account_details
                                .filter((data) => !(data?.account_name !== "CLOSING STOCK VALUE" && data?.closing_balance <= 0))
                                .map((data, i) => {
                                    // total_bal = total_bal + data?.closing_balance

                                    countAsset = countAsset + 1

                                    return  (
                                       
                                        <>
                                            <tr className='bg-asset-color'>
                                                {/* <td>{countAsset}</td> */}
                                                <td>{data?.account_name}</td>
                                                {/* <td>{ data?.closing_balance.toFixed(2)}</td> */}
                                                <td>{newValue?.closing_stock_account_name == data?.account_name? value.toFixed(2) : data?.closing_balance.toFixed(2)}</td>

                                            </tr>
                                        </>
                                    )
                                },
                                )
                            }

                            <tr>
                                <td className='text-start bg-danger text-white'>LOSS: {tableValue.diff_amount < 0 ? tableValue.diff_amount : "0.00"}</td>
                                <td className='text-end bg-danger text-white'>Total AMOUNT: {Math.abs((Number(tableValue.total_asset))).toFixed(2)}</td>
                                
                            </tr>

                            {/* <tr>
                                <td className='bg-danger text-white' colSpan={2}>LOSS:{tableValue.total_asset - tableValue.total_liability}</td>
                            </tr> */}
                            {/* <tr style={{ position: "sticky", bottom: "0" }}>
                                <td className='bg-primary text-white' colSpan={2}>TOTAL ASSET: {Math.abs((Number(tableValue.total_asset) ) + (Math.abs(Number(tableValue.diff_amount < 0 ? tableValue.diff_amount : "0.00"))) ).toFixed(2)}</td>
                            </tr> */}


                        </tbody>
                    </table>
                </div>

                <div className='w-100' style={{ height: '30rem', overflow: 'hidden', overflowY: 'scroll' , }}>


                    <table className='table table-striped border border-5 border-dark  '>
                        <thead>
                            <tr className='bal-sheet-table-head mb-1'>
                                {/* <th>Sn</th> */}
                                <th>Liability</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {
                                newValue?.account_details?.length > 0 &&
                                newValue?.account_details
                                .filter((data) => !(data?.account_name !== "CLOSING STOCK VALUE" && data?.closing_balance >= 0))

                                .map((data, i) => {
                                    console.log(data)
                                    return (
                                        <>
                                            <tr className='bg-liability-color'>
                                                <td>{data.account_name}</td>
                                                <td>{Math.abs(data.closing_balance).toFixed(2)}</td>
                                            </tr>
                                        </>
                                    )
                                    // if(data?.acc_group == "LIABILITY"){
                                    //     return data?.acc_group == "LIABILITY" && (
                                    //         <>
                                    //             <tr className='bg-liability-color'>
                                    //                 <td>{data.account_name}</td>
                                    //                 <td>{data.closing_balance.toFixed(2)}</td>
                                    //             </tr>
                                    //         </>
                                    //     )
                                    // }
                                    // else if(data?.closing_balance<0){
                                    //     return (
                                    //         <>
                                    //             <tr className='bg-liability-color'>
                                    //                 <td>{data.account_name}</td>
                                    //                 <td>{Math.abs(data.closing_balance).toFixed(2)}</td>
                                    //             </tr>
                                    //         </>
                                    //     )
                                    // }
                                })
                            }
                            <tr>
                                <td className='text-start bg-success text-white'>PROFIT: {tableValue.diff_amount > 0 ? tableValue.diff_amount : "0.00"}</td>
                                <td className='text-end bg-success text-white'>TOTAL AMOUNT: {Math.abs((Number(tableValue.total_liability) )).toFixed(2)} </td>
                            </tr>

                            {/* <tr >
                                <td className='bg-primary text-white' colSpan={2}>TOTAL LIABILITY: {(Math.abs((Number(tableValue.total_liability) ) - (Math.abs(Number(tableValue.diff_amount > 0 ? tableValue.diff_amount : "0.00"))) )).toFixed(2)}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    
                </div>
                
            </div>
            <div style={{backgroundColor:"#d2c5e3"}} className='row  mx-1 text-black text-bold '>
                <div className=' col  border border-2 border-white'>
                    <td className='text-black text p-2' >TOTAL ASSET: {Math.abs((Number(tableValue.total_asset) ) + (Math.abs(Number(tableValue.diff_amount < 0 ? tableValue.diff_amount : "0.00"))) ).toFixed(2)}</td>
                    
                </div>
                <div className=' col  border border-2 border-white'>
                    <td className='text-black text p-2' >TOTAL LIABILITY: {(Math.abs((Number(tableValue.total_liability) ) - (Math.abs(Number(tableValue.diff_amount > 0 ? tableValue.diff_amount : "0.00"))) )).toFixed(2)}</td>
                </div>
            </div>

        </>

    )
}

export default BalanceSheetTables