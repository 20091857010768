import React, { useEffect, useState, memo } from "react";

import { useSelector } from "react-redux";
import "./A4Format2.css";
import { SheetFormat } from "./components/SheetFormat";
import { Item } from "semantic-ui-react";

export const A4Format1 = (props) => {
  // console.log('step 52');
  const [sheets, setSheets] = useState([]);
  // console.log('step 53');
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeight,
    tableTrBody,
    setTableTrBody,
    tableHead,
    hsnCalc,
    tableRowContent,
    GetContent,
    salesAdd
  } = props;
  console.log(
    'c_address: ',
    c_address,
    'c_name: ',
    c_name,
    'c_number: ',
    c_number,
    'delivery_add: ',
    delivery_add,
    'c_gstin: ',
    c_gstin,
    'vehicle_no: ',
    vehicle_no,
    'driver: ',
    driver,
    'total_qty: ',
    total_qty,
    'total_disc: ',
    total_disc,
    'total_value: ',
    total_value,
    'total_sgst: ',
    total_sgst,
    'total: ',
    total,
    'roundOff: ',
    roundOff,
    'TableHeight: ',
    TableHeight,
    'tableTrBody: ',
    tableTrBody,
    'setTableTrBody: ',
    setTableTrBody,
    'tableHead: ',
    tableHead,
    'hsnCalc: ',
    hsnCalc,
    'tableRowContent: ',
    tableRowContent,
    'GetContent: ',
    GetContent,
    'salesAdd: ',
    salesAdd
  );
  // console.log('step 54');
  // console.log(props.tableTrBody, '======+==+==+==+==')
  // console.log(tableTrBody, '======+==+==+==+==')

  const auth = useSelector((state) => state.auth.userDetails);
  // console.log('step 55');
  const itemList = [];
  const minNoOfItems = 10;
  const maxNoOfItems = 22;
  // console.log('step 56');
  let sheetList = []; 
  // console.log('step 57');
  let lastRow = [];
  let tableRowContentNew = tableTrBody;
  tableRowContentNew.map((tdData)=>console.log('::+::::+::::+::::+:::+:::+::::', tdData))

  // console.log('step 58');
  // console.log('1: tableRowContentNew: ', tableRowContentNew);

  // console.log('step 59');
  const configureSheets = () => {
    // const a4Height = 297;
    // const rowSize = 15;
    // const sampleDiv = document.getElementById("my_mm");
    // const mm_unit = sampleDiv.clientHeight / 100;
    // const headerDiv = document.getElementById("header-content");
    // const footerDiv = document.getElementById("footer-content");
    // const fixedHeight = Math.floor(
    //   (headerDiv.clientHeight + footerDiv.clientHeight) / mm_unit
    // );
    // const remainingHeight = a4Height - (fixedHeight + rowSize * 2);
    // const numberOfRows = Math.floor(remainingHeight / (rowSize * 3));
  // console.log('step 63');
  if (tableTrBody.length > 0){
      lastRow = tableTrBody[tableTrBody.length-1];
  }
  let count = 1;
  // console.log('step 64');
    while (tableRowContentNew.length > 0) {
    // while (count < 4) {
      //   itemList.push(
      //     <SheetFormat
      //       showSheet={true}
      //       count={""}
      //       showTableBody={true}
      //     //   tableBodyContent={tableRowContent.splice(0, numberOfRows)}
      //       tableBodyContent={tableRowContentNew.splice(0, 5)}
      //       auth={auth}
      //       tableHead={tableHead}
      //     />
      //   );
      // console.log('step 65.1..');
      // console.log(count, '---', tableRowContentNew)
      // console.log('step 65.2..');
      if (tableRowContentNew.length > maxNoOfItems){
        itemList.push({'content': tableRowContentNew.splice(0, maxNoOfItems), 'isLastRow': false});
      }else if(tableRowContentNew.length < minNoOfItems) {
        itemList.push({'content': tableRowContentNew.splice(0, maxNoOfItems), 'isLastRow': true});
      }else{
        itemList.push({'content': tableRowContentNew.splice(0, minNoOfItems), 'isLastRow': false});        
      }
      

      // console.log(count, '-+++++----+++++-', itemList)
      // console.log('step 65.3..');
      count++;
      // console.log('step 65.4..');
    }
    
    // console.log('step 66');
    // console.log('itemList: ----:  ', itemList)
    // console.log('step 67');
    // const assignSlNo = (items) => {
    //   let slNo = 1;
    //   return items.map((item) => {
    //     const currentSlNo = slNo;
    //     slNo += item.content.length; // increment slNo by the length of the content
    //     return {
    //       ...item,
    //       sl_no: `${currentSlNo} to ${slNo - 1}`
    //     };
    //   });
    // };
    console.log("Woooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooow",itemList)
    sheetList = itemList.map((item, index) =>{
    return(
      <SheetFormat
		  key={index}
		  showSheet={true}
		  //   count={""}
		  showTableBody={true}
		  tableBodyContent={item.content}
		  auth={auth}
      isLastRow={item.isLastRow}
		  tableHead={tableHead}
      //itemNumber={count_sl_num}
      maxNoOfItems={maxNoOfItems}
      minNoOfItems={minNoOfItems}
      {...{
        c_address,
        c_name,
        c_number,
        delivery_add,
        c_gstin,
        vehicle_no,
        driver,
        total_qty,
        total_disc,
        total_value,
        total_sgst,
        total,
        roundOff,
        TableHeight,
        tableTrBody,
        setTableTrBody,
        tableHead,
        hsnCalc,
        tableRowContent,
        GetContent,
        salesAdd
      }}
		/>
    )}
	  );
    
    
    // console.log('step 68');
    //  setSheets(sheetList);
  };

  // console.log('step 60');
  
  // useEffect(() => {
  //   console.log('step 61');
  //   console.log('2: tableRowContentNew: ', tableRowContentNew);
  //   console.log('step 62');
  //   // GetContent();
  //   // configureSheets();
  //   // console.log('][][][]][][][][][][][][]]',sheetList);
  //   console.log('step 69');
  //   console.log('3:tableRowContentNew: ', tableRowContentNew);

  // });
  configureSheets();
  // console.log('][][][]][][][][][][][][]]',sheetList);
  return (
    <div className="d-flex flex-column">
      {/* <SheetFormat
		showSheet={true}
		showTableBody={false}
		tableBodyContent={tableTrBody}
		auth={auth}
		tableHead={tableHead}
	  /> */}
    {/* {console.log('][][][]][][][][][][][][]]',sheetList)} */}
      {sheetList}
    </div>
  );
};
