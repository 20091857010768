import React, { useEffect, useState } from 'react'
import './accountInitialize.css'
import Swal from 'sweetalert2'
import { useBaseServices } from '../../services/base/baseServices'
import { useNavigate } from 'react-router'

const AccountInitializes = () => {
   const {postAccountInitialize}=useBaseServices()
   const [selectedList,setSelectedList]=useState([])
   const [all,setAll]=useState(true)
   const inital_data={
      "account":false,
      "customer":false,
      "supplier":false,
      "staff":false,
      "items":false,
      "purchase":false,
      "purchase_return":false,
      "purchase_order":false,
      "sales":false,
      "sales_return":false,
      "sales_order":false,
      "stock_journal":false,
      "account_journal":false,
      "receipt":false,
      "payment":false,
      "daybook":false,
      "cheque_register":false,
      "batch":false,
      "barcode":false,
      "payroll":false,
      "production":false,
   }
   const [options, setOptions] = useState(inital_data);
   const navigate = useNavigate()
   const handleRadio = (e) =>{
      const item = e.target.value
      setOptions(preOpt => ({...preOpt,[item]: !preOpt[item]}))
      if (selectedList.includes(item)){
         setSelectedList(selectedList.filter(i=>i !== item))
      }else{
         setSelectedList([...selectedList,item])
      }
   }

   const handleSelectAll = () => {
      
      if (all===true){
      setOptions(prevOptions => {
        const updatedOptions = { ...prevOptions };
        for (let key in updatedOptions) {
          updatedOptions[key] = true;
        }
        return updatedOptions;
      });
    
      const list = Object.keys(options);
      setSelectedList(list);
      setAll(prevAll => !prevAll)
      }else{
         setOptions(prevOptions => {
            const updatedOptions = { ...prevOptions };
            for (let key in updatedOptions) {
              updatedOptions[key] = false;
            }
            return updatedOptions;
          });
         setSelectedList([]);
         setAll(prevAll => !prevAll)
      }
    };

    const handleKey =(value) =>{
      const data =value.replace(/_/g, " ")
      return data[0].toUpperCase()+data.slice(1)
    }

    const handleReset = () =>{
      setSelectedList([]);
      setOptions(inital_data)
    }

    const handleSubmit =async () =>{
      try{
         const response = await postAccountInitialize({'delete_list':selectedList});
         console.log(response)
         if (response?.success) {
            Swal.fire({
                title: "Success",
                text: 'Selected Items Deleted.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })
            navigate('/')
         }
      }catch(err){
         Swal.fire('Error', err?.response?.data?.message || "Something went wrong. Please try again later.","error");
      }
    }
      
   return (
      <div className='main-page'>
         <h4 className='p-3'>Account Initialize</h4>
         <span className='px-4'>Initialize</span> <span className=''>|</span>
         <button className='ms-4 p-2 rounded initialize' onClick={handleSelectAll}>{all===true?'Select All':'Reset'}</button>
         <div className='border border border-3 black rounded m-3'>
            <div className="row mt-3 m-4">
               {Object.keys(options).map((key) => (
                  <div key={key} className="col-3 radio-box me-3 mb-2 mx-2 px-3">
                  <div className='d-flex align-item-center'>
                     <input
                        className='me-2 permission-checkbox boxsize'
                        type="checkbox"
                        value={key}
                        onChange={handleRadio}
                        checked={options[key]}
                     />
                     <span className=''>{handleKey(key)}</span>
                  </div>
                  </div>
               ))}
            </div>

         </div>
         <div className='m-5 d-flex justify-content-end'>
            <button style={{ background: "rgba(112, 112, 112, 1)" }} className='btn text-white px-5 me-3 mb-3' onClick={handleReset}>Cancel</button>
            <button style={{ background: "rgba(74, 0, 168, 1)" }} className='btn text-white px-5 mb-3' onClick={handleSubmit}>Initialize</button>
         </div>

      </div>
   )
}

export default AccountInitializes