import React, { useEffect, useState } from "react";
import "./printConfig.css";
import { Rnd } from "react-rnd";
import { CustomTable } from "./components/customTable";


export const PrintConfig = () => {
  const [prevColor, setPrevColor] = useState('');
  const [prevSize, setPrevSize] = useState('');
  return (
    <div className="print-main bg-primary d-flex align-items-center justify-content-center">
      <div className="container bg-light">
          <div className="row">
            <div className="col-8 design-box">
            
              <Rnd style={{backgroundColor:'red'}}
                onDragStart={(e,d)=>{
                  setPrevColor(e.target.style.color);
                  setPrevSize(e.target.style.fontSize);
                  console.log(e.target.style);
                  e.target.style.color = 'green';
                  e.target.style.fontSize = '84px';
                }}
                onDragStop={(e,d)=>{
                  console.log(e.target.style);
                  e.target.style.color = prevColor;
                  e.target.style.fontSize    = prevSize;

                }}
                >
                table
              </Rnd>

              {/* <Rnd style={{backgroundColor:'red'}}
                onDrag={(e,d)=>{
                  console.log('event :', );
                }}>
                table2
              </Rnd> */}
            
            <CustomTable/>
            </div>
            <div className="col-4 tool-box">
              <h2 className="mt-3" style={{
                borderBottom: "2px solid"
              }}>Tools</h2>
            </div>
          </div>
            
      </div>
    </div>
  );
};
