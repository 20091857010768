import { useEffect, useState } from "react";
import { NormaA4 } from "./printStyles/NormaA4";
import { NormalA42 } from "./printStyles/NormalA42";
import { Thermal } from "./printStyles/Thermal";
import "./printStyles.css";
import { A4Format1 } from "./printStyles/A4Format1/A4Format1";
import {A5Format1} from "./printStyles/A5Format1/A5Format1";
import { DotMatrix1 } from "./printStyles/DotMatrix1/DotMatrix1";

export const GenerateDynamicHtml = (props) => {
	console.log("step 5");
	console.log("step 6");
	const {
		c_address,
		c_name,
		c_number,
		delivery_add,
		c_gstin,
		vehicle_no,
		driver,
		taxPerc,
		total_qty,
		total_disc,
		total_value,
		total_cgst,
		total_sgst,
		total,
		roundOff,
		hsn,
		hsnCalc,
		salesAdd
	} = props;
	console.log("step 7");

	// const [tableHead, setTableHead] = useState([]);
	let tableHead = [];
	console.log("step 8");
	// const [tableBodyLength, setTableBodyLength] = useState(0);
	let tableBodyLength = 0;
	console.log("step 9");
	// const [tableTrBody, setTableTrBody] = useState([]);
	let tableTrBody = [];
	console.log("step 10");
	let headerLabels = [];
	let trData = [];

	console.log("step 11");

	const GetContent = () => {
		console.log("step 16");
		const contentDiv = document.getElementById("TableToPrint");
		console.log("step 17");
		const tableHeaderElements = [...contentDiv.querySelectorAll("thead th")];
		let tableTrs = [...contentDiv?.querySelectorAll("tbody tr:not(:empty)")];
		let tableRowContent = [];

		console.log("step 18");
		console.log("step 19");
		tableHeaderElements?.map((elements) => {
			console.log("step 20");
			if (
				elements.innerText !== "" &&
				elements.innerText &&
				elements.innerText.match(/^[a-z]+/i)
			) {
				console.log("step 21...");
				headerLabels.push(elements.innerText);
				console.log("step 21...");
			}
			console.log("step 22....");
		});

		console.log("step 23");
		console.log("step 24");
		console.log("step 25");
		for (const tr of tableTrs) {
			console.log("step 26");
			if (tr.getAttribute("id")) {
				console.log("step 27");
				tableRowContent.push([
					...tr.querySelectorAll("input:not(empty), select:not(empty)"),
				]);
				console.log("step 28");
			}
			console.log("step 29");
		}
		console.log("step 30");

		console.log("step 31");
		tableRowContent.map((list) => {
			console.log("step 32");
			let tdData = [];
			console.log("step 33");
			if (list.length > 0) {
				console.log("step 34");
				let i = 0;
				console.log("step 35");
				for (let b of list) {
					console.log("step 36.1..");
					let textContent;
					// console.log('step 36.2..');
					if (i == 0) {
						// console.log('step 36.3..');
						textContent = b?.parentElement?.innerText?.trim();
						// console.log('step 36.4..');
						i++;
						// console.log('step 36.5..');
					} else if (b?.tagName.toLowerCase() === "select") {
						// console.log('step 36.6..');

						// If the innermost child is a <select>, get the selected option text
						const selectedOption = b?.options[b?.selectedIndex];
						textContent = selectedOption
							? selectedOption.textContent.trim()
							: "";
						// console.log('step 36.7..');
					} else {
						// console.log('step 36.8..');

						// For other elements, get the general text content
						// console.log(b)
						textContent =
							b?.textContent?.trim() ||
							b.defaultValue.trim() ||
							b.placeholder?.trim();
						// console.log('step 36.9..');
					}
					if (textContent && textContent != "")
						// console.log('step 36.10..');
						tdData.push(textContent);
					// console.log('step 36.11..');
				}
			}
			console.log("step 37");
			trData.push(tdData);
			console.log("step 38");

		});
		console.log("step 39");
		// trData.map((data)=>console.log(data))
		console.log("-----", trData);

		console.log("step 40");
		// if (tableRowContent.length > 0) setTableBodyLength(trData[0].length);
		if (tableRowContent.length > 0) tableBodyLength = trData[0].length;
		console.log("step 41 -> ", trData);
		// setTableTrBody(trData);
		tableTrBody = trData;
		console.log("step 42 ->");
		// if (headerLabels.length > 0) setTableHead(headerLabels);
		if (headerLabels.length > 0) tableHead = headerLabels;
		console.log("step 43");
		let divContent = contentDiv ? contentDiv.innerHTML : "";
		console.log("step 44");

		//     if (divContent !== "") {
		//       console.log("step 45");
		//       return <div dangerouslySetInnerHTML={{ __html: divContent }} />;
		//     }
		console.log("step 46");
	};
	// Replace this with your logic to generate dynamic HTML
	console.log("step 12");

	const TableHeight = () => {
		console.log("step 48");
		let a = [];
		console.log("step 49");
		for (let i = 0; i < 8 - tableTrBody.length; i++) {
			console.log("step 50.1...");
			let s = [];
			console.log("step 50.2...");

			for (let i = 0; i < tableBodyLength + 1; i++) {
				console.log("step 50.3.1..");

				s.push(
					<td
						key={i}
						className={`p-3 bg-danger border-0 border-start border-secondary ${i == 0 && "border-start-0"
							}`}
					></td>
				);
				console.log("step 50.3.2..");
			}
			console.log("step 50.4...");

			a.push(<tr>{s}</tr>);
			console.log("step 50.5...");
		}
		console.log("step 51");
		return a;
	};
	console.log("step 13");

	const printType = localStorage.getItem("printType") || "A5_format_1";
	console.log("step 14");
	GetContent();

	// useEffect(() => {
	//   console.log('step 15');
	//   console.log('step 47');
	// },[]);
	return (
		<div
			className="d-flex justify-content-center"
			style={{ width: "fit-content" }}
		>
			{/* {trData.map((tdData)=>console.log('::::::::::::::::::::::::', tdData))}
			{console.log(':----------:-----------:', tableTrBody)} */}
			<div id={"my_mm"} style={{ height: "100mm", display: "block" }}></div>

			{printType == "A4_normal" ? (
				<NormaA4
					{...{
						c_address,
						c_name,
						c_number,
						delivery_add,
						c_gstin,
						vehicle_no,
						driver,
						taxPerc,
						total_qty,
						total_disc,
						total_value,
						total_cgst,
						total_sgst,
						total,
						roundOff,
						hsn,
						TableHeight,
						tableHead,
						tableTrBody,
						hsnCalc,
					}}
				/>
			) : printType == "A4_normal_2" ? (
				<NormalA42
					{...{
						c_address,
						c_name,
						c_number,
						delivery_add,
						c_gstin,
						vehicle_no,
						driver,
						taxPerc,
						total_qty,
						total_disc,
						total_value,
						total_cgst,
						total_sgst,
						total,
						roundOff,
						hsn,
						TableHeight,
						tableHead,
						tableTrBody,
						hsnCalc,
					}}
				/>
			) : printType == "thermal" ? (
				<Thermal
					{...{
						c_address,
						c_name,
						c_number,
						delivery_add,
						c_gstin,
						vehicle_no,
						driver,
						taxPerc,
						total_qty,
						total_disc,
						total_value,
						total_cgst,
						total_sgst,
						total,
						roundOff,
						hsn,
						TableHeight,
						tableHead,
						tableTrBody,
						trData,
						hsnCalc,
					}}
				/>
			) : 
				printType == "A4_format_1" ? (
					<A4Format1
						// {trData[0].length}
						tableTrBody={tableTrBody}
						tableHead={tableHead}
						{...{
							c_address,
							c_name,
							c_number,
							delivery_add,
							c_gstin,
							vehicle_no,
							driver,
							taxPerc,
							total_qty,
							total_disc,
							total_value,
							total_cgst,
							total_sgst,
							total,
							roundOff,
							hsn,
							TableHeight,

							trData,
							hsnCalc,
							// setTableTrBody,
							GetContent,
							salesAdd
						}}
					/>
				) : printType == "A5_format_1" ? (
					<A5Format1
						// {trData[0].length}
						tableTrBody={tableTrBody}
						tableHead={tableHead}
						{...{
							c_address,
							c_name,
							c_number,
							delivery_add,
							c_gstin,
							vehicle_no,
							driver,
							taxPerc,
							total_qty,
							total_disc,
							total_value,
							total_cgst,
							total_sgst,
							total,
							roundOff,
							hsn,
							TableHeight,

							trData,
							hsnCalc,
							// setTableTrBody,
							GetContent,
							salesAdd
						}}
					/>
				):printType == "Dot_matrix_1" && (
					<DotMatrix1
						// {trData[0].length}
						tableTrBody={tableTrBody}
						tableHead={tableHead}
						{...{
							c_address,
							c_name,
							c_number,
							delivery_add,
							c_gstin,
							vehicle_no,
							driver,
							taxPerc,
							total_qty,
							total_disc,
							total_value,
							total_cgst,
							total_sgst,
							total,
							roundOff,
							hsn,
							TableHeight,

							trData,
							hsnCalc,
							// setTableTrBody,
							GetContent,
							salesAdd
						}}
					/>
				)}
		</div>
	);
};
