import React from "react";
import { Form } from "react-bootstrap";
import { TfiEmail, TfiPrinter } from "react-icons/tfi";
import { BsWhatsapp, BsFiletypePdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

export const ReportDetails = (props) => {
  const { stockList, setStockList, from,
    paramsToReport, setParamsToReport, } = props

  const handleChange = (e) => {
    if (e.target.value === "") {
      setParamsToReport({ ...paramsToReport, [e.target.name]: null })
    } else {
      setParamsToReport({ ...paramsToReport, [e.target.name]: e.target.value?.toUpperCase() })
    }
  }

  return (
    // <div className="stock-jdetails-cont col-12 p-1 ps-4 rounded-1 w-100 bg-light h-100 pe-4">
    <div className="stock-entry row mx-0 px-0 pt-3 w-fit d-flex justify-content-between">
      <div className="col-12 px-0 mt-1 d-flex gap-3 justify-content-start">
        <div
          style={{ background: "#b30b00", border: "#b30b00 2px", }}
          className="reports-btn btn rounded-1 col-1 col-2 py-1"
        >
          <BsFiletypePdf className="me-2 text-" size={18} />
          PDF
        </div>
        <div
          style={{ background: "#02723b", border: "solid #02723b 2px" }}
          className="reports-btn btn rounded-1 col-1 col-2 py-1"
        >
          <RiFileExcel2Line className="me-2" size={18} />
          Excel
        </div>
        <div
          style={{ background: "rgba(75, 75, 75, 1)", border: "rgba(75, 75, 75, 1) 2px" }}
          className="reports-btn btn rounded-1 col-1 col-2 py-1"
        >
          <TfiPrinter size={18} className="me-2 h-100" />
          Print
        </div>
        <div
          style={{ background: "#ea4335", border: "solid #ea4335 2px" }}
          className="reports-btn btn rounded-1 col-1 col-2 py-1"
        >
          <TfiEmail size={18} className="me-2 h-100" />
          Email
        </div>
        <div
          style={{ background: "rgba(77, 135, 42, 1)", border: "solid rgba(77, 135, 42, 1) 2px" }}
          className="reports-btn btn rounded-1 col-1 col-2 py-1"
        >
          <BsWhatsapp size={18} className="me-2 h-100" />
          Whatsapp
        </div>
      </div>

      <div className="d-flex col-6 mt-3 px-0">
        <Form.Group className="col-6 pe-4 ps-0 mx-0 d-flex align-items-start mt-1">
          <Form.Label className="col-2 purchase-input-label pb-1">
            From.
          </Form.Label>
          <Form.Control
            onChange={handleChange}
            required
            name="from_date"
            value={paramsToReport.from_date || (new Date()?.toISOString()?.slice(0, 8) + '01')}
            className="purchase-input-text me-2"
            placeholder="Document number"
            type="date"
          />
        </Form.Group>
        <Form.Group className="col-6 pe-6 ps-0 mx-0 d-flex align-items-start mt-1">
          <Form.Label className="col-2 purchase-input-label pb-1">
            Upto
          </Form.Label>
          <Form.Control
            onChange={handleChange}
            required
            name="to_date"
            value={paramsToReport.to_date || (new Date()?.toISOString()?.slice(0, 8) + '01')}
            className="purchase-input-text me-2"
            placeholder="Document number"
            type="date"
          />
        </Form.Group>
      </div>

    </div>
  );
};
