import React, { useEffect, useState } from "react";
import "../A4Format2.css";
import { SheetFooter } from "./SheetFooter";
import { SheetHeader } from "./SheetHeader";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

export const SheetFormat = (props) => {
  console.log('step 70');
  console.log('SheetFormat : ', props);
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeigth,
    tableHead,
    // tableTrBody,
    tableBodyContent,
    hsnCalc,
    setTableTrBody,

    showTableBody,
    showSheet,
    auth,
    count,
    isLastRow,
    salesAdd,
    itemNumber,
    noOfItems
  } = props;

  const styles = {
    sHead: {
      height: isLastRow ? '31%' : '31%',
    },
    sBody: {
      height: isLastRow ? '42%' : '69%',
    },
    sFoot: {
      height: isLastRow ? '27%' : '0%',
    }
  };
  return (
    <div
      style={showSheet ? {} : { display: "none" }}
      className="m-2 main-sheet-A4 print-page"
    >
      {/* <div  className="m-2 print-page">
        <div
          className="p-3 normal-a4"
          id="new"
          style={{ width: "100%" }}
        >
          <div className="border w-auto border-secondary ">
            <SheetHeader auth={auth} count={count} {...{c_address, delivery_add, salesAdd, vehicle_no}}/>
            <div className="border-top w-auto border-bottom mt-1 mx-1 border-secondary">
              <table className="table mb-0 w-100 body-content print-table">
                {showTableBody && <TableBody {...props} />}
              </table>
            {!isLastRow && <div className="mb-4"></div>}  
            </div>
            {  isLastRow && <SheetFooter auth={auth} count={count} {...props} />
            }
          </div>
        </div>
      </div> */}
      <div className="invoice-A4">
        <div  style={styles.sHead} className="s-head-A4">
          <SheetHeader auth={auth} count={count} {...{c_address, delivery_add, salesAdd, vehicle_no}}/>
        </div>
        <div  style={styles.sBody} className="s-body-A4">
          {showTableBody && <TableBody {...props} />}
        </div>
        <div  style={styles.sFoot}className="s-foot-A4">
          {isLastRow && <SheetFooter auth={auth} count={count} {...props} />
            }
        </div>
      </div>
    </div>
  );
};
