import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import "../A4Format2.css";


export const TableBody = (props) => {
  const {
    c_address,
    c_name,
    c_number,
    delivery_add,
    c_gstin,
    vehicle_no,
    driver,
    total_qty,
    total_disc,
    total_value,
    total_sgst,
    total,
    roundOff,
    TableHeigth,
    tableHead,
    // tableTrBody,
    tableBodyContent,
    hsnCalc,
    setTableTrBody,
    isLastRow,
    itemNumber,
    noOfItems
  } = props;
  let currentItemNumber=itemNumber;
  // let emptyRows = [];
  // for (var i=0; i<(tableBodyContent.length - noOfItems ); i++) {
  //   emptyRows.push(
  //     <tr></tr>
  //   )
  // }
  const tbodyHeight = tableBodyContent.length > 8 ? "580px" : "330px";

  return (
    <>
    <section class="items-A4">
      <table className="print-table-A4" style={{ border: "1px solid black" }}>
        <thead>
          <tr>
            {tableHead.length>0 && tableHead.map((data,i)=>{
                return(
                    <th>{data==="CGST,IGST"?'CGST\nIGST':data}</th>
                )
              })}
          </tr>
        </thead>
        <tbody style={{'height':tbodyHeight}}>
          {tableBodyContent.length > 0 &&
            tableBodyContent.map((data, i) => {
              currentItemNumber++;
              return (
                <tr key={i}>
                  <td>{currentItemNumber}</td>
                  {data.map((item)=>{
                          return(
                            <td>{item.length > 16 ? item.slice(0, 16) : item}</td>
                          )
                  })}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr className="border-secondary">
              <td className="text-end fw-bold" colSpan={tableHead.length}>
                  { isLastRow ?
                      <>
                        <strong>Total: </strong> {total}
                      </>
                      :
                      <strong className="me-2">Continue ... </strong>
                    }
              </td>
            </tr>
        </tfoot>
        </table>
    </section>
     
    </>
  );
};
