import React, { useEffect, useState } from "react";
import "../printConfig.css";
import { Rnd } from "react-rnd";


export const CustomTable = () => {
    return (
        <Rnd style={{backgroundColor:'red'}}>
            <table style={{width: '100%', height: '100%'}} border={1} className="printCfg">
                <tr>
                    <td>Sl.No</td>
                    <td>Item Name</td>
                    <td>Qty</td>
                    <td>Amount</td>
                    <td>Sub Total</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>item 1</td>
                    <td>3</td>
                    <td>100</td>
                    <td>300</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>item 2</td>
                    <td>2</td>
                    <td>75</td>
                    <td>150</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>item 3</td>
                    <td>9</td>
                    <td>50</td>
                    <td>450</td>
                </tr>
            </table>
        </Rnd>
    )
}